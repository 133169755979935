import React, { useState, useEffect } from 'react';
import Topbar from '../components/Topbar';
import TopNav from '../components/TopNav';
import Footer from '../components/Footer';
import team1 from './images/team-1-1.jpg';
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import { connect } from 'react-redux';
import axios from "axios";
import { toggleLoader } from '../actions/loader';
import { allInstructorList } from '../Utils/utils';
import ReactPaginate from 'react-paginate';
import { FacebookShareButton, PinterestShareButton, TwitterShareButton, LinkedinShareButton } from 'react-share';
import { FacebookIcon, PinterestIcon, TwitterIcon, LinkedinIcon } from "react-share";
import { Link } from 'react-router-dom';
import LazyLoad from 'react-lazyload';
import defaultImg from './images/index.jpeg';
import { Translate, I18n } from "react-redux-i18n";

const InstructorList = (props) => {
  const [instructor, setInstructor] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [current_page, setCurrentPage] = useState(1);
  const [search, setSearch] = useState('');
  const [is_loading, setIsLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  useEffect(async () => {
    props.toggleLoader(true);
    var list_var = await allInstructorList(current_page)
    setInstructor(list_var.data);
    setPageCount(list_var.last_page);
    setIsLoading(false)
    props.toggleLoader(false);
  }, [])

  const handlePageClick = async (e) => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    const selectedPage = e.selected + 1;
    props.toggleLoader(true);
    setIsLoading(true)
    var list_var = await allInstructorList(selectedPage);
    setPageCount(list_var.last_page)
    setInstructor(list_var.data);
    setIsLoading(false)
    props.toggleLoader(false);
  };

  const searchFilter = async () => {
    props.toggleLoader(true);
    setIsLoading(true)
    var list_var = await allInstructorList('1', search)
    setInstructor(list_var.data)
    setPageCount(list_var.last_page)
    setIsLoading(false)
    props.toggleLoader(false);
  }
  const searchFilterNoLoader = async (e) => {
    setIsLoading(true)
    var list_var = await allInstructorList('1', e)
    setInstructor(list_var.data)
    setPageCount(list_var.last_page)
    setIsLoading(false)
  }
  return (
    <>
      <body id="page-top">
        {/* <TopNav /> */}
        <div classNameName="page-wrapper">

          <Topbar />
          <div>
            <section class="inner-banner d-none d-sm-block">
              <div class="container">
                <ul className="list-unstyled thm-breadcrumb">
                  <li><Link to="/">{I18n.t("Home")}</Link></li>
                  <li className="active"><Link to="/instructor-list">{I18n.t("Instructors")}</Link></li>
                </ul>
                <h2 class="inner-banner__title">{I18n.t("Instructors")}</h2>
              </div>
            </section>
            <section class="inner-banner-responsive d-block d-sm-none">
              <div class="container">
                <Breadcrumb>
                  <Breadcrumb.Item href="/">{I18n.t("Home")}</Breadcrumb.Item>
                  <Breadcrumb.Item active>
                    {I18n.t("Instructors")}
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>
            </section>
            <section className='container my-5'>
              <div className="row">
                <div className='col-auto'>
                  <label className="widget-title line-bottom position-relative" style={{ fontSize: '18px' }}>{I18n.t("Search")}
                   &nbsp; <span className="text-theme-color-2 font-weight-bold">{I18n.t("Instructors")} </span></label>
                </div>
                <div className='col-md-6'>
                  <div className="search-form">

                    <form>
                      <div className="input-group" style={{ maxWidth: "400px" }}>
                        <input type="text" placeholder={I18n.t("Search here")} className="form-control search-input mh-50" onChange={e => {
                          setSearch(e.target.value)
                          searchFilterNoLoader(e.target.value)
                        }} />
                        <span className="input-group-btn">
                          <button type="submit" className="header__search-btn search-popup__toggler search-button" onClick={e => searchFilter()}>
                            <i className="fa fa-search"></i>
                          </button>
                        </span>
                      </div>
                    </form>
                  </div>
                </div>

              </div>
            </section>
            <hr className='md-mb-4' />
            <div className='clearfix'></div>
            <section class="instrutores team-page">
              <div class="container">
                <div class="row">
                  {
                    is_loading && [1, 2, 3, 4, 5, 6, 7, 8].map((data) => (
                      <div className="mt-3 mb-2 col-lg-3 col-md-4 col-sm-6">
                        <div className="wrapper">
                          <div className="animate br" style={{ height: '205px' }}></div>
                          <div className="">
                            <h4 className="comment animate br mb-2">
                              <span></span>
                            </h4>
                            <h6 className="w50 mx-auto comment animate br"></h6>
                          </div>
                          <div className="comment animate br"></div>
                        </div>
                      </div>
                    ))
                  }
                  {instructor.map((data) => (
                    <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12">
                      <div class="instrutores__single">
                        <Link to={"/instructor-detail/" + data.id}>
                          <div class="instrutores__image">
                            {data.media !== null ?
                              <LazyLoad height={200}>
                                <img src={data.media.url} />
                              </LazyLoad>
                              :
                              <LazyLoad height={200}>
                                <img src={defaultImg} alt="" />
                              </LazyLoad>
                            }
                          </div>
                        </Link>
                        <div class="instrutores__content">
                          <h2 class="instrutores__name">
                            <Link to={"/instructor-detail/" + data.id}>
                              {data.full_name}</Link>
                          </h2>
                          <p class="instrutores__designation">{data.role_txt !== null ? data.role_txt : I18n.t("Role")}</p>
                          <p class="instrutores__text">{data.as_instuctor} {data.as_instuctor > 1 ? I18n.t("Courses") : I18n.t("course")} </p>
                        </div>
                        <div class="instrutores__social">
                          {/* <FacebookShareButton url={"/instructor-detail/" + data.id} className="mr-1">
                            <FacebookIcon size={20} />
                          </FacebookShareButton>
                          <TwitterShareButton url={"/instructor-detail/" + data.id} className="mr-1">
                            <TwitterIcon size={20} />
                          </TwitterShareButton>
                          <LinkedinShareButton url={"/instructor-detail/" + data.id} className="mr-1">
                            <LinkedinIcon size={20} />
                          </LinkedinShareButton> */}
                          <div className="team-social-links">
                            <div className="team-social pl-0">
                              {data.facebook !== null ?
                                <Link className="facebook" to={{ pathname: data.facebook }} target="_blank">'
                                  <i className="icofont-facebook"></i>
                                </Link>
                                : <Link>
                                  <i className="icofont-facebook"></i>
                                </Link>
                              }
                              {data.twitter !== null ?
                                <Link className="twitter" to={{ pathname: data.twitter }} target="_blank">
                                  <i className="icofont-twitter"></i>
                                </Link>
                                : <Link>
                                  <i className="icofont-twitter"></i>
                                </Link>
                              }
                              {data.linkedin !== null ?
                                <Link className="linkdin" to={{ pathname: data.linkedin }} target="_blank">
                                  <i className="icofont-linkedin"></i>
                                </Link>
                                : <Link>
                                  <i className="icofont-linkedin"></i>
                                </Link>
                              }
                              {data.instagram !== null ?
                                <Link className="instagram" to={{ pathname: data.instagram }} target="_blank">
                                  <i className="icofont-instagram"></i>
                                </Link>
                                : <Link>
                                  <i className="icofont-instagram"></i>
                                </Link>
                              }
                              {/* <Link to={"/instructor-detail/" + data.id} target="_blank"><i class="icofont-web"></i></Link> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}

                </div>
              </div>
            </section>
            {instructor.length > 0 ?
              <div className='row no-gutters mb-4'>
                <div className="mx-auto d-block">
                  <ReactPaginate
                    previousLabel={I18n.t("prev")}
                    nextLabel={I18n.t("next")}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"} />
                </div>
              </div>
              : ""}
          </div>
          <Footer />
        </div>
      </body>
    </>
  )
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(InstructorList);
