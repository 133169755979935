import React, { useState, useEffect } from 'react';
import star from './images/Star.png'
import teacher1 from './images/pic15.jpg'
import Carousel from 'react-bootstrap/Carousel';
import { connect } from 'react-redux';
import axios from "axios";
import { toggleLoader } from '../../actions/loader';
import { instructorList } from '../../Utils/utils';
import { Link } from 'react-router-dom';
import { Translate, I18n } from "react-redux-i18n";
import defaultImg from './images/index.jpeg';

const MeetTeachers = () => {
  const [instructor, setInstructor] = useState([]);
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  useEffect(async () => {
    var list_var = await instructorList();
    if (list_var !== null) {
      setInstructor(list_var);
    }
  }, [])
  return (
    <>
      <section class="teacher_list">
        {/* desktop starts here */}
        <div class="container mb-3">
          <div class="row">
            <div class="col-auto"><img src={star} /></div>
            <div class="col"><h2 class="heading_tea">{I18n.t("Meet The Teacher")}</h2></div>
          </div>
        </div>
        <div class="container mb-3 mt-5 d-none d-sm-block">
          <div class="row">
            {instructor.map((list) => (
              <div class="col-lg-3 col-md-6 col-sm-6 wow fadeInUp">
                <div class="dutra-box mb-3 dutra-team1">
                  <div class="dutra-media">
                    <a href="#">
                      {list.media !== null ?
                        <Link to={"/instructor-detail/" + list.id}>
                          <img width="358" height="460" alt="" src={list.media.url} class="lazy" />
                        </Link>
                        :
                        <Link to={"/instructor-detail/" + list.id}>
                          <img width="358" height="460" alt="" src={defaultImg} class="lazy" />
                        </Link>
                      }
                    </a>
                  </div>
                  <div class="dutra-info">
                    <h4 class="dutra-title">
                      {list.full_name}
                    </h4>
                    <span class="dutra-position">
                      {list.role_txt !== null ? list.role_txt
                        : "role"
                      }</span>
                    <ul class="dutra-social-icon dez-border">
                      <li><a href="" class="fab fa-facebook"></a>
                      </li>
                      <li><a href="" class="fab fa-twitter"></a></li>
                      <li><a href="" class="fab fa-linkedin"></a></li>
                      <li><a href="" class="fab fa-pinterest"></a></li>
                    </ul>
                  </div>
                </div>
              </div>
            ))}

          </div>
          <div class="btn_div text-center">
            <Link to="/instructor-list" className="theme-btn">{I18n.t("View all instructors")}</Link>
          </div>
        </div>
        {/* desktop ends here */}

        {/* mobile responsive starts here  */}
        <div class="container mb-3 mt-5 d-block d-sm-none">

          <Carousel activeIndex={index} onSelect={handleSelect} indicators={false} >
            {/* <div class="row"> */}
            {instructor.map((list) => (
              <Carousel.Item >
                <div class="col-lg-3 col-md-6 col-sm-6 wow fadeInUp">
                  <div class="dutra-box mb-3 dutra-team1">
                    <div class="dutra-media">
                      {list.media !== null ?
                        <Link to={"/instructor-detail/" + list.id}>
                          <img width="358" height="460" alt="" src={list.media.url} class="lazy" />
                        </Link>
                        :
                        <Link to={"/instructor-detail/" + list.id}>
                          <img width="358" height="460" alt="" src={defaultImg} class="lazy" />
                        </Link>
                      }
                    </div>
                    <div class="dutra-info">
                      <h4 class="dutra-title">
                        <a href="#">
                          {list.full_name}</a>
                      </h4>
                      <span class="dutra-position">{list.role_txt !== null ? list.role_txt
                        : "role"
                      }</span>
                      <ul class="dutra-social-icon dez-border">
                        <li><a href="" class="fab fa-facebook"></a>
                        </li>
                        <li><a href="" class="fab fa-twitter"></a></li>
                        <li><a href="" class="fab fa-linkedin"></a></li>
                        <li><a href="" class="fab fa-pinterest"></a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Carousel.Item >
            ))}
            {/* </div> */}
          </Carousel>
        </div>
        {/* mobile responsive ends here */}
      </section>
    </>
  )
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(MeetTeachers);
