import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import axios from "axios";
import { toggleLoader } from '../actions/loader';
import { Link } from 'react-router-dom';
import Topbar from '../components/Topbar';
import Footer from '../components/Footer';
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import './style.css';
import { displayError, displaySuccess } from '../Utils/utils';
import config from "../config/config";

const ContactUs = (props) => {
  const [full_name, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const ContactEmail = () => {
    var token = localStorage.getItem('token');
    props.toggleLoader(true);
    let path = config.siteUrl;
    axios.post(path + '/contact-us', {
      full_name: full_name,
      email: email,
      subject: subject,
      message: message,
    }, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    })
      .then(function (response) {
        props.toggleLoader(false);
        displaySuccess('Message Send Succesfully');
        setTimeout(() => window.location.href = "/contactus", 3000);
      })
      .catch(function (error) {
        props.toggleLoader(false);
        // if (error.response.status === 401) {
        //   window.location.href = '/';
        // }
        // displayError(error);
      });
  }
  return (
    <>
      <body id="page-top">
        <div className="page-wrapper">

          {/* <Topbar /> */}
          <div>
            {/* <section className="inner-banner d-none d-sm-block">
              <div className="container">
                <ul className="list-unstyled thm-breadcrumb">
                  <li><Link to="/">Home</Link></li>
                  <li className="active"><Link>contact us</Link></li>
                </ul>
                <h2 className="inner-banner__title">Contact Us</h2>
              </div>
            </section>
            <section className="inner-banner-responsive d-block d-sm-none">
              <div className="container">
                <Breadcrumb>
                  <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                  <Breadcrumb.Item href="" active>
                    Contact Us
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>
            </section>
            <br /> */}
            {/* <br /> */}
            <div className="container">
              <div className='row' style={{ marginTop: '-50px' }}>
                <div className='col-lg-4' >
                  <div className='card kontak-page__card' style={{ width: '300px' }} >
                    <svg width="36" height="50" viewBox="0 0 36 50" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18 23.75C16.3424 23.75 14.7527 23.0915 13.5806 21.9194C12.4085 20.7473 11.75 19.1576 11.75 17.5C11.75 15.8424 12.4085 14.2527 13.5806 13.0806C14.7527 11.9085 16.3424 11.25 18 11.25C19.6576 11.25 21.2473 11.9085 22.4194 13.0806C23.5915 14.2527 24.25 15.8424 24.25 17.5C24.25 18.3208 24.0883 19.1335 23.7743 19.8918C23.4602 20.6501 22.9998 21.3391 22.4194 21.9194C21.8391 22.4998 21.1501 22.9602 20.3918 23.2743C19.6335 23.5883 18.8208 23.75 18 23.75ZM18 0C13.3587 0 8.90752 1.84374 5.62563 5.12563C2.34374 8.40752 0.5 12.8587 0.5 17.5C0.5 30.625 18 50 18 50C18 50 35.5 30.625 35.5 17.5C35.5 12.8587 33.6563 8.40752 30.3744 5.12563C27.0925 1.84374 22.6413 0 18 0Z" fill="#26C9D3"></path></svg>
                    <p class="kontak-page__bold-text mt-3">Head Office</p>
                    <p class="kontak-page__text" style={{ marginTop: '-13px', fontSize: '14px' }}>Rua Tupi, 758, Novo Hamburgo, RS, Brasil, CEP 93336-010</p>
                  </div>
                </div>
                <div className='col-lg-4'>
                  <div className='card kontak-page__card' style={{ width: '300px' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path d="M511.2 387l-23.25 100.8c-3.266 14.25-15.79 24.22-30.46 24.22C205.2 512 0 306.8 0 54.5c0-14.66 9.969-27.2 24.22-30.45l100.8-23.25C139.7-2.602 154.7 5.018 160.8 18.92l46.52 108.5c5.438 12.78 1.77 27.67-8.98 36.45L144.5 207.1c33.98 69.22 90.26 125.5 159.5 159.5l44.08-53.8c8.688-10.78 23.69-14.51 36.47-8.975l108.5 46.51C506.1 357.2 514.6 372.4 511.2 387z" fill="#26C9D3" /></svg>

                    <p class="kontak-page__bold-text mt-3">Phone</p>
                    <p class="kontak-page__text" style={{ marginTop: '-13px', fontSize: '14px' }}>+55 51 3527-4145</p>
                  </div>
                </div>
                <div className='col-lg-4'>
                  <div className='card kontak-page__card' style={{ width: '300px' }} >
                    <svg width="50" height="40" viewBox="0 0 50 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M45 10L25 22.5L5 10V5L25 17.5L45 5V10ZM45 0H5C2.225 0 0 2.225 0 5V35C0 36.3261 0.526784 37.5979 1.46447 38.5355C2.40215 39.4732 3.67392 40 5 40H45C46.3261 40 47.5979 39.4732 48.5355 38.5355C49.4732 37.5979 50 36.3261 50 35V5C50 2.225 47.75 0 45 0Z" fill="#26C9D3"></path></svg>
                    <p class="kontak-page__bold-text mt-3">Email</p>
                    <p class="kontak-page__text" style={{ marginTop: '-13px', fontSize: '14px' }}>relacionamento@metododutra.com.br</p>
                  </div>
                </div>
              </div>
              {/* <div className="row justify-content-center">
                <div className="col-md-12">
                  <div className="card-group mb-0" style={{ paddingBottom: '40px' }}>
                    <div className="card p-4">
                      <div className="card-body">
                        <h1>Need Help?</h1>
                        <div className="input-group mb-3">
                          <input type="text" className="form-control" placeholder='Full Name' onChange={e => setFullName(e.target.value)}/>
                        </div>
                        <div className="input-group mb-4">
                          <input type="email" name="email" className="form-control" placeholder='Email Address'onChange={e => setEmail(e.target.value)} />
                        </div>
                        <div className="input-group mb-4">
                          <input type="text" name="subject" className="form-control" placeholder='Subject' onChange={e => setSubject(e.target.value)}/>
                        </div>
                        <div className="input-group mb-4">
                          <textarea rows='5' cols='25' placeholder='Message' className="form-control" onChange={e => setMessage(e.target.value)}></textarea>
                        </div>
                        <div className="row">
                          <div className="col-6 ">
                            <button type="button" className="btn btn-link px-0" onClick={ContactEmail}>Send</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card text-white  py-5 d-md-down-none" >
                      <div className="card-body text-center">
                        <div id="map-container-google-9" class="z-depth-1-half map-container-5" >
                          <div style={{ width: "100%" }}>
                            <iframe scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=urban%20excubator%20,%20jaipur%20,%20rajasthan+(CodiFly%20IT)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed" width="100%" height="400" frameborder="0">
                            </iframe>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}

            </div>
          </div>
          {/* <Footer /> */}
        </div>
      </body>
    </>
  )
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);
