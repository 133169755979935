import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import axios from "axios";
import { toggleLoader } from '../actions/loader';
import Topbar from '../components/Topbar';
import Footer from '../components/Footer';
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import { Link } from 'react-router-dom';
// import './style.css';
import Leftbar from '../components/Leftbar';
import config from "../config/config";
import { getUserProfile, } from '../Utils/utils';
import { Translate, I18n } from "react-redux-i18n";
import ReactInputMask from "react-input-mask";

const Profile = (props) => {
  const [first_name, setFirstName] = useState('');
  const [last_name, setLastName] = useState('');
  const [address, setAddress] = useState('');
  const [zip_code, setZipCode] = useState('');
  const [email, setEmail] = useState('');
  const [cpf, setCpf] = useState('');
  const [role_txt, setRole] = useState('');
  const [experience, setExperience] = useState('');
  const [speciality_in, setSpeciality] = useState('');
  const [facebook, setFacebook] = useState('');
  const [twitter, setTwitter] = useState('');
  const [linkedin, setLinkedin] = useState('');
  const [timezone, setTimezone] = useState('');
  const [phone_number, setPhoneNumber] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [cpf_inter, setCpfInter] = useState('');
  let [mask, setMask] = React.useState(null);
  const [zip_inter, setZipInter] = useState('');
  let [masked, setMasked] = React.useState(null);
  const [instagram, setInstagram] = useState('');
  useEffect(async () => {
    props.toggleLoader(true);
    var getProfile_var = await getUserProfile();
    if (getProfile_var !== null) {
      setFirstName(getProfile_var.first_name);
      setLastName(getProfile_var.last_name);
      setEmail(getProfile_var.email);
      setAddress(getProfile_var.address);
      setZipCode(getProfile_var.zip_code);
      setRole(getProfile_var.role_txt);
      // setCpf(getProfile_var.cpf);
      setPhoneNumber(getProfile_var.phone_number);
      setExperience(getProfile_var.experience);
      setSpeciality(getProfile_var.speciality_in);
      setFacebook(getProfile_var.facebook);
      setLinkedin(getProfile_var.linkedin);
      setTwitter(getProfile_var.twitter);
      setInstagram(getProfile_var.instagram);
      if (getProfile_var.city !== null) {
        setCity(getProfile_var.city.name);
      }
      setTimezone(getProfile_var.timezone);
      if (getProfile_var.state !== null) {
        setState(getProfile_var.state.name);
      }
      if (getProfile_var.cpf !== null) {
        var nums = getProfile_var.cpf.replace(/[^0-9]/g, "");
        setMask("999.999.999-99");
        setCpf(getProfile_var.cpf);
        setCpfInter(nums);
      }
      if (getProfile_var.zip_code !== null) {
        var num = getProfile_var.zip_code.replace(/[^0-9]/g, "");
        setMasked("99.999-999");
        setZipCode(getProfile_var.zip_code);
        setZipInter(num);
      }
    }
    props.toggleLoader(false);
  }, [])
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const handleChange = ev => {
    let { value } = ev.target;
    let nums = value.replace(/[^0-9]/g, "");
    setMask("999.999.999-99");
    setCpf(ev.target.value);
    setCpfInter(nums);
    if (nums.length < 3 && mask != null) {
      setMask(null);
      ev.target.value = nums;
    }
  };
  const zipHandle = ev => {
    let { value } = ev.target;
    let nums = value.replace(/[^0-9]/g, "");
    setMasked("99.999-999");
    setZipCode(ev.target.value);
    setZipInter(nums);
    if (nums.length < 3 && mask != null) {
      setMask(null);
      ev.target.value = nums;
    }
  };
  return (
    <>
      <body id="page-top">
        <div className="page-wrapper">

          <Topbar />
          <section className="inner-banner d-none d-sm-block">
            <div className="container">
              <ul className="list-unstyled thm-breadcrumb">
                <li><Link to="/">{I18n.t("Home")}</Link></li>
                <li className="active"><Link to="/my-course">{I18n.t("Profile")}</Link></li>
              </ul>
              <h2 className="inner-banner__title">{I18n.t("Profile")}</h2>
            </div>
          </section>
          <section className="inner-banner-responsive d-block d-sm-none">
            <div className="container">
              <Breadcrumb>
                <Breadcrumb.Item href="/">{I18n.t("Home")}</Breadcrumb.Item>
                <Breadcrumb.Item href="" active>
                  {I18n.t("Course Detail")}
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </section>
          <>
            <div class="container">
              <div class="main-body">
                <div class="row gutters-sm my-4">
                  <Leftbar menu={'profile'} />
                  <div class="col-md-9">
                    <div class="card mb-3">
                      <div className='card-header'>
                        <h4 className='mb-0'> {I18n.t("Basic Information")}</h4>
                      </div>
                      <div class="card-body">
                        <div class="row">
                          <div class="col-sm-3">
                            <h6 class="mb-0">{I18n.t("Full Name")}</h6>
                          </div>
                          <div class="col-sm-9 text-secondary">
                            {first_name} {last_name}
                          </div>
                        </div>
                        <hr />
                        <div class="row">
                          <div class="col-sm-3">
                            <h6 class="mb-0">{I18n.t("Email")}</h6>
                          </div>
                          <div class="col-sm-9 text-secondary">
                            {email !== null ? email : "N/A"}
                          </div>
                        </div>
                        <hr />
                        <div class="row">
                          <div class="col-sm-3">
                            <h6 class="mb-0">{I18n.t("Phone")}</h6>
                          </div>
                          <div class="col-sm-9 text-secondary">
                            {phone_number !== null ? phone_number : "N/A"}
                          </div>
                        </div>
                        <hr />
                        <div class="row">
                          <div class="col-sm-3">
                            <h6 class="mb-0">{I18n.t("Address")}</h6>
                          </div>
                          <div class="col-sm-9 text-secondary">
                            {address !== null ? address : "N/A"}
                          </div>
                        </div>
                        <hr />
                        <div class="row">
                          <div class="col-sm-3">
                            <h6 class="mb-0">{I18n.t("State")}</h6>
                          </div>
                          <div class="col-sm-9 text-secondary">
                            {state !== null ? state : "N/A"}
                          </div>
                        </div>
                        <hr />
                        <div class="row">
                          <div class="col-sm-3">
                            <h6 class="mb-0">{I18n.t("City")}</h6>
                          </div>
                          <div class="col-sm-9 text-secondary">
                            {city !== null ? city : "N/A"}
                          </div>
                        </div>
                        <hr />
                        <div class="row">
                          <div class="col-sm-3">
                            <h6 class="mb-0">{I18n.t("Timezone")}</h6>
                          </div>
                          <div class="col-sm-9 text-secondary">
                            {timezone !== null ? timezone : "N/A"}
                          </div>
                        </div>
                        <hr />
                        <div class="row">
                          <div class="col-sm-3">
                            <h6 class="mb-0">{I18n.t("Zip Code")}</h6>
                          </div>
                          <div class="col-sm-9 text-secondary">
                            {zip_code !== null ?
                              <ReactInputMask
                                style={{ backgroundColor: 'white', borderColor: 'white' }}
                                className="form-control"
                                mask={masked}
                                onChange={zipHandle}
                                // onChange={e => {
                                //   setCpf(e.target.value)
                                // handleChange}}
                                maskChar="_"
                                value={zip_code}
                                {...props}
                                disabled
                              /> : "N/A"}
                          </div>
                        </div>
                        <hr />
                        <div class="row">
                          <div class="col-sm-3">
                            <h6 class="mb-0">{I18n.t("CPF")}</h6>
                          </div>
                          <div class="col-sm-9 text-secondary">
                            {cpf !== null ?
                              <ReactInputMask
                                style={{ backgroundColor: 'white', borderColor: 'white' }}
                                className="form-control"
                                mask={mask}
                                onChange={handleChange}
                                // onChange={e => {
                                //   setCpf(e.target.value)
                                // handleChange}}
                                maskChar="_"
                                value={cpf}
                                {...props}
                                disabled
                              /> : "N/A"}

                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="card mb-3">
                      <div className='card-header'>
                        <h4 className='mb-0'>{I18n.t("Other Detail")}</h4>
                      </div>
                      <div class="card-body">
                        <div class="row">
                          <div class="col-sm-3">
                            <h6 class="mb-0">{I18n.t("About You")}</h6>
                          </div>
                          <div class="col-sm-9 text-secondary">
                            {role_txt !== null ? role_txt : "N/A"}
                          </div>
                        </div>
                        <hr />
                        <div class="row">
                          <div class="col-sm-3">
                            <h6 class="mb-0">{I18n.t("Experience")}</h6>
                          </div>
                          <div class="col-sm-9 text-secondary">
                            {experience !== null ? experience : "N/A"}
                          </div>
                        </div>
                        <hr />
                        <div class="row">
                          <div class="col-sm-3">
                            <h6 class="mb-0">{I18n.t("Speciality")}</h6>
                          </div>
                          <div class="col-sm-9 text-secondary">
                            {speciality_in !== null ? speciality_in : "N/A"}
                          </div>
                        </div>
                        <hr />
                        <div class="row">
                          <div class="col-sm-3">
                            <h6 class="mb-0">{I18n.t("Facebook")}</h6>
                          </div>
                          <div class="col-sm-9 text-secondary">
                            {facebook !== null ? facebook : "N/A"}
                          </div>
                        </div>
                        <hr />
                        <div class="row">
                          <div class="col-sm-3">
                            <h6 class="mb-0">{I18n.t("Twitter")}</h6>
                          </div>
                          <div class="col-sm-9 text-secondary">
                            {twitter !== null ? twitter : "N/A"}
                          </div>
                        </div>
                        <hr />
                        <div class="row">
                          <div class="col-sm-3">
                            <h6 class="mb-0">{I18n.t("Linkedin")}</h6>
                          </div>
                          <div class="col-sm-9 text-secondary">
                            {linkedin !== null ? linkedin : "N/A"}
                          </div>
                        </div>
                        <hr />
                        <div class="row">
                          <div class="col-sm-3">
                            <h6 class="mb-0">{I18n.t("Instagram")}</h6>
                          </div>
                          <div class="col-sm-9 text-secondary">
                            {instagram !== null ? instagram : "N/A"}
                          </div>
                        </div>
                        <hr />
                        <div class="row">
                          <div class="col-sm-12" style={{ width: "200px" }}>
                            <Link to="/edit-profile" class="btn-primary btn-lg">{I18n.t("Edit Profile")}</Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        </div>
        <Footer />
      </body>
    </>
  )
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Profile);
