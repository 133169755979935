import React, { useEffect, useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { connect } from 'react-redux';
import axios from "axios";
import { toggleLoader } from '../../actions/loader';
import { courseList } from '../../Utils/utils';
import { Link } from 'react-router-dom';
import { Translate, I18n } from "react-redux-i18n";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import Alert from 'react-bootstrap/Alert'
import defaultImg from './images/index.jpeg';
const CourseResponsive = () => {
  const [index, setIndex] = useState(0);
  const [course, setCourse] = useState([]);
  const handleClose = () => setShow(false); const [show, setShow] = useState(false);
  const [temp_id, setTempId] = useState('');
  const handleShow = (id) => {
    setShow(true)
    setTempId(id)
  }
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  useEffect(async () => {
    var courseList_var = await courseList();
    if (courseList_var !== null) {
      setCourse(courseList_var);
    }
  }, [])
  return (
    <>
      <div className='d-block d-sm-none p-2 mb-2'>
        <Carousel activeIndex={index} onSelect={handleSelect} indicators={false}>
          {course.map((list) => (
            <Carousel.Item >
              <div class="course-one__single color-1">
                <div class="course-one__image">
                  <a class="course-one__category">{list.category.name}</a>
                  <img src={list.media.url} alt="" />
                  <i class="far fa-heart"></i>
                </div>
                <div class="course-one__content">

                  <div class="course-one__admin">
                    {/* <img src={list.user.media.url} alt="" />
                    {I18n.t('by')} <a >{list.user.full_name}</a> */}
                    <h3 className='instructor-name ' onClick={e => handleShow(list.id)} style={{ cursor: 'pointer' }}>
                      <i class="fa fa-users"></i> {I18n.t("View Instructors")}
                    </h3>
                  </div>
                  <Link to={"/course-detail/" + list.id + "/" + list.slug}>
                    <h2 className="course-one__title">{list.name}</h2>
                  </Link>
                  {/* <!-- /.course__title --> */}
                  <div class="course-one__stars">
                    <span class="course-one__stars-wrap">
                      <i class="fa fa-star"></i>
                      <i class="fa fa-star"></i>
                      <i class="fa fa-star"></i>
                      <i class="fa fa-star"></i>
                      <i class="fa fa-star"></i>
                    </span>
                    <span class="course-one__count">{list.avg_rating}</span>
                    <span class="course-one__stars-count">{list.rating_count}</span>
                  </div>
                  <div class="course-one__meta">
                    <a><i class="far fa-clock"></i> {list.offer_detail.duration}</a>
                    <a><i class="far fa-folder-open"></i> {list.offer_detail.lecture_count} {I18n.t("Lectures")}</a>
                    <a>R$ {list.price}</a>
                  </div>
                  <Link to={"/course-detail/" + list.id + "/" + list.slug} class="course-one__link"> {I18n.t("See Preview")}</Link>
                </div>
              </div>
            </Carousel.Item>
          ))}

        </Carousel>
      </div>
      <>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <div className="modal-title-c h4">  {I18n.t("View all instructors")}</div>
          </Modal.Header>
          <Modal.Body>

            {
              course.map((list) => (
                temp_id === list.id ?
                  // <div className="row" style={{ paddingBottom: '15px' }}>
                  <>
                    {(list.offer_instructor.length === 0) ?
                      <Tr>
                        <Td colspan="12" style={{ textAlign: 'center' }}>
                          <Alert key={'alert'} variant={'info'} style={{ width: '350px', marginLeft: '50px' }}>
                            {I18n.t('No Instructor')}
                          </Alert>
                        </Td>
                      </Tr> : ''}
                    {list.offer_instructor.map((inst) => (

                      <div className="row" style={{ paddingBottom: '20px' }}>
                        <div className="col-lg-3">
                          <div className='user-image-c'>
                            <Link to={"/instructor-detail/" + inst.user.id}>
                              {inst.user.media !== null ?
                                <img src={inst.user.media.url} className="img-fluid" style={{ borderRadius: '4px' }} />
                                : <img src={defaultImg} className="img-fluid" style={{ borderRadius: '4px' }} />}
                            </Link>
                          </div>
                        </div>
                        <div className="col-lg-9">
                          <div className="user-name-c">
                            <Link to={"/instructor-detail/" + inst.user.id}>
                              <h6>{inst.user.first_name} {inst.user.last_name}</h6>
                            </Link>

                            {/* <h6>instructor name</h6> */}
                          </div>
                          <br />
                        </div>
                      </div>

                    ))}
                    {/* // </div> */}
                  </>
                  : ""
              ))}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              {I18n.t("Close")}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    </>
  )
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CourseResponsive);
