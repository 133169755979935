import React, { useState, useEffect } from 'react';
import slider from './images/slider-2-2.jpg'
import photo1 from './images/banner2.jpg'
import photo2 from './images/banner3.jpg'
import btnImage from './images/btn-img.png'
import Carousel from 'react-bootstrap/Carousel';
import { Translate, I18n } from "react-redux-i18n";
import { connect } from 'react-redux';
import { toggleLoader } from '../../actions/loader';
import { Link } from 'react-router-dom';
import { accessUserLogin, carouselList } from '../../Utils/utils';
import { getUserData } from '../../actions/userdata';

const CarouselSlider = ({ handleShowLogin, getUserData, userdata }) => {
  const [user_data, setUserData] = useState(null);
  const [carousel, setCarousel] = useState([]);
  const languageSelected = (localStorage.getItem('langauge_selected') == 'en' || localStorage.getItem('langauge_selected') == '') ? 'en' : 'pt' 
  const userCallMethod = async () => {
    var final_data = await accessUserLogin();
    getUserData(final_data);
    return final_data;
  }
  
  useEffect(async () => {
    var carousel_var = await carouselList();
    setCarousel(carousel_var);
    console.log(carousel_var)
    var data = userCallMethod();
    setUserData(data)
  }, [])
  return (
    <>
      <div id="demo" className="carousel slide" data-ride="carousel">

        <Carousel>
          {carousel.map((car) => (
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={car.media.url}
                alt="First slide"
              />
              <Carousel.Caption>
                <div className="carousel-caption">
                  {/* <p>{I18n.t("Metodo dutra /")}</p> */}
                  <h3>{ languageSelected === 'en' ? car.head_text : car.head_text_pt } <br />
                    <span> { languageSelected === 'en' ? car.description : car.description_pt }</span>
                  </h3>
                  {userdata.userData === null || userdata.userData === '' ?
                    <a className='theme-btn' onClick={e => handleShowLogin(true)}>
                      <div className='btn-type'>
                        {I18n.t("Student Login")}
                      </div>
                      {/* <img src={btnImage} /> */}
                    </a>
                    : ""}
                </div>
              </Carousel.Caption>
            </Carousel.Item>
          ))}
          {/* <Carousel.Item>
            <img
              className="d-block w-100"
              src={slider}
              alt="Second slide"
            />

            <Carousel.Caption>
              <div className="carousel-caption">
                 <h3>{I18n.t("Metodo dutra")} <br />
                  <span> {I18n.t("Sua empresa pode mais!!")}</span>
                  </h3>
                  {userdata.userData === null || userdata.userData === '' ? 
                  <a className='theme-btn' onClick={e => handleShowLogin(true)}>
                    <div className='btn-type'>
                      {I18n.t("Student Login")}
                    </div>
                  </a>
               :""  } 
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={slider}
              alt="Third slide"
            />
            <Carousel.Caption>
              <div className="carousel-caption">
                <h3>{I18n.t("Metodo dutra")} <br />
                  <span> {I18n.t("Sua empresa pode mais!!")}</span>
                  </h3>
                  {userdata.userData === null || userdata.userData === '' ? 
                  <a className='theme-btn' onClick={e => handleShowLogin(true)}>
                    <div className='btn-type'>
                      {I18n.t("Student Login")}
                    </div>
                  </a>
               :""  } 
              </div>
            </Carousel.Caption>
          </Carousel.Item>*/}
        </Carousel>
      </div>
    </>
  )
}
const mapDispatchToProps = {
  toggleLoader,
  getUserData
}
const mapStateToProps = (state) => {
  return {
    userdata: state.userdata
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CarouselSlider);
