import './App.css';
import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/style.css'
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { toggleLoader } from './actions/loader';
import { connect } from 'react-redux';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
// import { getUserData } from './actions/userdata'
import { getUserData } from './actions/userdata'
import { accessUserLogin } from './Utils/utils'

import Dashboard from './Dashboard/';
import CourseList from './CourseList';
import InstructorList from './InstructorList';
import CourseDetail from './CourseDetail';
import VerifyEmail from './VerifyEmail';
import InstructorDetail from './InstructorDetail';
import MyCourse from './MyCourse';
import Profile from './Profile';
import EditProfile from './EditProfile';
import ChangePassword from './ChangePassword';
import Certificate from './Certificate';
import ProtectedRoute from "./ProtectedRoute";
import LiveEvent from './LiveEvent';
import LiveEventDetail from './LiveEventDetail';
import ContactUs from './ContactUs';
import Faqs from './Faqs';
import TermsAndConditions from './TermsAndConditions';
import PrivacyPolicy from './PrivacyPolicy';
import CourseConsumption from './CourseConsumption';
import Checkout from './Checkout';
import FreeCheckout from './FreeCheckout';
import Notifications from './Notifications';
import LoadingSpinner from "./components/LoadingSpinner";
import { setLocaleWithFallback } from "./actions/i18n"
function App(props) {
  const [default_language, setDefaultLanguage] = useState('pt');
  useEffect(async () => {
    props.toggleLoader(true);
    var auth_me = await accessUserLogin()
    props.getUserData(auth_me);
    props.toggleLoader(false);
    if (localStorage.getItem('langauge_selected') !== undefined && localStorage.getItem('langauge_selected') !== null) {
      setDefaultLanguage(localStorage.getItem('langauge_selected'));
      props.setLocaleWithFallback(localStorage.getItem('langauge_selected'));
    } else {
      setDefaultLanguage("pt");
      props.setLocaleWithFallback("pt");
      localStorage.setItem('langauge_selected', "pt");
    }
    document.dir = props.locale === "en" ? "en" : "pt";
  }, [props.locale])
  return (
    <React.Fragment>
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Dashboard} />
          <Route exact path="/course-list" component={CourseList} />
          <Route exact path="/instructor-list" component={InstructorList} />
          <Route exact path={"/course-detail/:id/:slug"} component={CourseDetail} />
          <Route exact path="/verify" component={VerifyEmail} />
          <Route exact path="/reset/:token" component={Dashboard} />
          <Route exact path="/password/reset" component={Dashboard} />

          <Route exact path="/instructor-detail/:id" component={InstructorDetail} />
          <ProtectedRoute exact path="/mycourse" component={MyCourse} />
          <ProtectedRoute exact path="/Profile" component={Profile} />
          <ProtectedRoute exact path="/edit-profile" component={EditProfile} />
          <ProtectedRoute exact path="/change-password" component={ChangePassword} />
          <ProtectedRoute exact path="/certificate" component={Certificate} />
          <ProtectedRoute exact path="/live-event" component={LiveEvent} />
          <ProtectedRoute exact path={"/course/liveevent/:id"} component={LiveEventDetail} />
          <Route exact path="/contactus" component={ContactUs} />
          <Route exact path="/faqs" component={Faqs} />
          <Route exact path="/termsandconditions" component={TermsAndConditions} />
          <Route exact path="/privacypolicy" component={PrivacyPolicy} />
          <ProtectedRoute exact path={"/course/:id/consumption"} component={CourseConsumption} />
          <Route exact path="/checkout/:id/:slug" component={Checkout} />
          <Route exact path="/free/checkout/:id/:slug" component={FreeCheckout} />
          <ProtectedRoute exact path="/notifications" component={Notifications}/>

        </Switch>
      </BrowserRouter>
      <LoadingSpinner />
    </React.Fragment >
  );
}

const mapDispatchToProps = {
  getUserData,
  toggleLoader,
  setLocaleWithFallback
}
const mapStateToProps = (state) => {
  return {
    userdata: state.userdata,
    locale: state.i18n.locale
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(App);
