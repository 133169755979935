import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal'
import { toggleLoader } from '../actions/loader';
import Button from 'react-bootstrap/Button';
import { connect } from 'react-redux';
import { Rating, RatingView } from 'react-simple-star-rating'
import Form from 'react-bootstrap/Form';
// import { Translate, I18n } from "react-redux-i18n";
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import apiUrl from "../config/apiPath";
import config from "../config/config";
import axios from "axios";
import { Translate, I18n } from "react-redux-i18n";
import { displayError, displaySuccess, getRatings, getRatingsPercent } from '../Utils/utils'
const RatingForm = (props) => {
  // const [show, setShow] = useState(false);

  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
  const [rating, setRating] = useState(5) // initial rating value
  const [image_quality, setImageQuality] = useState(false)
  const [content_improved, setContentImproved] = useState(false)
  const [sound_quality, setSoundQuality] = useState(false)
  const [hate_speech, setHateSpeech] = useState(false)
  const [interest_me, setContentInterestMe] = useState(false)
  const [offensive_content, setOffensiveContent] = useState(false)
  const [offer_id, setOfferId] = useState(props.offer_id)
  const [topic_id, setTopicId] = useState(props.topic_id);
  // Catch Rating value
  const handleRating = (rate) => {
    setRating(rate)
    if (rate > 3) {
      setImageQuality(false)
      setContentImproved(false)
      setSoundQuality(false)
      setHateSpeech(false)
      setContentInterestMe(false)
      setOffensiveContent(false)
    }
    // Some logic
  }

  useEffect(() => {
    if (props.my_rating !== null) {
      setRating(props.my_rating.rating)
      setImageQuality(props.my_rating.image_quality)
      setContentImproved(props.my_rating.content_improved)
      setSoundQuality(props.my_rating.sound_quality)
      setHateSpeech(props.my_rating.hate_speech)
      setContentInterestMe(props.my_rating.interest_me)
      setOffensiveContent(props.my_rating.offensive_content)
    }
  }, [])

  const submitReview = async (topic_id) => {
    const data = {
      rating: rating,
      offer_id: offer_id,
      image_quality: image_quality,
      content_improved: content_improved,
      sound_quality: sound_quality,
      hate_speech: hate_speech,
      interest_me: interest_me,
      offensive_content: offensive_content,
    }
    let path = config.siteUrl;
    var token = localStorage.getItem('token');
    axios.post(path + "/student/course/rating", data, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    })
      .then(async (response) => {
        displaySuccess(I18n.t('Rating Successfull'));
        props.toggleLoader(false);
        props.onHide()
        props.onRatingGiven(topic_id)

      })
      .catch((error) => {
        displayError(error);
        if (error.response.status === 401) {
          window.location.href = '/';
        }
        props.toggleLoader(false);
      });
  }
  return (
    <>
      {/* <Button variant="primary" onClick={handleShow}>
                Launch demo modal
              </Button> */}

      <Modal  {...props}>
        <Modal.Header closeButton>
          <div className="modal-title h4"> {I18n.t("Provide Your Feedback")}</div>
        </Modal.Header>
        <Modal.Body>
          <p style={{ textAlign: 'center', fontSize: '20px' }}>
            <Rating onClick={handleRating} ratingValue={rating} />
          </p>
          {
            (rating <= 3) ?
              <div className="row">
                <div className='col'>
                  <div className='select-help-improve'>
                    <h6>{I18n.t("Help us improve, what impacted your grade negatively")}?</h6>
                    <form>
                      <div className="row">
                        <div className='col-lg-5 mt-1'>
                          <div class="mb-3 form-group">
                            <div class="form-check">
                              <input type="checkbox" id="formBasicCheckbox1" class="form-check-input checkbox-rate" checked={image_quality}
                                onClick={e => { setImageQuality(e.target.checked) }} />
                              <label title="" for="formBasicCheckbox1" class="form-check-label">{I18n.t("Image Quality")}
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className='col-lg-7 mt-1'>
                          <div class="mb-3 form-group">
                            <div class="form-check">
                              <input type="checkbox" id="formBasicCheckbox1" class="form-check-input checkbox-rate" checked={content_improved}
                                onClick={e => { setContentImproved(e.target.checked) }} />
                              <label title="" for="formBasicCheckbox1" class="form-check-label">{I18n.t("Content could be improved")}
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className='col-lg-5 mt-1'>
                          <div class="mb-3 form-group">
                            <div class="form-check">
                              <input type="checkbox" id="formBasicCheckbox1" class="form-check-input checkbox-rate" checked={sound_quality}
                                onClick={e => { setSoundQuality(e.target.checked) }} />
                              <label title="" for="formBasicCheckbox1" class="form-check-label">{I18n.t("Sound Quality")}
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className='col-lg-7 mt-1'>
                          <div class="mb-3 form-group">
                            <div class="form-check">
                              <input type="checkbox" id="formBasicCheckbox1" class="form-check-input checkbox-rate" checked={offensive_content}
                                onClick={e => { setOffensiveContent(e.target.checked) }} />
                              <label title="" for="formBasicCheckbox1" class="form-check-label">{I18n.t("Offensive Content")}
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className='col-lg-5 mt-1'>
                          <div class="mb-3 form-group">
                            <div class="form-check">
                              <input type="checkbox" id="formBasicCheckbox1" class="form-check-input checkbox-rate" checked={hate_speech}
                                onClick={e => { setHateSpeech(e.target.checked) }} />
                              <label title="" for="formBasicCheckbox1" class="form-check-label">{I18n.t("Hate Speech")}
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className='col-lg-7 mt-1'>
                          <div class="mb-3 form-group">
                            <div class="form-check">
                              <input type="checkbox" id="formBasicCheckbox1" class="form-check-input checkbox-rate" checked={interest_me}
                                onClick={e => { setContentInterestMe(e.target.checked) }} />
                              <label title="" for="formBasicCheckbox1" class="form-check-label">{I18n.t("The content doesnt interest me")}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              : ''
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={props.onHide}>
            {I18n.t("Close")}
          </Button>
          <Button variant="primary" onClick={props.onHide} onClick={() => submitReview(topic_id)}>
            {I18n.t("Submit")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(RatingForm);