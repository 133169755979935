import logger from 'redux-logger'
import thunk from 'redux-thunk'
import rootReducer from './index'
import { createStore, applyMiddleware } from 'redux'
import {
  setLocale,
  loadTranslations,
  syncTranslationWithStore,
} from "react-redux-i18n";
import translations from '../l10n/translations';
//const middleware = applyMiddleware(thunk, logger);
const middleware = applyMiddleware(thunk);
const store = createStore(rootReducer, middleware);
syncTranslationWithStore(store);
store.dispatch(loadTranslations(translations));
if (localStorage.getItem('langauge_selected') !== undefined && localStorage.getItem('langauge_selected') !== '') {
  store.dispatch(setLocale(localStorage.getItem('langauge_selected')));
} else {
  store.dispatch(setLocale("pt"));
}
export default store