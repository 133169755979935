import React, { useState, useEffect } from 'react';
import Topbar from '../components/Topbar';
import Footer from '../components/Footer';
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import { connect } from 'react-redux';
import axios from "axios";
import { toggleLoader } from '../actions/loader';
import { instructorDetail, instructorCourseList } from '../Utils/utils';
import Alert from 'react-bootstrap/Alert'
import parse from "html-react-parser";
import team1 from './images/team-1-1.jpg';
import { Link } from 'react-router-dom';
import { Translate, I18n } from "react-redux-i18n";
import defaultImg from './images/index.jpeg';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
const InstructorDetail = (props) => {
  const localePriceFormat = (localStorage.getItem('langauge_selected') == 'en' || localStorage.getItem('langauge_selected') == '') ? 'en' : 'pt-BR'
  const [first_name, setFirstName] = useState('');
  const [last_name, setLastName] = useState('')
  const [role_txt, setRoleTxt] = useState('');
  const [about_you, setAboutYou] = useState('');
  const [speciality_in, setSpecialityIn] = useState('');
  const [description, setDescription] = useState('');
  const [instructor_image, setInstructorImage] = useState('');
  const [course_list, setCourseList] = useState([]);
  const [timezone, setTimezone] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [country, setCountry] = useState('');
  const [facebook, setFacebook] = useState('');
  const [linkedin, setLinkedin] = useState('');
  const [twitter, setTwitter] = useState('');
  const [avg_rating, setAvgRating] = useState('');
  const [offer_instructor, setOfferInstructor] = useState([]);
  const [show, setShow] = useState(false);
  const [instagram, setInstagram] = useState('');
  const [temp_id, setTempId] = useState('');
  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    setShow(true)
    setTempId(id)
  }
  useEffect(async () => {
    props.toggleLoader(true);
    var detail_var = await instructorDetail(props.match.params.id);
    if (detail_var !== null) {
      setFirstName(detail_var.first_name);
      setLastName(detail_var.last_name);
      setRoleTxt(detail_var.role_txt);
      if (detail_var.about_you !== null) {
        setAboutYou(detail_var.about_you);
      }
      setSpecialityIn(detail_var.speciality_in);
      setDescription(detail_var.description);
      if (detail_var.media !== null) {
        setInstructorImage(detail_var.media.url);
      } else {
        setInstructorImage(defaultImg);
      }
      if (detail_var.city !== null) {
        setCity(detail_var.city.name);
      }

      if (detail_var.state !== null) {
        setState(detail_var.state.name);
      }
      if (detail_var.country !== null) {
        setCountry(detail_var.country.name);
      }
      setTimezone(detail_var.timezone);
      setTwitter(detail_var.twitter);
      setLinkedin(detail_var.linkedin);
      setInstagram(detail_var.instagram);
      setFacebook(detail_var.facebook);
      setAvgRating(detail_var.avg_rating)
      props.toggleLoader(false);
    }
  }, [])
  useEffect(async () => {
    props.toggleLoader(true);
    var course_var = await instructorCourseList(props.match.params.id);
    setCourseList(course_var);
    props.toggleLoader(false);

  }, [])
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const printStars = (avg_star) => {
    var print_html = [];
    for (var i = 1; i <= 5; i++) {
      if (avg_star >= i) {
        print_html.push(<i className="icofont-star text-warning"></i>);
      } else {
        print_html.push(<i className="icofont-star rate-select " style={{ color: '#eee' }}></i>);
      }
    }
    return print_html;
  }
  return (
    <>
      <body id="page-top">
        <div classNameName="page-wrapper">

          <Topbar />
          <section className="inner-banner d-none d-sm-block">
            <div className="container">
              <ul className="list-unstyled thm-breadcrumb">
                <li><Link to="/">{I18n.t("Home")}</Link></li>
                <li className="active"><Link to="/instructor-list">{I18n.t("Instructors")}</Link></li>
              </ul >
              <h2 className="inner-banner__title">{I18n.t("Instructor Details")}</h2>
            </div >
          </section >
          <section class="inner-banner-responsive d-block d-sm-none">
            <div class="container">
              <Breadcrumb>
                <Breadcrumb.Item href="/">{I18n.t("Home")} </Breadcrumb.Item>
                <Breadcrumb.Item active>
                  {I18n.t("Instructor Details")}
                </Breadcrumb.Item>
              </Breadcrumb>
            </div >
          </section >
          <main>
            <section className='py-5'>
              <div className="container">
                <div className="row">
                  <div className="col-md-4 col-lg-3 col-sm-12">
                    <div className="instructor-item type2  default">

                      <img src={instructor_image} className="img-fluid" alt="Instructor Image" title="Instructor Image" />
                      <div className="instructor-item-meta-data">
                        <h4>
                          <a href="#" rel="author">
                            {first_name} {last_name}
                          </a>
                        </h4>
                        {/* <h6>{I18n.t('Digital Media')}</h6> */}
                        <div className="team-social-links">
                          <ul className="team-social pl-0">
                            {facebook !== null ?
                            <li><Link className="facebook" to={{ pathname: facebook }} target="_blank"><i className="icofont-facebook"></i></Link></li>:""}
                            {twitter !== null ?
                            <li><Link className="twitter" to={{ pathname: twitter }} target="_blank"><i className="icofont-twitter"></i></Link></li>:""}
                            {linkedin !== null ?
                            <li><Link className="linkdin" to={{ pathname: linkedin }} target="_blank"><i className="icofont-linkedin"></i></Link></li>:""}
                            {instagram !== null ?
                            <li><Link className="instagram" to={{ pathname: instagram }} target="_blank"><i className="icofont-instagram"></i></Link></li>:""}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-8 col-lg-9 col-sm-12 mt-4 mt-md-0">
                    <div className="instrurors-information">
                      <h5 className="instructor__name">
                        {first_name} {last_name} <br />
                        <div className="rating-star-instrutor" style={{ paddingTop: '10px' }}>
                          <i className="icofont-star"></i>
                          ({avg_rating})
                        </div>
                        <div className="instructor_detail">
                          {parse(about_you)}
                        </div>
                      </h5>
                      <ul className="teachers-details">
                        {role_txt !== null ? <li> {I18n.t('Role')} : {role_txt} </li> : ""}
                        {speciality_in !== null ? <li> {I18n.t('Specialist in')} : {speciality_in} </li> : ""}
                        {country !== null ? <li> {I18n.t('Country')} : {country} </li> : "N/A"}
                        {state !== null ? <li> {I18n.t('State')} : {state} </li> : "N/A"}
                        {city !== null ? <li> {I18n.t('City')} : {city} </li> : "N/A"}
                        {timezone !== null ? <li> {I18n.t('Timezone')} : {timezone} </li> : "N/A"}
                      </ul >
                      <div className="description">
                        <p className="lead">
                          {description}
                        </p>
                      </div>
                    </div >
                  </div >
                </div >

                <div className="row mt-4 mt-md-0">
                  <h4 className="col-12 m-0">
                    {first_name} {last_name}
                  </h4>
                  <p className="lead col-12">
                    {I18n.t('Register for classes with')} {first_name} {last_name}
                  </p >
                  {(course_list.length === 0) ?
                    <div colspan="7" classNAme="col-md-12" style={{ textAlign: 'center', width: '100%' }}>
                      <Alert key={'alert'} variant={'info'}>
                        {I18n.t('No Course Available')}
                      </Alert>
                    </div>
                    : ''}
                  {course_list.map((list) => (
                    <div class="col-lg-3">
                      <div class="course-one__single color-1">
                        <div class="course-one__image">
                        <a  class="course-one__category">{I18n.t(list.category.name)}</a>
                          <img src={list.media.url} alt="" />
                          <i class="far fa-heart"></i>
                        </div>
                        <div class="course-one__content">
                         
                          <h3 className='instructor-name ' onClick={e => handleShow(list.id)} style={{ cursor: 'pointer' }}>
                            <i class="fa fa-users"></i> {I18n.t("View Instructors")}
                          </h3>
                          <Link to={"/course-detail/" + list.id + "/" + list.slug}>
                  <h2 className="course-one__title"><a >{list.name}</a></h2>
                  </Link>
                          <div class="course-one__stars">
                            <span class="course-one__stars-wrap">
                              {printStars(list.avg_rating)}
                            </span>
                            <span class="course-one__stars-count">{list.avg_rating}</span>
                          </div>
                          <div class="course-one__meta">
                            <a ><i class="far fa-clock"></i> {list.offer_detail.duration}</a>
                            <a ><i class="far fa-folder-open"></i>{list.offer_detail.lecture_count}</a>
                            <a >R$ {parseFloat(list.price).toLocaleString(localePriceFormat)}</a>
                          </div>
                          <Link to={"/course-detail/" + list.id + "/" + list.slug} class="course-one__link">{I18n.t('See Preview')}</Link>
                        </div >
                      </div >
                    </div >
                  ))
                  }


                </div >
              </div >
            </section >
            <>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <div className="modal-title-c h4">  {I18n.t("View all instructors")}</div>
          </Modal.Header>
          <Modal.Body>

            {
              course_list.map((list) => (
                temp_id === list.id ?
                  // <div className="row" style={{ paddingBottom: '15px' }}>
                  <>
                    {(list.offer_instructor.length === 0) ?
                      <Tr>
                        <Td colspan="12" style={{ textAlign: 'center' }}>
                          <Alert key={'alert'} variant={'info'}style={{width: '350px',marginLeft: '50px'}}>
                          {I18n.t('No Instructor')}
                          </Alert>
                        </Td>
                      </Tr> : ''}
                    {list.offer_instructor.map((inst) => (

                      <div className="row" style={{ paddingBottom: '20px' }}>
                        <div className="col-lg-3">
                          <div className='user-image-c'>
                            <Link to={"/instructor-detail/" + inst.user.id}>
                              {inst.user.media !== null ?
                                <img src={inst.user.media.url} className="img-fluid" style={{ borderRadius: '4px' }} />
                                : <img src={defaultImg} className="img-fluid" style={{ borderRadius: '4px' }} />}
                            </Link>
                          </div>
                        </div>
                        <div className="col-lg-9">
                          <div className="user-name-c">
                          <Link to={"/instructor-detail/" + inst.user.id}>
                              <h6>{inst.user.first_name} {inst.user.last_name}</h6>
                              </Link>
                            {/* <h6>instructor name</h6> */}
                          </div>
                          <br />
                        </div>
                      </div>

                    ))}
                    {/* // </div> */}
                  </>
                  : ""
              ))}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              {I18n.t("Close")}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
          </main >
        </div >
        <Footer />
      </body >
    </>
  )
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(InstructorDetail);
