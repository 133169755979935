import React, { useEffect, useState } from 'react';
import Topbar from '../components/Topbar';
import Footer from '../components/Footer';
import { toggleLoader } from '../actions/loader';
import { connect } from 'react-redux';
import { Translate, I18n } from "react-redux-i18n";
import { Link } from 'react-router-dom';
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import './style.css';

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <body id="page-top">
        <div className="page-wrapper">

          <Topbar />

          <section className="inner-banner d-none d-sm-block">
            <div className="container">
              <ol className="list-unstyled thm-breadcrumb">
                <li><Link to="/">{I18n.t("Home")}</Link></li>
                <li className="active"><Link>{I18n.t("PRIVACY AND SECURITY POLICY")}</Link></li>
              </ol>
              <h2 className="inner-banner__title">{I18n.t("PRIVACY AND SECURITY POLICY")}</h2>
            </div>
          </section>
          <section className="inner-banner-responsive d-block d-sm-none">
            <div className="container">
              <Breadcrumb>
                <Breadcrumb.Item href="/">{I18n.t("Home")}</Breadcrumb.Item>
                <Breadcrumb.Item href="" active>
                  {I18n.t("PRIVACY AND SECURITY POLICY")}
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </section>
          <br />
          <div className="container-privacy">
            <div className="row justify-content-center" style={{ marginTop: '30px', paddingLeft: '30px', paddingRight: '30px' }}>
              <div className="col-md-12 col-sm-12 col-lg-12">
                <h3><center>{I18n.t('PRIVACY AND SECURITY POLICY')}</center></h3>
                {I18n.t('The purpose of this Privacy Policy is to demonstrate the commitment of')} <strong>{I18n.t('DUTRA ANALYTICS SERVICOS DE INFORMACAO LTDA ,owner of the Dutra for Education platform')}</strong>, {I18n.t(' a legal entity governed by private law, headquartered in the city of Novo Hamburgo, state of Rio Grande do Sul, at Rua Tupi , nº 758, CONJ 40, Bairro Rio Branco, CEP 93336-010, registered with the CNPJ under nº 29653583/0001-31, with the privacy and protection of personal data collected from its users, establishing the rules on collection, registration, storage, use, sharing, enrichment and elimination of the data collected within the scope of the services and functionalities of the DUTRA FOR EDUCATION platform, in accordance with the laws in force As a condition for accessing and using the exclusive features of the DUTRA FOR EDUCATION platform, the USER declares that he is over 18 (eighteen) years of age and that he has read the rules of this document and the Terms of Use fully and carefully, thus being fully aware, thus conferring his free and express agreement with the terms stipulated herein If you do not comply with these guidelines, you must discontinue your access')}.
              </div>
            </div>
            <div className="row ">
              <div className="col-md-12 col-sm-12 col-lg-12" style={{ color: '#52565b', fontSize: '15px', fontWeight: '400' }}>
                <ol>
                  <li><>{I18n.t('DATA COLLECTED FORM AND PURPOSE OF COLLECTION')}</>
                    <ol>
                      <li>{I18n.t('Data is collected when the USER voluntarily enters or submits when accessing and interacting with the features available on this website')}
                      </li>
                      <li>{I18n.t('We are not responsible for the accuracy veracity or lack thereof in the information that the USER provides to the COMPANY or for its outdatedness when it is your responsibility to provide it accurately or to update it')}
                        <ol>
                          <li>
                            {I18n.t('All technologies used will always respect current legislation and the terms of this Privacy Policy')}
                          </li>
                          <li>
                            {I18n.t('The data collected is intended for the following purposes for which USERS provide their consent')}
                            <ol>
                              <li>{I18n.t('allow the COMPANY to contact the USER when their data is made available in the contact form')}</li>
                              <li>{I18n.t('authorize the sending of newsletter in cases where the USER enters his personal data in the field destined to the acceptance of this functionality')};</li>
                              <li>{I18n.t('access to the companys systems through login and password in a personal and non-transferable way')};</li>
                            </ol>
                          </li>
                        </ol>
                      </li>
                      <li> {I18n.t('The consent that the USER provides for the purposes of using the data is collected individually clearly specifically and legitimately')}
                      </li>
                      <li>{I18n.t('Through the CONTACT service channel (dpo@utreecombr) the USER may change their consent grants for the processing of their data grant new permissions or withdraw their consent to the current permissions being warned of the consequences that the withdrawal of consent may cause')}
                        <ol>
                          {I18n.t('The data collected and the activities recorded may also be shared')}
                          <ol>
                            <li>{I18n.t('With competent judicial administrative or governmental authorities whenever there is a legal determination request requisition or court order')} </li>
                            <li>{I18n.t('Automatically in case of corporate changes such as merger acquisition and incorporation')}</li>
                          </ol>
                        </ol>
                      </li>
                      <li>{I18n.t('The database formed by collecting data on the companys website is our property and responsibility and its use access and sharing when necessary will be done within the limits and purposes of our business and described in this Policy of Privacy')}
                        <ol>
                          <li>{I18n.t('Personally identifiable information will not be sold or rented by the COMPANY')}</li>
                          <li>{I18n.t('the USER is co-responsible for the confidentiality of his/her personal data. Sharing passwords and access data violates this Privacy Policy and the DUTRA FOR EDUCATION Terms of Use')}
                            {I18n.t('No emails will be sent asking the USER to forward his/her registration data')}
                          </li>
                        </ol>
                      </li>
                      <li>{I18n.t('Internally, the data we collect is accessed only by duly authorized professionals, respecting the principles of proportionality, necessity and relevance for the objectives of DUTRA FOR EDUCATION, in addition to the commitment to confidentiality and preservation of privacy under the terms of this Privacy Policy')}
                      </li>
                    </ol>
                  </li>
                  <li style={{ marginBottom: '20px' }}>
                    <>{I18n.t('HOW WE STORE DATA AND RECORDS')}</>
                    <ol>
                      <li>{I18n.t('The collected data and activity records will be stored in a safe and controlled environment for the minimum period stipulated by law according to the nature of the data')}</li>
                      <li>{I18n.t('If requested by the USER the data may be deleted before this deadline However it may happen that the data need to be kept for a longer period due to law court order fraud prevention (art 11 II “a” of the General Data Protection Law “LGPD” Law No 13709 /2018) credit protection (art 7 X LGPD) and other legitimate interests in accordance with article 10 of the LGPD At the end of the term and the legal need they will be excluded using safe disposal methods or used anonymously for statistical purposes')}</li>
                      <li> {I18n.t('The data collected will be stored on servers intended for such purposes')}</li>
                      <li>{I18n.t('Storage servers are structured in accordance with current legislation')}</li>
                    </ol>
                  </li>
                  <li style={{ marginBottom: '20px' }}>
                    <>{I18n.t('DISPLAY RECTIFICATION LIMITATION OPPOSITION AND DELETION OF DATA')}</>
                    <ol>
                      <li> {('the USER may request the display or rectification of their personal data, through the DUTRA FOR EDUCATION service channel, CONTATO (relacimento@metododutra.com.br)')}
                      </li>
                      <li>{I18n.t('Using the same tool the USER may also')}
                        <ol>
                          <li>{I18n.t('request the limitation of the use of your personal data')};</li>
                          <li>{I18n.t('express your opposition to the use of your personal data')};</li>
                          <li> {I18n.t('request the deletion of your personal data that we collect provided that any Access Accounts have been canceled and the minimum legal term related to data retention has elapsed')}</li>
                        </ol>

                      </li>
                      <li> {I18n.t('If the USER withdraws its consent for fundamental purposes to the regular functioning of the DUTRA FOR EDUCATION platform, the services and functionalities may be unavailable')}</li>
                      <li>{('If the USER does not grant his consent for the optional purposes, related to the sending of news, content, news and other events relevant to the maintenance of the relationship, the services and functionalities of the DUTRA FOR EDUCATION platform will continue to be made available regularly')}</li>
                      <li>{I18n.t('For purposes of auditing, security, fraud control and preservation of rights, we may keep the USERs data registration history for a longer period in the cases that the law or regulatory rule so establishes or for the preservation of rights')}
                      </li>
                    </ol>
                  </li>
                  <li style={{ marginBottom: '20px' }}>
                    <>{I18n.t('NEWSLETTER')}</>
                    <ol>
                      <li> {I18n.t('If you have registered to receive the DUTRA FOR EDUCATION newsletter, the USER agrees to receive news, personalized advertising about our products and services and other information')}
                        <ol>
                          <li>{I18n.t('A newsletter inclui novidades, ofertas e outras informações')}
                          </li>
                          <li>{I18n.t('By registering to receive the newsletter, the USER declares that he agrees that DUTRA FOR EDUCATION will carry out a personalized compilation of news, offers and other information, as well as evaluate its patterns of use of the website, in order to send a personalized newsletter that meets the needs and USER interests')}
                          </li>
                          <li> {I18n.t('The data is also stored and used for promotional purposes and market research To this end we will only contact the USER by email Finally we will also use your data to analyze and improve the effectiveness of our websites')}</li>
                          <li>{I18n.t('If the USER no longer wants to receive the newsletter, he/she may unsubscribe at any time. For this, the USER may click on the link present in all newsletters to be forwarded to the cancellation process or may use one of the means of communication available')}
                          </li>
                        </ol>
                      </li>
                    </ol>
                  </li>
                  <li style={{ marginBottom: '20px' }}>
                    <>{I18n.t('COOKIES')}</>
                    <ol>
                      <li>{('To facilitate the use of DUTRA FOR EDUCATION, the USER consents to the use of “Cookies”. Cookies are small units of data stored on your computers hard drive by your browser and that are necessary for the use of DUTRA FOR EDUCATION this cancellation or you can use one of the available means of communication')}
                      </li>
                      <li>
                        {I18n.t('Cookies allow a Digital Platform to memorize information about the USErs visit, their preferred language, their location, the recurrence of their sessions and other variables that DUTRA FOR EDUCATION considers relevant to make the experience much more efficient. These Cookies will also be used to help determine the usefulness, interest and number of uses of the websites, allowing for faster and more efficient navigation and eliminating the need to repeatedly enter the same information. They do not store personal data or collect personally identifiable information')}

                      </li>
                      <li> {I18n.t('Online behavioral advertising cookies')}
                        <ol>
                          <li>{I18n.t('DUTRA FOR EDUCATION reserves the right to use information obtained through Cookies from an analysis of the usage behavior of DUTRA FOR EDUCATION visitors, in order to display specific advertising for some of our products. DUTRA FOR EDUCATION believes that this action benefits the USER, because content or advertisements are displayed that DUTRA FOR EDUCATION believes correspond to the USERs interests - based on their browsing behavior, as they will see less advertising displayed randomly and less content that is not of your interest')}</li>
                        </ol>
                      </li>
                      <li>{I18n.t('How to prevent cookies from being stored on your hard drive and/or delete them')}
                        <ol>
                          <li>
                            {I18n.t('Most Internet browsers are configured to automatically accept Cookies. The USER may change the settings to block the use of Cookies or alert you when a Cookie is being sent to your device')}.</li>
                          <li>{I18n.t('After authorizing the use of Cookies the USER can always disable part or all of our Cookies')}
                          </li>
                          <li>{I18n.t('All browsers allow the user to accept, refuse or delete Cookies, namely by selecting the appropriate settings in the respective browser. The USER can configure Cookies in the options or preferences menu of their browser')}.</li>
                          <li>{I18n.t('Note that, by disabling cookies, you may prevent some web services from working properly, affecting, in part or in full, website navigation')}.
                          </li>
                        </ol>
                      </li>
                    </ol>
                  </li>
                  <li style={{ marginBottom: '20px' }}>
                    <>{I18n.t('PIXEL')}</>
                    <ol>
                      <li>{I18n.t('PIXEL is a tool used through COOKIES that captures basic access information and organizes users into access niches and content interests for commercial purposes')}
                      </li>
                      <li>{I18n.t('All information captured and stored by the PIXEL tool will be used for the exclusive use of DUTRA FOR EDUCATION')}</li>
                      <li>{I18n.t('DUTRA FOR EDUCATION reserves the right to use information obtained through Cookies and Pixel from an analysis of the usage behavior of DUTRA FOR EDUCATION visitors, in order to display specific advertising for some of our products. DUTRA FOR EDUCATION believes that this action benefits the USER, because content or advertisements are displayed that DUTRA FOR EDUCATION believes correspond to the USERs interests- based on their browsing behavior, as they will see less advertising displayed randomly and less content that is not of your interest')}</li>

                    </ol>
                  </li>
                  <li style={{ marginBottom: '20px' }}>
                    <>{I18n.t('GENERAL PROVISIONS')}</>
                    <ol>
                      <li> {I18n.t('We do not use any type of automated decision that impacts the USER')}

                      </li>
                      <li>{I18n.t('We have the right to change the content of this Privacy Policy at any time, according to the purpose or need, such as for legal adequacy and compliance with a law or rule that has equivalent legal force, and the USER is responsible for checking it whenever access on the DUTRA FOR EDUCATION platform')}
                        <ol>
                          <li>{I18n.t('If there are any updates to this document that require a new collection of consent, they will be notified through the contacts provided in the registration')}
                          </li>
                        </ol>
                      </li>
                      <li>{I18n.t('In case of any doubt regarding the provisions contained in this Security and Privacy Policy, the USER may contact the service channels available here or through the contact of the Person in Charge of the Processing of Personal Data (DPO) of DUTRA FOR EDUCATION, according to the following data')}
                        <li>{I18n.t('The General Data Protection Law (13709/2018) created the figure of the data protection officer (DPO)')}
                        </li>
                        <li>{I18n.t('This will be the professional responsible for processing the data of individuals in accordance with the law')}</li>
                        <p>{I18n.t('Data Protection Officer - DPO')}
                        </p>
                        <p>{I18n.t('DPO NAME')}
                        </p>
                        <p>{I18n.t('Contact Relationshipmetododutracombr')}
                        </p>
                        <p>+55 51 3527-4145</p>
                      </li>
                      <li> {I18n.t('If outsourced companies carry out the processing of any data we collect, they must comply with the conditions stipulated herein and our Information Security standards, obligatorily"')}</li>
                      <li>{I18n.t('If outsourced companies carry out the processing of any data we collect, they must comply with the conditions stipulated herein and our Information Security standards, obligatorily')}
                      </li>
                      <li>{('The USER recognizes that all communication carried out by e-mail (to the addresses informed in their registration) SMS instant communication applications or any other digital virtual and digital form are also valid effective and sufficient for the dissemination of any matter that refers to the services we provide as well as the conditions of their provision or any other subject addressed therein except for the expressly different provisions provided for in this Privacy Policy')}
                      </li>
                    </ol>
                  </li>
                  <li style={{ marginBottom: '20px' }}>
                    <>{I18n.t('APPLICABLE LAW AND JURISDICTION')}</>
                    <ol>
                      <li> {I18n.t('This Privacy Policy will be governed and interpreted according to Brazilian legislation, in the Portuguese language, and the São Paulo/SP District Court will be elected to settle any dispute or controversy involving this document, with express waiver of any other, however privileged whatever')}
                        {I18n.t('Update: February 28, 2022')}


                      </li>
                    </ol>
                  </li>
                </ol>

                {/* </li> */}
                {/* </ol>  */}
              </div>
            </div>
          </div>

          <Footer />
        </div >
      </body >
    </>
  )
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicy);