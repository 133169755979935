import React, { useState, useEffect } from "react";
import { toggleLoader } from '../actions/loader';
import { displayError, displaySuccess, verifyEmail, displayErrorString } from '../Utils/utils';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';
import { Translate, I18n } from "react-redux-i18n";
const VerifyEmail = (props) => {
  const history = useHistory();

  useEffect(async () => {
    props.toggleLoader(true);
    const token = queryString.parse(window.location.search);
    console.log(token.reset_token);
    var verify_email = await verifyEmail(token.reset_token)
    console.log(token);
    if (verify_email === true) {
      displaySuccess(I18n.t('Email Verified successfully'));
      setTimeout(() => history.push("/"), 3000);
    }
    else {
      displayErrorString('invalid token');
      // setTimeout(() => history.push("/register"), 3000);
    }
    props.toggleLoader(false);
  }, [])

  return (
    <>
      {/* <body>

        <Topbar />

        <main>
          <div className="login-5">
            <div className="container">
              <div className="row login-box">
                <div className="col-lg-6 align-self-center pad-0">
                  <div className="form-section align-self-center">
                    <h3>Sign into your account</h3>
                    <div className="btn-section clearfix">
                      <Link to="/login"><a href="" className="link-btn btn-1 default-bg">Login</a></Link>
                      <a href="" className="link-btn active btn-2 active-bg">Register</a>
                    </div>
                    <div className="clearfix" />
                    <div className="form-group form-box">
                      <input type="text" name="first name" className="input-text" placeholder="First Name" />
                    </div>
                    <div className="form-group form-box">
                      <input type="text" name="last name" className="input-text" placeholder="Last Name" />
                    </div>
                    <div className="form-group form-box">
                      <input type="email" name="email" className="input-text" placeholder="Email Address" />
                    </div>
                    <div className="form-group form-box clearfix">
                      <input type="password" name="Password" className="input-text" placeholder="Password" />
                    </div>
                    <div className="form-group form-box clearfix">
                      <input type="password" name="Password" className="input-text" placeholder="Confirm Password" />
                    </div>
                    <div className="form-group clearfix">

                      <button type="submit" className="btn-md btn-theme float-left">Register</button>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 bg-color-15 align-self-center pad-0 none-992 bg-img">
                  <div className="info clearfix">
                    <div className="logo-2">
                      <a href="">
                        <img src="/img/logo-png.png" width="70px" alt="logo" />
                      </a>
                    </div>
                    <h3>Welcome to On Global</h3>
                    <div className="social-list">
                      <a href="https://www.facebook.com/dtsgroupeventos" target="_new" className="facebook-bg">
                        <i className="icofont-facebook" />
                      </a>
                      <a href="https://www.instagram.com/dtsgroup/?hl=pt-br" target="_new" className="instagram">
                        <i className="icofont-instagram" />
                      </a>
                      <a href="https://www.linkedin.com/company/dtsgroupeventos/" target="_new" className="linkedin-bg">
                        <i className="icofont-linkedin" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>

        <Footer />

      </body> */}
    </>
  )
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(VerifyEmail);
