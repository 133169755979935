import React, { useState, useEffect } from 'react';
import Topbar from '../components/Topbar';
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';
import './style.css';
import { toggleLoader } from '../actions/loader';
import { connect } from 'react-redux';
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import "bootstrap/dist/css/bootstrap.min.css";
import { formatCreditCardNumber, formatExpirationDate, formatCVC } from './CardUtils';
import Payment from 'payment';
import { CourseDetail, getUserProfile, getCountry, getStates, getCities, displayError, displaySuccess, getCreditCardRates, knowCoursePrice, accessUserLogin, displayErrorString } from '../Utils/utils';
import axios from "axios";
import config from "../config/config";
import queryString from 'query-string';
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import { getUserData } from '../actions/userdata';
import { useHistory } from 'react-router-dom';
import Toast from 'react-bootstrap/Toast';
import Button from 'react-bootstrap/Button';
import { Translate, I18n } from "react-redux-i18n";
import ReactInputMask from "react-input-mask";
const Checkout = ({ value, onChange, getUserData, userdata, ...props }) => {
  const history = useHistory();

  const localePriceFormat = (localStorage.getItem('langauge_selected') == 'en' || localStorage.getItem('langauge_selected') == '') ? 'en' : 'pt-BR'
  let [mask, setMask] = React.useState(null);
  const [number, setCardNumber] = useState('');
  const [name, setCardName] = useState('');
  const [cvc, setCardSecurityCode] = useState('');
  const [expiry, setCardValidity] = useState('');
  const [focus, setCardFocused] = useState();
  const [issuer, setCardIssuer] = useState('');

  const [course_image, setCourseImage] = useState('');
  const [title, setTitle] = useState('');
  const [instructor_name, setInstructorName] = useState('');
  const [price, setPrice] = useState(0);
  const [installment_quantity, setInstallmentQuantity] = useState(1);

  const [first_name, setFirstName] = useState('');
  const [last_name, setLastName] = useState('');
  const [address, setAddress] = useState('');
  const [zip_code, setZipCode] = useState('');
  const [country_id, setCountryId] = useState(30);
  const [state_id, setStateId] = useState('');
  const [email, setEmail] = useState('');
  const [cpf, setCpf] = useState('');
  const [city_id, setCityId] = useState('');
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [countries, setCountries] = useState([]);
  const [payment_type, setPaymentType] = useState(3);
  const [boleto_rate, setBoletoRate] = useState(0);
  const [discount_percentage, setDiscountPrecentage] = useState('');
  const parsed = queryString.parse(window.location.search);
  const [cross_sell_id, setCrossSellId] = useState(parsed.cross_sell_id);

  const [card_holder_name, setCardHolderName] = useState('');
  const [card_no, setCardNo] = useState('');
  const [securityCode, setSecurityCode] = useState('');
  const [validThru, setValidThru] = useState('');
  const [card_rate, setCardRate] = useState('0');
  const [emailReadOnly, setEmailReadOnly] = useState(false);

  const [installment_no, setInstallmentNo] = useState([]);
  const [install, setInstall] = useState('');
  const [price_after_discount, SetPriceAfterDiscount] = useState('');
  const [price_after_installment, setPriceAfterInstallment] = useState('');
  const [temp_var, setTempVar] = useState('');
  const [cpf_inter, setCpfInter] = useState('');
  const [zip_inter, setZipInter] = useState('');
  const [is_coupon_check, setIsCouponCheck] = useState(false);
  const [code, setCode] = useState('');
  const [discount_voucher_percentage, setDiscountVoucherPercentage] = useState('');
  const [discount_value, setDiscountValue] = useState('');
  let [masked, setMasked] = React.useState(null);
  const [intrest_mode, setIntrestMode] = useState('');
  const [user_id, setUserId] = useState('');
  const [code2, setCode2] = useState('');
  const [showA, setShowA] = useState(true);
  const [showB, setShowB] = useState(false);
  const [percentage_price, setPercentagePrice] = useState('');
  const [voucher_id, setVoucherId] = useState('');
  const [cross_sell_disc, setCrossSellDisc] = useState('');
  const [price_after_coupon, setPriceAfterCoupon] = useState('');
  const [price_after_cross, setPriceAfterCross] = useState('');
  const [user_data, setUserData] = useState(null);
  const toggleShowA = () => {
    setShowB(false);
    setCode2("");
    setCode("");
    setVoucherId("");
    setDiscountPrecentage("");
    setDiscountValue("");
    if (Object.keys(parsed).length !== 0) {
      calculations2();
    } else {
      calculations();
    }
  }
  const toggleShowB = () => {
    setShowB(!showB);
  }


  const handleInputFocus = (e) => {
    setCardFocused({ focus: e.target.name });
  }
  const carddata = [];
  const handleInputChange = async (e) => {
    const { name, value } = e.target;
    var isRun = 0;
    if (e.target.name === "number") {
      e.target.value = formatCreditCardNumber(e.target.value);
      setCardNumber(e.target.value);
      carddata[e.target.name] = e.target.value;
      const issuer = Payment.fns.cardType(e.target.value);
      setCardIssuer(issuer);
      if (issuer !== null) {
        internalCreditCardRates();
      } else {
        var creditCard_rate = await getCreditCardRates();
        setBoletoRate(creditCard_rate.taxaBoleto);
      }
    } else if (e.target.name === "expiry") {
      e.target.value = formatExpirationDate(e.target.value);
      setCardValidity(e.target.value);
      carddata[e.target.name] = e.target.value;
    } else if (e.target.name === "cvc") {
      e.target.value = formatCVC(e.target.value);
      setCardSecurityCode(e.target.value);
      carddata[e.target.name] = e.target.value;
    } else if (e.target.name === "name") {
      setCardName(e.target.value);
      carddata[e.target.name] = e.target.value;
    }
  }
  useEffect(async () => {
    props.toggleLoader(true);
    var courseDetail_var = await CourseDetail(props.match.params.id);
    let quantity_customer = courseDetail_var.quantity_of_customer;
    let enroll_user = courseDetail_var.enroll_user_count;
    if (quantity_customer === "unlimited" || quantity_customer > enroll_user) {
      setCourseImage(courseDetail_var.media.url);
      setTitle(courseDetail_var.name);
      setInstructorName(courseDetail_var.user.full_name);
      setPrice(courseDetail_var.price);
      setIntrestMode(courseDetail_var.interest_mode);
      if (courseDetail_var.installment_quantity == 0) {
        setInstallmentQuantity(1);
        setInstall(1);
      } else {
        setInstallmentQuantity(courseDetail_var.installment_quantity);
      }
    } else {
      displayErrorString(I18n.t('This offer has reached limit to maximum number of enrolled students'));
      setTimeout(() => window.location.href = "/course-list", 3000);
    }
    props.toggleLoader(false);

  }, [price])
  const userCallMethod = async () => {
    var final_data = await accessUserLogin();
    getUserData(final_data);
    return final_data;
  }
  useEffect(() => {
    var data = userCallMethod();
    setUserData(data)
  }, [])
  useEffect(async () => {
    props.toggleLoader(true);
    var data = await accessUserLogin();
    setUserData(data)

    if (data === null) {
      setFirstName("");
      setLastName("");
      setEmail("");
      setAddress("");
      setZipCode("");
      setCpf("");
      setEmailReadOnly(false);
    } else {
      var getProfile_var = await getUserProfile();
      if (getProfile_var !== null) {
        setEmailReadOnly(true);
        setFirstName(getProfile_var.first_name);
        setLastName(getProfile_var.last_name);
        setEmail(getProfile_var.email);
        setAddress(getProfile_var.address);
        setCountryId(getProfile_var.country_id);
        setStateId(getProfile_var.state_id);
        setCityId(getProfile_var.city_id);
        setZipCode(getProfile_var.zip_code);
        setCpf(getProfile_var.cpf);
        setUserId(getProfile_var.id);
        if (getProfile_var.state !== null) {
          var get_states = await getStates(getProfile_var.country_id);
          setStates(get_states);
        }
        if (getProfile_var.city !== null) {
          var get_cities = await getCities(getProfile_var.state_id);
          setCities(get_cities);
        }
        let nums = getProfile_var.cpf.replace(/[^0-9]/g, "");
        setMask("999.999.999-99");
        setCpf(getProfile_var.cpf);
        setCpfInter(nums);
        let num = getProfile_var.zip_code.replace(/[^0-9]/g, "");
        setMasked("99.999-999");
        setZipCode(getProfile_var.zip_code);
        setZipInter(num);
      }
    }
    props.toggleLoader(false);
  }, [])

  useEffect(async () => {
    fetchStates(30);
    props.toggleLoader(true);
    var country_option = await getCountry();
    setCountries(country_option)
    props.toggleLoader(false);
  }, [])
  const fetchStates = async (country_id) => {
    props.toggleLoader(true);
    var states_options = await getStates(country_id);
    setStates(states_options);
    setCountryId(country_id)
    props.toggleLoader(false);
  }
  const fetchCity = async (state_id) => {
    props.toggleLoader(true);
    var city_options = await getCities(state_id);
    setCities(city_options);
    setStateId(state_id)
    props.toggleLoader(false);
  }
  const PaymentSales = () => {
    var token = localStorage.getItem('token');
    props.toggleLoader(true);
    let path = config.siteUrl;
    axios.post(path + '/student/course/checkout', {
      card_holder_name: card_holder_name,
      card_number: card_no,
      card_security_code: securityCode,
      card_validity: validThru,
      offer_id: props.match.params.id,
      amount: price,
      installments: install,
      cpf: cpf_inter,
      address: address,
      zip_code: zip_inter,
      payment_type: payment_type,
      paid_amount: grandTotal,
      cross_sell_id: cross_sell_id,
      credit_card_rate: intrest_mode === "paid by producer" ? "0" : card_rate,
      country_id: country_id,
      state_id: state_id,
      city_id: city_id,
      email: email,
      boleto_rate: boleto_rate,
      first_name: first_name,
      last_name: last_name,
      interest_mode: intrest_mode,
      discount_voucher_code: code2,
      discount_voucher_id: voucher_id,
    }, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    })
      .then(function (response) {
        props.toggleLoader(false);
        if (userdata.userData === null || userdata.userData === '') {
          displaySuccess(I18n.t('Payment made successfully and a confirmation email is sent'));
        } else {
          displaySuccess(I18n.t('Payment made successfully'));
        }
        console.log(response.data.data.pedido.boleto)
        if (response.data.data.pedido.boleto !== undefined) {
          if (response.data.data.pedido.boleto.url !== null) {
            setTimeout(() => window.location.href = response.data.data.pedido.boleto.url, 1000);
          }
        } else {
          setTimeout(() => window.location.href = "/mycourse", 3000);
        }
      })
      .catch(function (error) {
        props.toggleLoader(false);
        if (error.response.status === 401) {
          window.location.href = '/';
        }
        displayError(error);
      });
  }
  useEffect(async () => {
    props.toggleLoader(true);
    if (Object.keys(parsed).length !== 0) {
      var course_price_var = await knowCoursePrice(props.match.params.id, cross_sell_id);
      setCrossSellDisc(course_price_var.discount_percentage)
      setDiscountPrecentage(course_price_var.discount_percentage);
    }
    props.toggleLoader(false);
  }, [])
  const subtotal = price_after_discount + (price_after_discount * (intrest_mode === "paid by producer" ? "0" : card_rate) / 100);
  const subtotal2 = price_after_installment + (price_after_installment * (intrest_mode === "paid by producer" ? "0" : card_rate) / 100);
  const grandTotal = installment_quantity === 0 || installment_quantity === 1 ? parseFloat(subtotal).toFixed(2) : parseFloat(subtotal2).toFixed(2);
  const creditSelect = () => {
    setPaymentType(3);
  }
  const boletoSelect = () => {
    setPaymentType(1);
    setInstall(1);
  }
  const boletoTotal = price_after_discount;
  const boletoTotal2 = price_after_installment;
  const grandBoletoTotal = installment_quantity === 0 || installment_quantity === 1 ? parseFloat(boletoTotal).toFixed(2) : parseFloat(boletoTotal2).toFixed(2)

  const calculations = () => {
    console.log(discount_percentage);
    if (discount_percentage !== null) {
      var discounted_price = price - (price * (Object.keys(parsed).length !== 0 ? cross_sell_disc : discount_percentage) / 100);
    } else {
      var discounted_price = price - (Object.keys(parsed).length !== 0 ? cross_sell_disc : discount_value);
    }
    var installedPrice = discounted_price / install;
    setPriceAfterInstallment(installedPrice);
    if (discount_percentage === null) {
      if (showB === true && installment_quantity === 1) {
        SetPriceAfterDiscount(price);
      }
      else if (showB === true && installment_quantity > 1) {
        console.log("discount value");
        if (Object.keys(parsed).length !== 0) {
          var cross_price = price_after_cross - discount_value;
          SetPriceAfterDiscount(cross_price);
          var installedPrice = cross_price / install;
          setPriceAfterInstallment(installedPrice);
        } else {
          var cross_price = price - discount_value;
          SetPriceAfterDiscount(cross_price);
        }
      } else {
        SetPriceAfterDiscount(discounted_price);
      }


    } else if (discount_percentage !== null) {
      if (showB === true && installment_quantity === 1) {
        SetPriceAfterDiscount(price);
      }
      else if (showB === true && installment_quantity > 1) {
        console.log("discount percentage");
        if (Object.keys(parsed).length !== 0) {
          var cross_price = price_after_cross - percentage_price;
          SetPriceAfterDiscount(cross_price);
          var installedPrice = cross_price / install;
          setPriceAfterInstallment(installedPrice);
        } else {
          var cross_price = price - percentage_price;
          SetPriceAfterDiscount(cross_price);
        }
      } else {
        SetPriceAfterDiscount(discounted_price);
      }
    } else {
      SetPriceAfterDiscount(discounted_price);

    }

  }
  const calculations2 = () => {
    var discounted_price = price - (price * (Object.keys(parsed).length !== 0 ? cross_sell_disc : discount_percentage) / 100);
    SetPriceAfterDiscount(discounted_price);
    var installedPrice = price / install;
    setPriceAfterInstallment(installedPrice);
  }
  const internalCreditCardRates = async () => {
    var installment = install;
    var installment_quantity = install;
    if (issuer === 'visa') {
      var creditCard_rate = await getCreditCardRates();
      if (installment_quantity === 0 || installment_quantity === 1) {
        setCardRate(creditCard_rate.taxaTotal.Visa.credit["1"]);
      } else {
        setCardRate(creditCard_rate.taxaTotal.Visa.credit[installment]);
      }
    } else if (issuer === 'mastercard') {
      var creditCard_rate = await getCreditCardRates();
      if (installment_quantity === 0 || installment_quantity === 1) {
        setCardRate(creditCard_rate.taxaTotal.MasterCard.credit["1"]);
      } else {
        setCardRate(creditCard_rate.taxaTotal.MasterCard.credit[installment]);
      }
    } else if (issuer === 'discover') {
      var creditCard_rate = await getCreditCardRates();
      if (installment_quantity === 0 || installment_quantity === 1) {
        setCardRate(creditCard_rate.taxaTotal.Discover.credit["1"]);
      } else {
        setCardRate(creditCard_rate.taxaTotal.Discover.credit[installment]);
      }
    }
    else if (issuer === 'amex') {
      var creditCard_rate = await getCreditCardRates();
      if (installment_quantity === 0 || installment_quantity === 1) {
        setCardRate(creditCard_rate.taxaTotal["American Express"].credit["1"]);
      } else {
        setCardRate(creditCard_rate.taxaTotal["American Express"].credit[installment]);
      }
    }
  }

  useEffect(() => {
    props.toggleLoader(true);
    if (issuer != "" && issuer != null) {
      internalCreditCardRates();
    }
    calculations();
    props.toggleLoader(false);
  }, [install, price, discount_percentage])

  useEffect(() => {
    var temp_arr = [];
    for (var i = 1; i <= installment_quantity; i++) {
      temp_arr.push(i);
    }
    setInstallmentNo(temp_arr);
    setInstall(installment_quantity);
  }, [installment_quantity])

  const handleChange = ev => {
    let { value } = ev.target;
    let nums = value.replace(/[^0-9]/g, "");
    setMask("999.999.999-99");
    setCpf(ev.target.value);
    setCpfInter(nums);
    if (nums.length < 3 && mask != null) {
      setMask(null);
      ev.target.value = nums;
    }
  };
  const zipHandle = ev => {
    let { value } = ev.target;
    let nums = value.replace(/[^0-9]/g, "");
    setMasked("99.999-999");
    setZipCode(ev.target.value);
    setZipInter(nums);
    if (nums.length < 3 && mask != null) {
      setMask(null);
      ev.target.value = nums;
    }
  };

  const applyCoupon = () => {
    var token = localStorage.getItem('token');
    props.toggleLoader(true);
    let path = config.siteUrl;
    axios.post(path + '/discount-voucher/apply', {
      offer_id: props.match.params.id,
      code: code,
      user_id: user_id,
    }, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    })
      .then(function (response) {
        props.toggleLoader(false);
        displaySuccess(I18n.t('Discount Voucher Applied Successfully'));
        setShowB(true);
        setCode2(code);
        setCode("");
        setVoucherId(response.data.data.id);
        setDiscountPrecentage(response.data.data.discount_percentage);
        setDiscountValue(response.data.data.discount_value);
        if (response.data.data.discount_percentage !== null) {
          setPriceAfterCross(price_after_discount);
          if (Object.keys(parsed).length > 0) {
            var percent_price = (price_after_discount * response.data.data.discount_percentage / 100);
            setPercentagePrice(percent_price);
            var discount_price = price_after_discount - (price_after_discount * response.data.data.discount_percentage / 100);
            var discount_voucher_price = discount_price - (discount_price * discount_voucher_percentage / 100);
            console.log(discount_voucher_price);
            SetPriceAfterDiscount(discount_voucher_price);
            var installedPrice = discount_voucher_price;
            var installedVoucherPrice = installedPrice / install;
            setPriceAfterInstallment(installedVoucherPrice);
          } else {
            var percent_price = (price * response.data.data.discount_percentage / 100);
            setPercentagePrice(percent_price);
            var discount_price = price - (price * response.data.data.discount_percentage / 100);
            var discount_voucher_price = discount_price - (discount_price * discount_voucher_percentage / 100);
            SetPriceAfterDiscount(discount_voucher_price);
            var installedPrice = discount_voucher_price;
            var installedVoucherPrice = installedPrice / install;
            setPriceAfterInstallment(installedVoucherPrice);
          }
        } else if (response.data.data.discount_value) {
          console.log(price_after_discount)
          setPriceAfterCross(price_after_discount);
          if (Object.keys(parsed).length > 0) {
            var percent_price = (price_after_discount - response.data.data.discount_value);
            setDiscountValue(response.data.data.discount_value);
            console.log(percent_price)
            SetPriceAfterDiscount(percent_price);
            var installedPrice = percent_price;
            var installedVoucherPrice = installedPrice / install;
            setPriceAfterInstallment(installedVoucherPrice);
          } else {
            var discount_price = price - response.data.data.discount_value;
            if (discount_price < 0) {
              discount_price = 0;
            }
            SetPriceAfterDiscount(discount_price);
            var installedPrice = discount_price;
            var installedVoucherPrice = installedPrice / install;
            if (discount_price < 0) {
              installedVoucherPrice = 0;
            }
            setPriceAfterInstallment(installedVoucherPrice);
          }

        } else {
          var discount_voucher_price = price - (price * discount_voucher_percentage / 100);
          SetPriceAfterDiscount(discount_voucher_price);
          var installedVoucherPrice = discount_voucher_price / install;
          setPriceAfterInstallment(installedVoucherPrice);
        }
      })
      .catch(function (error) {
        props.toggleLoader(false);
        if (error.response.status === 401) {
          window.location.href = '/';
        }
        displayError(error);
        setShowB(false);
        SetPriceAfterDiscount(price);
        setCode("");
      });

  }
  return (

    <>
      <body>
        <Topbar />
        <section className="inner-banner d-none d-sm-block">
          <div className="container">
            <ul className="list-unstyled thm-breadcrumb">
              <li><Link to="/">{I18n.t('Home')}</Link></li>
              <li className="active"><Link to="/my-course">{I18n.t('Checkout')}</Link></li>
            </ul>
            <h2 className="inner-banner__title">{I18n.t('Checkout')}</h2>
          </div>
        </section>
        <section className="inner-banner-responsive d-block d-sm-none">
          <div className="container">
            <Breadcrumb>
              <Breadcrumb.Item href="/">{I18n.t('Home')}</Breadcrumb.Item>
              <Breadcrumb.Item href="" active>
                {I18n.t('Checkout')}
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </section>
        <main>
          <div className="overflow-hidden">
            <div className="container">
              <div className="row">
                <div className="col-md-7 pl-md-0">
                  <div className="course__title">
                    <h4>{title} <br />
                      {/* <small className="disabale">{I18n.t('By')}:- {instructor_name}</small> */}
                    </h4>
                  </div>
                  {grandTotal <= 0 ? "" :
                    <div className="card_details">
                      <div className='container'>
                        <form>
                          <label class="radio-inline" style={{ marginRight: '20px', cursor: 'pointer' }}>
                            <input type="radio" name="optradio" onClick={creditSelect} style={{ cursor: 'pointer' }}
                              checked={payment_type === 3 ? true : false} /> {I18n.t('Credit Card')}
                          </label>
                          <label class="radio-inline" style={{ cursor: 'pointer' }}>
                            <input type="radio" name="optradio" onClick={boletoSelect} style={{ cursor: 'pointer' }} checked={payment_type === 1 ? true : false} /> {('Boleto')}
                          </label>
                        </form>
                      </div>
                      {payment_type === 1 ? "" :
                        // <div id="PaymentForm" >
                        <div className="row">
                          <div className="col-md-5">
                            <Cards
                              cvc={cvc}
                              expiry={expiry}
                              focused={focus}
                              name={name}
                              number={number}
                            />
                          </div>
                          <div className="col-md-7 pl-md-0 m-ml-2">
                            <form>
                              <div className="row no-gutters">
                                <div className="col-md-6 mt-2 pr-md-2">
                                  <label className="label-control">{I18n.t('Card Number')}</label>
                                  <input
                                    type="tel"
                                    name="number"
                                    className="form-control"
                                    placeholder={I18n.t("Card Number")}
                                    pattern="[\d| ]{16,22}"
                                    required
                                    onChange={e => {
                                      setCardNo(e.target.value)
                                      handleInputChange(e)
                                    }}
                                    onBlur={e => {
                                      setCardNo(e.target.value)
                                      handleInputChange(e)
                                    }}
                                    onFocus={handleInputFocus}
                                    id="cardnumber"
                                    value={card_no}
                                  />
                                </div>
                                <div className="col-md-6 mt-2 pr-md-2">
                                  <label className="label-control">{I18n.t('Card Holder Name')}</label>
                                  <input
                                    type="text"
                                    name="name"
                                    className="form-control"
                                    placeholder={I18n.t("Name")}
                                    required
                                    onChange={e => {
                                      setCardHolderName(e.target.value)
                                      handleInputChange(e)
                                    }}
                                    onFocus={handleInputFocus}
                                  />
                                </div>
                                <div className="col-md-6 mt-2 pr-md-2">
                                  <label className="label-control">{I18n.t('CVC')}</label>
                                  <input
                                    type="tel"
                                    name="cvc"
                                    className="form-control"
                                    placeholder={I18n.t("CVC")}
                                    pattern="\d{3,4}"
                                    required
                                    onChange={e => {
                                      setSecurityCode(e.target.value)
                                      handleInputChange(e)
                                    }}
                                    onFocus={handleInputFocus}
                                  />
                                </div>
                                <div className="col-md-6 mt-2 pr-md-2">
                                  <label className="label-control">{I18n.t('Card Validity')}</label>
                                  <input
                                    type="tel"
                                    name="expiry"
                                    className="form-control"
                                    placeholder={I18n.t("Valid Thru")}
                                    pattern="\d\d/\d\d"
                                    required
                                    onChange={e => {
                                      setValidThru(e.target.value)
                                      handleInputChange(e)
                                    }}
                                    onFocus={handleInputFocus}
                                  />
                                  <input type="hidden" name="issuer" />
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                        // </div>
                      }
                    </div>
                  }
                  <div className="row mt-4">
                    <div className="col-sm-4 mb-4">
                      <label>{I18n.t('CPF')}</label>
                      <ReactInputMask
                        className="form-control"
                        mask={mask}
                        onChange={handleChange}
                        maskChar="_"
                        value={cpf}
                        placeholder={I18n.t("CPF")}
                        {...props}
                      />
                    </div>

                    <div className="col-sm-4 mb-4">
                      <label>{I18n.t('Email Address')}</label>
                      <input type="text" className="form-control" placeholder={I18n.t("Email Address")}
                        value={email} readOnly={emailReadOnly} onChange={e => setEmail(e.target.value)} />
                    </div>
                    <div className="col-sm-4 mb-4">
                      <label>{I18n.t('Installment Quantity')}</label>
                      {payment_type === 1 ?
                        <select
                          className="form-control"
                          id="validationCustom04"
                          required=""
                          onChange={e => {
                            setInstall(e.target.value)
                            // installCredit(e)
                          }}
                        >
                          {/* {installment_no.map((instal) =>
                         <option value={instal} selected={(instal === installment_quantity) ? true : false}>{instal}</option>
                       )} */}
                          <option>{install}</option>
                        </select> :
                        <select
                          className="form-control"
                          id="validationCustom04"
                          required=""
                          onChange={e => {
                            setInstall(e.target.value)
                            // installCredit(e)
                          }}
                          disabled={grandTotal <= 0 ? true : false}
                        >
                          {installment_no.map((instal) =>
                            <option value={instal} selected={(instal === installment_quantity) ? true : false}>{instal}</option>
                          )}
                        </select>
                      }
                    </div >
                    <div className='col-sm-12'>
                      <hr />
                    </div>
                    <>
                      <div className='col-sm-6'>
                        <label>{I18n.t('First Name')}</label>
                        <input type="text" className="form-control" placeholder={I18n.t("First Name")}
                          value={first_name}
                          onChange={e => setFirstName(e.target.value)} />
                      </div>
                      <div className='col-sm-6'>
                        <label>{I18n.t('Last Name')}</label>
                        <input type="text" className="form-control" placeholder={I18n.t("Last Name")}
                          value={last_name}
                          onChange={e => setLastName(e.target.value)} />
                      </div>
                    </>

                    <div className="col-md-12 mb-4">
                      <label>{I18n.t('Address')}</label>
                      <input type="text" className="form-control" placeholder={I18n.t("Address")}
                        value={address}
                        onChange={e => setAddress(e.target.value)} />
                    </div>
                    <div className="col-md-4 col-xl-3 mb-4">
                      <label>{I18n.t('Country')}</label>
                      <select
                        className="form-control"
                        id="validationCustom04"
                        required=""
                        onChange={e => {
                          fetchStates(e.target.value)
                          setCountryId(e.target.value)
                        }} >
                        <option >{I18n.t('Select Country')}</option>
                        {countries.map((country) =>
                          <option value={country.id} selected={(country.id === country_id) ? true : false}>{country.name}</option>
                        )}
                      </select>
                    </div>
                    <div className="col-md-4 col-xl-3 mb-4">
                      <label>{I18n.t('State')}</label>
                      <select className="form-control" id="validationCustom04"
                        onChange={e => {
                          fetchCity(e.target.value)
                          setStateId(e.target.value)
                        }}>
                        <option  >{I18n.t('Select State')}</option>
                        {states.map((state) =>
                          <option value={state.id} selected={(state.id === state_id) ? true : false}>{state.name}</option>
                        )}
                      </select>
                    </div>
                    <div className="col-md-4 col-xl-3 mb-4">
                      <label>{I18n.t('City')}</label>
                      <select className="form-control" id="validationCustom04"
                        onChange={e => {
                          setCityId(e.target.value)
                        }}>
                        <option  >{I18n.t('Select City')}</option>
                        {cities.map((city) =>
                          <option value={city.id} selected={(city.id === city_id) ? true : false}>{city.name}</option>
                        )}
                      </select>
                    </div>
                    <div className="col-md-4 col-xl-3 mb-4">
                      <label>{I18n.t('ZipCode')}</label>
                      {/* <input type="text" className="form-control" placeholder={I18n.t("ZipCode")}
                        onChange={e => setZipCode(e.target.value)}
                        value={zip_code} /> */}
                      <ReactInputMask
                        className="form-control"
                        mask={masked}
                        onChange={zipHandle}
                        // onChange={e => {
                        //   setCpf(e.target.value)
                        // handleChange}}
                        maskChar="_"
                        value={zip_code}
                        placeholder={I18n.t("ZipCode")}
                        {...props}
                      />
                    </div>
                  </div >
                  {/* <div className="form-group">
                    <div class="form-check-inline">
                      <label class="form-check-label">
                        <input type="checkbox" class="form-check-input" value="" /> {I18n.t('Save this information for next time')}
                      </label>
                    </div>
                  </div> */}

                  {/* < div className="form-group mt-3" >

                    <button type="button" class="theme-btn black_line"
                      onClick={PaymentSales}>{I18n.t('Submit Now')}</button>
                  </div > */}
                  <br /><br />
                </div >
                <div className="col-lg-5 before">
                  <div className="order-summary__section">
                    <div className="order-summary__section__content">
                      <table className="product-table">
                        <tbody>
                          <tr className="product">
                            <td className="product__image">
                              <div className="product-thumbnail ">
                                <div className="product-thumbnail__wrapper">
                                  {/* <img className="product-thumbnail__image" src={course_image} /> */}
                                  <img className="product-thumbnail__image" src={course_image} />
                                </div>
                              </div>
                            </td>
                            <th className="product__description">
                              <span className="product__description__name">{title}</span>
                            </th>
                            <td className="product__price">
                              <span className="order-summary__emphasis">R$ {parseFloat(price).toLocaleString(localePriceFormat, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="3">
                              <hr />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="order-summary">
                      <table className="total-line-table">
                        <tbody>
                          {Object.keys(parsed).length !== 0 ?
                            <>
                              <tr>
                                <th className="total-line__name">
                                  <span>
                                    {I18n.t('Price')}
                                  </span>
                                </th>
                                <td className="total-line__price">
                                  <span className="order-summary__small-text">
                                    R$ {parseFloat(price).toLocaleString(localePriceFormat, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <th className="total-line__name">
                                  <span>
                                    {I18n.t('Discount (-)')}
                                  </span>
                                </th>
                                <td className="total-line__price">
                                  <span className="order-summary__small-text">
                                    {Object.keys(parsed).length !== 0 ? cross_sell_disc : "NA"}
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan="3">
                                  <hr />
                                </td>
                              </tr>
                            </>
                            : ""}
                          <tr>
                            <th className="total-line__name-disc">
                              <span>
                                {I18n.t('Apply Discount Voucher')}
                              </span>
                            </th>
                          </tr>
                          {/* <tr >
                            <td className="coupon-check">
                              <label class="radio-inline" style={{ marginRight: '180px', cursor: 'pointer' }}>
                                <input type="checkbox" name="optradio" style={{ cursor: 'pointer' }}
                                // onClick={enterCode}
                                /> {'coupon Code'}
                              </label>
                            </td>
                          </tr> */}
                          {/* {is_coupon_check === false ? "" : */}
                          <tr>
                            <th className="total-line__name">
                              <span>
                                <input
                                  type="text" className="form-control" placeholder={I18n.t("Coupon Code")}
                                  required style={{ width: '100%' }}
                                  onChange={e => setCode(e.target.value)}
                                  onKeyPress={(event) => {
                                    if (!/[A-Z0-9]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                    if (event.target.value.length > 10) {
                                      event.preventDefault();
                                    }
                                  }}
                                  value={code}
                                />
                              </span>
                            </th>
                            <td className="total-line__price">
                              <span className="order-summary__small-text">
                                <button type="button" class="check-btn black_line_check"
                                  onClick={applyCoupon} >{I18n.t('Apply code')}</button>
                              </span>
                            </td>
                          </tr>
                          {/* <tr className=" mb-4 total-line__price">
                            <span className="order-summary__small-text">
                              <button type="button" class="check-btn black_line_check"
                                onClick={toggleShowA} > View Details</button>
                            </span>
                          </tr> */}
                          {showB === true ?
                            <tr>
                              <Toast show={showA} onClose={(e) => {
                                toggleShowA();
                                setShowB(false);
                              }}>
                                <Toast.Header>
                                  <strong className="me-auto">{code2}</strong>
                                </Toast.Header>
                              </Toast>
                            </tr>
                            : ""}
                          <tr>
                            <td colSpan="3">
                              <hr />
                            </td>
                          </tr>
                          {showB === true ?
                            <>
                              <tr>
                                <th className="total-line__name">
                                  <span>
                                    {I18n.t('Price')}
                                  </span>
                                </th>
                                <td className="total-line__price">
                                  <span className="order-summary__small-text">
                                    {Object.keys(parsed).length > 0 ? 'R$ ' + parseFloat(price_after_cross).toLocaleString(localePriceFormat, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                      : 'R$ ' + parseFloat(price).toLocaleString(localePriceFormat, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}


                                  </span>
                                </td>
                              </tr>
                              {discount_percentage !== null ?
                                <tr>
                                  <th className="total-line__name">
                                    <span>
                                      {I18n.t('Voucher Discount')} (-{parseFloat(discount_percentage).toLocaleString(localePriceFormat, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}%)
                                    </span>
                                  </th>
                                  <td className="total-line__price">
                                    <span className="order-summary__small-text">
                                      R$ {parseFloat(percentage_price).toLocaleString(localePriceFormat, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                    </span>
                                  </td>
                                </tr>
                                :
                                <tr>
                                  <th className="total-line__name">
                                    <span>
                                      {I18n.t('Voucher Discount')}(-)
                                    </span>
                                  </th>
                                  <td className="total-line__price">
                                    <span className="order-summary__small-text">
                                      R$ {discount_value}
                                    </span>
                                  </td>
                                </tr>
                              }

                              <tr>
                                <td colSpan="3">
                                  <hr />
                                </td>
                              </tr>
                            </> : ""}
                          {installment_quantity === 0 || installment_quantity === 1 ?
                            "" :
                            <>
                              <tr>
                                <th className="total-line__name">
                                  <span>
                                    {I18n.t('Discounted Price')}
                                  </span>
                                </th>
                                <td className="total-line__price">
                                  <span className="order-summary__small-text">
                                    R$ {parseFloat(price_after_discount).toLocaleString(localePriceFormat, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <th className="total-line__name">
                                  <span>
                                    {I18n.t('Installment Quantity')}
                                  </span>
                                </th>
                                <td className="total-line__price">
                                  <span className="order-summary__small-text">
                                    {install}
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan="3">
                                  <hr />
                                </td>
                              </tr>
                            </>
                          }
                          {installment_quantity === 0 || installment_quantity === 1 ? <tr>
                            <th className="total-line__name">
                              <span>{I18n.t('Discounted Price')}
                              </span>
                            </th>
                            <td className="total-line__price">
                              <span className="order-summary__small-text">
                                R$ {parseFloat(price_after_discount).toLocaleString(localePriceFormat, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                              </span>
                            </td>
                          </tr>
                            :
                            <tr>
                              <th className="total-line__name">
                                <span>{I18n.t('Installment Price')}
                                </span>
                              </th>
                              <td className="total-line__price">
                                <span className="order-summary__small-text">
                                  R$ {parseFloat(price_after_installment).toLocaleString(localePriceFormat, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                </span>
                              </td>
                            </tr>
                          }
                          {payment_type === 1 ?
                            <tr>
                              <th className="total-line__name">
                                <span>
                                  {I18n.t('Boleto rates (+)')}
                                </span>
                              </th>
                              <td className="total-line__price">
                                <span className="order-summary__small-text">
                                  0%
                                </span>
                              </td>
                            </tr>
                            :
                            <tr>
                              <th className="total-line__name">
                                <span>
                                  {I18n.t('Credit card rates (+)')}
                                </span>
                              </th>
                              <td className="total-line__price">
                                <span className="order-summary__small-text">
                                  {intrest_mode === "paid by producer" ? 0 : card_rate} %
                                </span>
                              </td>
                            </tr>
                          }
                          <tr>
                            <th className="total-line__name">{I18n.t('Subtotal')}</th>
                            <td className="total-line__price">
                              <span className="payment__currency">R$</span>
                              <span className="order-summary">
                                {payment_type === 1 ? parseFloat(grandBoletoTotal).toLocaleString(localePriceFormat, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : parseFloat(grandTotal).toLocaleString(localePriceFormat, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                              </span>
                            </td>
                          </tr>
                        </tbody>

                        <tfoot className='bg-gray-totoal'>
                          <tr className="total-line">
                            <th className="payment-due-label">
                              <span className="payment-due-label__total">{I18n.t('Grand Total')}</span>
                            </th>

                            <td className="total-line__price">
                              <span className="payment__currency" style={{ fontSize: '16px' }}>{install} x </span>
                              {/* <span class="course-one__stars-count">{install} x</span> */}
                              <span className="payment__currency">R$</span>
                              <span className="payment-due__price">
                                {payment_type === 1 ? parseFloat(grandBoletoTotal).toLocaleString(localePriceFormat, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : parseFloat(grandTotal).toLocaleString(localePriceFormat, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                              </span>
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                      < div className="form-group mt-5 mb-4" style={{ textAlign: 'right' }}>
                        <button type="button" class="theme-btn black_line"
                          onClick={PaymentSales} >{I18n.t('Subscribe')}</button>
                      </div >
                    </div>
                  </div>
                </div>
              </div >
            </div >
          </div >
        </main >
        <Footer />
      </body >
    </>
  )
}
const mapDispatchToProps = {
  toggleLoader,
  getUserData
}
const mapStateToProps = (state) => {
  return {
    userdata: state.userdata
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Checkout);