import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import axios from "axios";
import { toggleLoader } from '../../actions/loader';
import { courseList, displayError, displayErrorString } from '../../Utils/utils';
import { Link } from 'react-router-dom';
import { Translate, I18n } from "react-redux-i18n";
import { useHistory } from 'react-router-dom';

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import Alert from 'react-bootstrap/Alert'
import defaultImg from '../images/index.jpeg';
const CourseRightBar = (props) => {
  const localePriceFormat = (localStorage.getItem('langauge_selected') == 'en' || localStorage.getItem('langauge_selected') == '') ? 'en' : 'pt-BR'
  const [course, setCourse] = useState([]);
  const [show, setShow] = useState(false);
  const [temp_id, setTempId] = useState('');
  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    setShow(true)
    setTempId(id)
    console.log(temp_id)
  }
  useEffect(async () => {
    var courseList_var = await courseList();
    if (courseList_var !== null) {
      setCourse(courseList_var);
    }
  }, [])
  const printStars = (avg_star) => {
    var print_html = [];
    for (var i = 1; i <= 5; i++) {
      if (avg_star >= i) {
        print_html.push(<i className="icofont-star text-warning"></i>);
      } else {
        print_html.push(<i className="icofont-star rate-select " style={{ color: '#eee' }}></i>);
      }
    }
    return print_html;
  }
  const history = useHistory();
  const checkoutLink = () => {
    if (props.quantity_of_customer === "unlimited" || props.quantity_of_customer > props.enroll_user) {
      setTimeout(() => history.push("/checkout/" + props.id + "/" + props.slug))
    } else {
      displayErrorString(I18n.t('This offer has reached limit to maximum number of enrolled students'));
      setTimeout(() => window.location.href = "/course-list", 3000);
    }
  }
  const FreeCheckoutLink = () => {
    if (props.quantity_of_customer === "unlimited" || props.quantity_of_customer > props.enroll_user) {
      setTimeout(() => history.push("/free/checkout/" + props.id + "/" + props.slug))
    } else {
      displayErrorString(I18n.t('This offer has reached limit to maximum number of enrolled students'));
      setTimeout(() => window.location.href = "/course-list", 3000);
    }
  }
  return (
    <>
      <div className="col-lg-4">
        <div className="course-one__image">
          <img src={props.courseImage} />
          <i className="far fa-heart" />
        </div>
        <div className="full-course-price">
          <p className="full-course-price-text">{I18n.t("Course price")} </p>
          <p className="full-course-price-amount"> R$ {parseFloat(props.price).toLocaleString(localePriceFormat)}</p>
          {props.free_content === 1 ?
            <div className="theme-btn full-course-price-btn" onClick={FreeCheckoutLink} style={{ cursor: 'pointer' }}>
              {I18n.t("Subscribe Course")}
            </div>
            : props.free_content === 2 ?
              <div className="theme-btn full-course-price-btn" onClick={FreeCheckoutLink} style={{ cursor: 'pointer' }}>
                {I18n.t("Subscribe Course")}
              </div>
              :
              <div className="theme-btn full-course-price-btn" onClick={checkoutLink} style={{ cursor: 'pointer' }}>
                {I18n.t("Subscribe Course")}
              </div>
          }
        </div>
        <div className="course-details__meta">
          <a href="#" className="more-course-details-link">
            <span className="course-details__meta-icon">
              <i className="far fa-clock" />
            </span>
            {I18n.t("Duration")}: <span>{props.duration}</span>
          </a>
          <a href="#" className="more-course-details-link">
            <span className="course-details__meta-icon">
              <i className="far fa-folder-open" />
            </span>
            {I18n.t("Lectures")}: <span>{props.lectureCount}</span>
          </a>
          <a href="#" className="more-course-details-link">
            <span className="course-details__meta-icon">
              <i className="far fa-user-circle" />
            </span>
            {I18n.t("Students")}: <span>{props.enroll_user}</span>
          </a>
          {/* <a href="#" className="more-course-details-link">
            <span className="course-details__meta-icon">
              <i className="fas fa-play" />
            </span>
            Video: <span>8 hours</span>
          </a> */}
          <a href="#" className="more-course-details-link">
            <span className="course-details__meta-icon">
              <i className="far fa-flag" />
            </span>
            {I18n.t("Skill Level")}: <span>{I18n.t(props.level)}</span>
          </a>
          <a href="#" className="more-course-details-link">
            <span className="course-details__meta-icon">
              <i className="far fa-bell" />
            </span>
            {I18n.t("Language")}: <span>{props.language}</span>
          </a>
        </div>
        <div className="course-details__list">
          <h2 className="course-details__list-title">{I18n.t("Popular Courses")}</h2>
          {course.map((list) => (
            <div className="course-details__list-item">
              <Link to={"/course-detail/" + list.id + "/" + list.slug}>
                <div className="course-details__list-img">
                  <img src={list.media.url} />
                </div>
              </Link>
              <div className="course-details__list-content">
                {/* <a className="course-details__list-author" href="#">
                  by <span>{list.user.full_name}</span>
                </a> */}
                <h4 className='instructor-name ' onClick={e => handleShow(list.id)} style={{ cursor: 'pointer' }}>
                  <i class="fa fa-users"></i> {I18n.t('View Instructors')}
                </h4>
                <h3>
                  <a href="#">{list.name}</a>
                </h3>
                <div className="course-details__list-stars">
                  {printStars(list.avg_rating)}
                  <span>{list.avg_rating}</span>
                </div>
              </div >
            </div >
          ))}
        </div >
        <>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <div className="modal-title-c h4">{I18n.t("View all instructors")}</div>
            </Modal.Header>
            <Modal.Body>

              {
                course.map((list) => (
                  temp_id === list.id ?
                    <>
                      {(list.offer_instructor.length === 0) ?
                        <Tr>
                          <Td colspan="12" style={{ textAlign: 'center' }}>
                            <Alert key={'alert'} variant={'info'} style={{ width: '350px', marginLeft: '50px' }}>
                              {I18n.t('No Instructor')}
                            </Alert>
                          </Td>
                        </Tr> : ''}
                      {list.offer_instructor.map((inst) => (

                        <div className="row" style={{ paddingBottom: '20px' }}>
                          <div className="col-lg-3">
                            <div className='user-image-c'>
                              <Link to={"/instructor-detail/" + inst.user.id}>
                                {inst.user.media !== null ?
                                  <img src={inst.user.media.url} className="img-fluid" style={{ borderRadius: '4px' }} />
                                  : <img src={defaultImg} className="img-fluid" style={{ borderRadius: '4px' }} />}
                              </Link>
                            </div>
                          </div>
                          <div className="col-lg-9">
                            <div className="user-name-c">
                              <Link to={"/instructor-detail/" + inst.user.id}>
                                <h6>{inst.user.first_name} {inst.user.last_name}</h6>
                              </Link>
                              {/* <h6>instructor name</h6> */}
                            </div>
                            <br />
                          </div>
                        </div>

                      ))}
                      {/* // </div> */}
                    </>
                    : ""
                ))}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                {I18n.t('Close')}
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      </div >
    </>
  )
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CourseRightBar);
