import React, { useEffect, useState } from 'react';
import Topbar from '../components/Topbar';
import Footer from '../components/Footer';
import { toggleLoader } from '../actions/loader';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import { Translate, I18n } from "react-redux-i18n";

const TermsAndConditions = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <body id="page-top">
        <div className="page-wrapper">

          <Topbar />
          <section className="inner-banner d-none d-sm-block">
            <div className="container">
              <ul className="list-unstyled thm-breadcrumb">
                <li><Link to="/">{I18n.t("Home")}</Link></li>
                <li className="active"><Link>{I18n.t("Terms and Conditions")}</Link></li>
              </ul>
              <h2 className="inner-banner__title">{I18n.t("Terms and Conditions")}</h2>
            </div>
          </section>
          <section className="inner-banner-responsive d-block d-sm-none">
            <div className="container">
              <Breadcrumb>
                <Breadcrumb.Item href="/">{I18n.t("Home")}</Breadcrumb.Item>
                <Breadcrumb.Item href="" active>
                {I18n.t("Terms and Conditions")}
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </section>
          <br />
          <div className="container">
            <div className="row justify-content-center" style={{ marginTop: '30px' }}>
              <div className="col-md-12 col-sm-12 col-lg-12">
                <h3>{I18n.t('TERMS AND CONDITIONS OF USE')}</h3>
                <p>{I18n.t('Welcome to the DUTRA FOR EDUCATION platform')}</p>
                <p>{I18n.t('Thank you for using our services')}  </p>
                <p>{I18n.t('These general terms and conditions of use (Terms of Use) are applicable to the relationship between the parent company and operator DUTRA ANALYTICS SERVICOS DE INFORMACAO LTDA, owner of DUTRA FOR EDUCATION, located at RUA TUPI 758 CONJ 40 - BAIRRO RIO BRANCO CEP 93336010 - NOVO HAMBURG/RS, Brazil, and its customers')}</p>
                <p>{I18n.t('Please read carefully the terms and conditions of use stipulated below (“Terms of Use”) so that you can enjoy our platform and all the services offered by DUTRA FOR EDUCATION')}</p>
                <p><strong>{I18n.t('IMPORTANT THESE TERMS OF SERVICE GOVERN THE USE OF THIS PLATFORM PROVIDED BY DUTRA FOR EDUCATION BY ACCESSING THIS PLATFORM YOU ACKNOWLEDGE YOUR AGREEMENT AND AGREEMENT TO THESE TERMS OF USE THESE TERMS OF USE MAY BE CHANGED AT ANY TIME AND WITHOUT NOTICE YOUR USE OF THIS PLATFORM AFTER SUCH CHANGES HAVE BEEN EFFECTIVE CONSTITUTES YOUR ACKNOWLEDGMENT AND ACCEPTANCE OF THE CHANGES PLEASE REVIEW THE TERMS OF USE BEFORE EACH USE FOR MODIFICATIONS')}  </strong></p>
              </div>

            </div>
            <div className="row ">
              <div className="col-md-12 col-sm-12 col-lg-12" style={{ color: '#52565b', fontSize: '15px', fontWeight: '400' }}>
                {/* <ul> */}

                <li style={{ marginBottom: '20px' }}>
                  <h4>{I18n.t("How to use our Services")}</h4>
                  {/* <ul> */}
                  {I18n.t('You are required to follow the policies made available to you within the Services Do not misuse our services For example, please do not interfere with our services or try to access them by a method other than the interface and instructions we provide You may use our services only as permitted by law We may suspend or stop providing our services if you fail to comply with our terms or policies or if we are investigating suspected misconduct')}
                  {/* </ul> */}
                </li>
                <li style={{ marginBottom: '20px' }}>
                  <h4>{I18n.t('Information Property')}</h4>
                  <p>{I18n.t('The material and content (referred to in this document as “content”) accessible on this platform and any other website owned by DUTRA FOR EDUCATION, licensed or controlled by our company belongs to the company or the source that provided the content to our company, and our company or the source in question retains all right, title and interest in the content Likewise, content may not be copied, distributed, republished, uploaded, posted or transmitted in any way without our companys prior written consent, or unless authorized in writing on our platform You may not remove, alter or cause the removal or alteration of any copyright, trademark, trade name, service mark or any other proprietary notices in the Content Modification or use of content in any way other than as expressly described in these Terms of Use violates our Companys intellectual property rights Neither authorship nor intellectual property is transferred to you by accessing this platform The user has the permitted access to share content on social networks or messaging applications, provided that they are made available for this on our platform, provided that the completeness of the content is maintained')}</p>
                  <p>{I18n.t('This platform may contain links to other websites that are not maintained by or even related to our company Hyperlinks to such sites are provided as a service to users and are not affiliated with this platform or our company Our company does not review all or any of these sites and is not responsible for their content The user assumes the risk in accessing these hyperlinks and our company makes no representations or guarantees about the completeness or accuracy of the hyperlinks and the websites to which they direct Finally, the completion of any hyperlink to a third party website does not necessarily imply our companys endorsement of that website')} </p>
                  <p>{I18n.t('You may use this platform for purposes expressly permitted by this platform You may not use it for any other purpose, including commercial purposes, without our companys express prior written consent For example, you may not (and may not authorize any third party to) (I) associate our companys brand with any other, or (II) frame this platform on another, or (III) hyperlink to this platform without express prior written authorization from a representative of our company For the purposes of this term of use of these Terms of Use, associate our companys brand means to expose a name, logo, trademark or other means of attribution or identification in a font in a way that gives the user the impression that such font has the right to exhibit, publish or distribute this platform or the content made available by it You agree to cooperate with the company to terminate any unauthorized association, frame or hyperlink')} </p>
                  <p>{I18n.t('You understand that our company cannot and does not guarantee that files available for downloading from the Internet will be free of viruses, worms, Trojan horses or other code that may manifest contaminating or destructive properties You are responsible for implementing sufficient procedures and checkpoints to satisfy your security requirements and for maintaining means external to this platform to reconstruct any lost data Our company assumes no responsibility or risk for the use of the internet')}</p>
                  <p>{I18n.t('In connection with your use of the Services, we may send you service announcements, administrative messages and other information')} </p>
                </li>
                <li style={{ marginBottom: '20px' }}>
                  <h4> {I18n.t('Your account')} </h4>
                  <p>{I18n.t('You need to create an account with DUTRA FOR EDUCATION to use our services To protect your account, the user must keep the password confidential The activity carried out on or through the account is the responsibility of the user Upon your membership, data may be obtained from your account for the purpose of identifying you, interacting with other users and communicating with you from our team')}</p>
                </li>
                <li style={{ marginBottom: '20px' }}>
                  <h4>{I18n.t('Price')}</h4>
                  <p>{I18n.t('To access the content of the DUTRA FOR EDUCATION platform, the user must purchase the courses chosen in accordance with the conditions described on the platform The platform reserves the right to unilaterally readjust, at any time, the values ​​of the services or products without consultation or prior consent of the user')}
                  </p>
                  <p>{I18n.t('The values ​​applied are those that are in effect at the time of the order')}</p>
                  <p>{I18n.t('Upon contracting the service, the platform may request financial information from the user, such as CPF, billing address and card data By entering such data, the user agrees that the prices then in force and informed at the time of contracting will be charged, according to the form of payment that may be chosen Said financial data may be stored to facilitate future access and hiring')}</p>
                  <p>{I18n.t('The contracting of the services will be automatically renewed by the platform, regardless of communication to the user, through periodic charging of the same form of payment indicated by the user when contracting the service')}
                  </p>
                </li>
                <li style={{ marginBottom: '20px' }}>
                  <h4>{I18n.t('Cancellation')}</h4>
                  <p>{I18n.t('The user may cancel the contracting of the services in accordance with the terms that are defined at the time of contracting')}
                  </p>
                </li>
                <li style={{ marginBottom: '20px' }}>
                  <h4>{I18n.t('Your Content on our Services')} </h4>
                  <p>{I18n.t('Some of our services allow you to upload, submit, store, send or receive content You retain ownership of any intellectual property rights you hold in that content In short, what belongs to you, stays with you')}
                  </p>
                  <p>{I18n.t('When you upload, submit, store, send or receive content to or through our services, you grant DUTRA FOR EDUCATION (and those we work with) a license to use, host, store, reproduce, modify, create derivative works , communicate, publish, perform such content The rights you grant are for the limited purposes of operating, promoting and improving our services and developing new services')}</p>
                  <p>{I18n.t('Using our services, you agree that we may contact you regarding the data provided and offer investments, products and services related to our core business')} </p>
                </li>
                <li style={{ marginBottom: '20px' }}>
                  <h4>   {I18n.t('About Software in our Services')} </h4>
                  <p>{I18n.t('DUTRA FOR EDUCATION grants you a personal, non-exclusive, non-transferable, royalty-free license to use the software provided by DUTRA FOR EDUCATION as part of the Services This license is for the sole purpose of enabling you to use and enjoy the benefit of the services as provided by DUTRA FOR EDUCATION in the manner permitted by these terms You may not copy, modify, distribute, sell or rent any part of our services or the included software, nor may you reverse engineer or attempt to extract the source code of such software, except where law prohibits such restrictions, or where you have our written permission')}      </p>
                </li>
                <li style={{ marginBottom: '20px' }}>
                  <h4>{I18n.t('How to modify and cancel our Services')} </h4>
                  <p>{I18n.t('We are constantly changing and improving our services We may add or remove functionality or features and we may suspend or terminate a service altogether')}
                  </p>
                  <p>{I18n.t('You can stop using our services at any time DUTRA FOR EDUCATION may also stop providing the services to you or, include or create limits to our services at any time')} </p>
                  <p>{I18n.t('We believe that you own your data and that it is important to preserve your access to that data If we discontinue a service, when reasonably possible, you will be informed reasonably in advance and given the opportunity to withdraw your information from that service')} </p>
                </li>
                <li style={{ marginBottom: '20px' }}>
                  <h4> {I18n.t('Our Warranties and Disclaimers')} </h4>
                  <p>{I18n.t('DUTRA FOR EDUCATION, nor its suppliers or distributors offer any guarantees about the services We are not responsible for the contents of the Services, for the specific functionality of the Services, or for the reliability, availability or ability to meet your needs We provide the services as they are')}
                  </p>
                </li>
                <li style={{ marginBottom: '20px' }}>
                  <h4>{I18n.t('Responsibility for our Services')} </h4>
                  <p>{I18n.t('Where permitted by law, DUTRA FOR EDUCATION and suppliers or distributors will not be liable for lost profits, lost revenue, lost data, financial loss or for indirect, special, consequential, exemplary or punitive damages')}
                  </p>
                  <p>{I18n.t('In all cases, DUTRA FOR EDUCATION and its suppliers and distributors will not be liable for any loss or damage that is not reasonably foreseeable')}
                  </p>
                </li>
                <li style={{ marginBottom: '20px' }}>
                  <h4>{I18n.t('Commercial uses of our Services')}  </h4>
                  <p>{I18n.t('If you are using our services on behalf of a company, that company accepts these terms It will hold harmless and indemnify DUTRA FOR EDUCATION and its affiliates, officers, agents and workers from any claim, proceeding or legal action arising out of or relating to the use of the services or breach of these terms, including any liability or expense resulting from claims, losses, damages, lawsuits, judgments, litigation costs and attorneys fees')}  </p>

                </li>
                <li style={{ marginBottom: '20px' }}>
                  <h4>{I18n.t('About these Terms')}  </h4>
                  <p>{I18n.t('We may modify these terms or any additional terms that apply to a service to, for example, reflect changes in the law or changes to our services You should review the terms regularly We will post notices of changes to these terms on this page We will post a notice of change regarding additional terms within the applicable service Changes will not be applied retroactively and will take effect at least fourteen days after they are posted However, changes regarding new functionality of a service or changes made for legal reasons will be effective immediately If you do not agree to the changed terms of a service, you must discontinue using that service')}
                  </p>
                  <p>{I18n.t('If you do not comply with these terms and we do not take immediate action, this does not mean that we are waiving any rights we may have to take action in the future')}
                  </p>
                  <p>{I18n.t('Caso uma condição específica destes termos não seja executável, isso não prejudicará quaisquer outros termos')}
                  </p>
                  <p>{I18n.t('Even without the obligation to do so, our company reserves the right to monitor the use of this platform to determine compliance with these Terms of Use as well as to remove or veto any information for any reason In any case, you are fully responsible for the content of your submissions You acknowledge and agree that neither the company nor any third party providing content to the company will assume any responsibility for any action or inaction by the company or such third party with respect to any submission')} </p>
                  <p>{I18n.t('The company reserves the right to cooperate fully with the competent authorities or court orders for the company to reveal the identity of any person who posts an email, message or makes available any material that may violate these Terms of Use')} </p>
                  <p><strong>{I18n.t('BY ACCEPTING THIS AGREEMENT YOU RELEASE THE COMPANY FROM ANY CONSEQUENCES ARISING OUT OF ANY ACTION BY THE COMPANY DURING OR AS A RESULT OF ITS INVESTIGATIONS AND/OR ACTION TAKEN RESULTING FROM INVESTIGATIONS BY BOTH THE COMPANY AND THE COMPETENT AUTHORITIES OF JUSTICE')} </strong></p>

                </li>
                {/* </ul> */}
              </div>
            </div>
          </div>

          <Footer />
        </div>
      </body>
    </>
  )
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(TermsAndConditions);