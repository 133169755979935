import React, { useState, useEffect } from 'react';
import image1 from './images/img-1.jpg'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { connect } from 'react-redux';
import axios from "axios";
import { toggleLoader } from '../../actions/loader';
import { categoryList } from '../../Utils/utils';
import { Link } from 'react-router-dom';
import Carousel from 'react-bootstrap/Carousel';
import { Translate, I18n } from "react-redux-i18n";

const CategoriesResponsive = () => {
  const [index, setIndex] = useState(0);
  const [category, setCategory] = useState([]);

  useEffect(async () => {
    var categoryList_var = await categoryList();
    setCategory(categoryList_var);
  }, [])

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  return (
    <>
      <div className='d-block d-sm-none'>
        <Carousel activeIndex={index} onSelect={handleSelect} indicators={false} >
          {category.map((list) => (
            <Carousel.Item >
              {/* <div class="col-xl-3 col-lg-6 col-md-6 wow fadeInUp animated">
                <div class="categories-top__single">
                  <Link to={"/course-list?category=" + list.id}>
                    <div class="categories-top__single-img">
                      <img src={list.web_media.url} alt="" />
                      <div class="categories-top__single-overlay">
                        <div class="categories-top__single-overlay-text1">
                          <p>30 full courses</p>
                        </div>
                        <div class="categories-top__single-overlay-text2">
                          <h4>{I18n.t(list.name)}</h4>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div> */}
              <div class="category-list_div col">
                <Link to={"/course-list?category=" + list.id}>
                  <div className='top_category_ '>
                    <div className='category_icon one'>{/* <i class="fas fa-bezier-curve"></i>  */}
                      <img src={list.web_media.url} />
                    </div>
                    <h2>{I18n.t(list.name)}</h2>
                    {/* <h5>188 Course</h5> */}
                  </div>
                </Link>
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      </div>

    </>
  )
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CategoriesResponsive);
