import React, { useEffect, useCallback, useState, useRef } from 'react';
import { connect } from 'react-redux';
import axios from "axios";
import { toggleLoader } from '../../actions/loader';
import { Link } from 'react-router-dom';
import './style.css';
import { displaySuccess, accessUserLogin, getUserProfile, liveCount, notificationCount, displayError } from '../../Utils/utils';
import { getUserData } from '../../actions/userdata';
import Avatar from 'react-avatar';
import defaultImg from './images/index.jpeg';
import camera from './images/camera.png';
import { Translate, I18n } from "react-redux-i18n";
import { Container, Row, Col, Image, Modal, Button } from 'react-bootstrap';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import config from "../../config/config";

const Leftbar = ({ menu = '', getUserData, userdata, toggleLoader }) => {
  const [user_data, setUserData] = useState(null);
  const [user_image, setUserImage] = useState('');
  const [count, setCount] = useState([]);
  const [notification_count, setNotificationCount] = useState('');

  const [upImg, setUpImg] = useState(null);
  const imgRef = useRef(null);
  const [crop, setCrop] = useState({ unit: '%', width: 30, aspect: 1 / 1 });
  const [completedCrop, setCompletedCrop] = useState(null);
  const [imageData, setImageData] = useState(defaultImg);
  const previewCanvasRef = useRef(null);
  const [show, setShow] = useState(false);
  const logout = () => {
    localStorage.removeItem('token');
    displaySuccess(I18n.t('Logout Successfull'));
    setTimeout(() => window.location.href = "/", 3000);
  }
  const userCallMethod = async () => {
    var final_data = await accessUserLogin();
    getUserData(final_data);
    return final_data;
  }
  useEffect(() => {
    var data = userCallMethod();
    setUserData(data)
  }, [])
  useEffect(async () => {
    // props.toggleLoader(true);
    var getProfile_var = await getUserProfile();
    if (getProfile_var !== null) {
      // if (getProfile_var.media !== null) {
      //   setUserImage(getProfile_var.media.url);
      // }
      if (getProfile_var.media === null) {
        setUserImage(defaultImg);
      }
      if (getProfile_var.media !== null) {
        setImageData(getProfile_var.media.url);
      }
    }
    // props.toggleLoader(false);
  }, [])
  useEffect(async () => {
    var live_var = await liveCount();
    setCount(live_var);
  }, [])
  useEffect(async () => {
    var noti_var = await notificationCount();
    setNotificationCount(noti_var);
  }, [])
  const handleClose = () => {
    setShow(false);
    document.getElementById('profile_picture').value = null;
  }
  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
  }, [completedCrop]);

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
      setShow(true)
    }
  };

  function generateDownload(canvas, crop) {
    if (!crop || !canvas) {
      return;
    }

    canvas.toBlob(
      (blob) => {
        var dataURL = new File([blob], "my_image.png", { type: "image/png", lastModified: new Date().getTime() })
        const data = new FormData();
        data.append('media', dataURL);
        var token = localStorage.getItem('token');
        toggleLoader(true);
        let path = config.siteUrl;
        axios.post(path + '/user/profilepicture', data, {
          headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
        })
          .then(function (response) {
            toggleLoader(false);
            displaySuccess(I18n.t('Profile Picture Uploaded Successfully'));
            setImageData(response.data.data.media.url)
            setUpImg(null);
            setShow(false)
            document.getElementById('profile_picture').value = null;
          })
          .catch(function (error) {
            toggleLoader(false);
            // if (error.response.status === 401) {
            //   window.location.href = '/';
            // }
            displayError(error);
          });
      },
      'image/png',
      1
    );
  }
  return (
    <>
      <div className="col-md-3 mb-3">
        <div className="card1">
          <div className="card-body">
            <div className="d-flex flex-column align-items-center text-center">
              {/* {user_image !== null || user_image !== '' ?
                <img src={user_image} className="rounded-circle" width="120" />
                :
                <img src={user_image} className="rounded-circle" style={{ maxWidth: "auto", height: "auto" }} />
              } */}
              <Image src={imageData} className="rounded-circle" style={{ maxWidth: "150px", height: "auto" }} />

              <div className="media-body mt-2">
                <div className=""> {/* custom-file */}
                  <div class="image-upload">
                    <label for="profile_picture">
                      <img className='img-fluid' src={camera} />
                    </label>
                    <input accept=".jpg,.jpeg,.png" name="profile_image" id="profile_picture" onChange={onSelectFile} type="file" />
                  </div>
                  {/* <input type="file" accept=".jpg,.jpeg,.png" name="profile_image" id="profile_picture" onChange={onSelectFile} /> */}
                  <Modal size="lg" show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                      <Modal.Title>{I18n.t("Update Profile Picture")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                      <div className="row">
                        <div className="col-md-6 mt-3">
                          {(upImg) ? <>
                            <div className="col-md-12 mt-3">
                              <center>
                                <ReactCrop
                                  src={upImg}
                                  onImageLoaded={onLoad}
                                  crop={crop}
                                  onChange={(c) => setCrop(c)}
                                  onComplete={(c) => setCompletedCrop(c)}
                                  className="react-crop-image"
                                />
                              </center>
                            </div>
                          </> : ""
                          }
                        </div>
                        <div className="col-md-6 mt-3">
                          {
                            (upImg) ? <>
                              <canvas
                                ref={previewCanvasRef}
                                style={{
                                  width: Math.round(completedCrop?.width ?? 0),
                                  height: Math.round(completedCrop?.height ?? 0)
                                }}
                              />
                            </>
                              : <>
                              </>
                          }
                        </div>
                      </div>

                    </Modal.Body>
                    <Modal.Footer>
                      <button
                        className="btn btn-warning mr-2"
                        type="button"
                        disabled={!completedCrop?.width || !completedCrop?.height}
                        onClick={() =>
                          generateDownload(previewCanvasRef.current, completedCrop)
                        }
                      >
                        {I18n.t("Upload cropped image")}
                      </button>
                      <Button variant="secondary" onClick={handleClose}> {I18n.t("Close")} </Button>
                    </Modal.Footer>
                  </Modal>
                  {/* <label className="custom-file-label" for="inputGroupFile01">Choose file</label> */}
                </div>
              </div>
              <div className="mt-3">
                <h4>{userdata.userData.first_name} {userdata.userData.last_name}</h4>
                <p className="text-secondary mb-1">{I18n.t("Student")}</p>
              </div>

            </div>
            <hr />
            <nav className="nav flex-column nav-pills nav-gap-y-1">
              <Link to="/mycourse" data-toggle="tab" className={(menu === 'course') ? 'nav-item nav-link has-icon nav-link-faded active' : 'nav-item nav-link has-icon nav-link-faded  '}>
                {/* <div className="media-body"> */}
                <i className="fa fa-book" aria-hidden="true"></i> &nbsp;  {I18n.t("My Courses")}
                {count.course_count === 0 ? "" :
                  <span className='badge_count_event'>{count.course_count}</span>}

                {/* </div>  */}
              </Link>
              <Link to="/live-event" data-toggle="tab" className={(menu === 'live') ? 'nav-item nav-link has-icon nav-link-faded active' : 'nav-item nav-link has-icon nav-link-faded  '}>
                <i class="icofont-live-support"></i> &nbsp; {I18n.t("Live Events")}
                {count.live_events === 0 ? "" :
                  <span className='badge_count_event'>{count.live_events}</span>}
              </Link>
              <Link to="/certificate" data-toggle="tab" className={(menu === 'certificate') ? 'nav-item nav-link has-icon nav-link-faded active' : 'nav-item nav-link has-icon nav-link-faded  '}>
                <i class="icofont-certificate-alt-1"></i> &nbsp; {I18n.t("Certificate")}
                {count.certificate_count === 0 ? "" :
                  <span className='badge_count_event'>{count.certificate_count}</span>}
              </Link>
              <Link to="/notifications" data-toggle="tab" className={(menu === 'notification') ? 'nav-item nav-link has-icon nav-link-faded active' : 'nav-item nav-link has-icon nav-link-faded  '}>
                <i className="fa fa-bell" aria-hidden="true"></i> &nbsp; {I18n.t("Notification")}
                {notification_count === 0 ? "" :
                  <span className='badge_count_event'>{notification_count}</span>}
              </Link>
              <Link to="/profile" data-toggle="tab" className={(menu === 'profile') ? 'nav-item nav-link has-icon nav-link-faded active' : 'nav-item nav-link has-icon nav-link-faded  '}>
                <i className="fa fa-user" aria-hidden="true"></i> &nbsp;  {I18n.t("Profile")}

              </Link>
              <Link to="/change-password" data-toggle="tab" className={(menu === 'change-password') ? 'nav-item nav-link has-icon nav-link-faded active' : 'nav-item nav-link has-icon nav-link-faded  '}>
                <i className="fa fa-key" aria-hidden="true"></i> &nbsp;  {I18n.t("Change Password")}
              </Link>
              <Link data-toggle="tab" className="nav-item nav-link has-icon nav-link-faded" onClick={logout}>
                <i className="fa fa-sign-out" aria-hidden="true"></i> &nbsp;   {I18n.t("Logout")}
              </Link>
            </nav>
          </div>
        </div>
      </div>
    </>
  )
}
const mapDispatchToProps = {
  getUserData,
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
    userdata: state.userdata
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Leftbar);
