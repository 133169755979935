import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import axios from "axios";
import { toggleLoader } from '../actions/loader';
import Topbar from '../components/Topbar';
import Footer from '../components/Footer';
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import { Link } from 'react-router-dom';
import Leftbar from '../components/Leftbar';
import { FacebookShareButton, WhatsappShareButton, LinkedinShareButton } from 'react-share';
import { FacebookIcon, WhatsappIcon, LinkedinIcon } from "react-share";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import './certificate.css'
import { getCertificate, upcomingCertificate } from '../Utils/utils';
import Moment from 'react-moment';
import ProgressBar from 'react-bootstrap/ProgressBar'
import Alert from 'react-bootstrap/Alert'
import { Translate, I18n } from "react-redux-i18n";
import myCourseD from './img/default-img.svg';

const Certificate = (props) => {
  const [show, setShow] = useState(false);
  const [list, setList] = useState([]);
  const [lgShow, setLgShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setLgShow(true);
  const [upcoming, setUpcoming] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  useEffect(async () => {
    props.toggleLoader(true);
    var get_certficate = await getCertificate();
    setList(get_certficate);
    props.toggleLoader(false);
  }, [])

  useEffect(async () => {
    props.toggleLoader(true);
    var get_upcoming = await upcomingCertificate();
    setUpcoming(get_upcoming);
    props.toggleLoader(false);
  }, [])
  return (
    <>
      <body id="page-top">
        <div className="page-wrapper">

          <Topbar />
          {/* <InnerBanner/> */}
          <section className="inner-banner d-none d-sm-block">
            <div className="container">
              <ul className="list-unstyled thm-breadcrumb">
                <li><Link to="/">{I18n.t("Home")}</Link></li>
                <li className="active"><Link to="/my-course">{I18n.t("Certificate")}</Link></li>
              </ul>
              <h2 className="inner-banner__title">{I18n.t("Certificate")}</h2>
            </div>
          </section>
          <section className="inner-banner-responsive d-block d-sm-none">
            <div className="container">
              <Breadcrumb>
                <Breadcrumb.Item href="/">{I18n.t("Home")}</Breadcrumb.Item>
                <Breadcrumb.Item href="" active>
                  {I18n.t("Certificate")}
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </section>
          <>
            <div class="container">
              <div class="">
                <div class="row gutters-sm my-4">
                  <Leftbar menu={'certificate'} />
                  <div class="col-md-9">
                    <div className="">
                      <div className="page-section mt-2 mt-lg-0">
                        <div className="page__container">
                          {(list.length == 0 && upcoming.length == 0) ?
                            <div className="default_img">
                              <img src={myCourseD} className="img-fluid" />
                              <h5 className="">{I18n.t('No Certificates')}</h5>
                            </div>
                            :
                            <>
                              {list.map((data) => (
                                <div className="list-card align-items-center shadow-sm">
                                  <div className="row">
                                    <div className="col-md-4 px-lg-4 my-3">
                                      <div onClick={handleShow} style={{ cursor: 'pointer' }}>
                                        {data.image !== null ? <img className="w-100 Certification-img" src={data.image.url} /> : ''}
                                      </div>
                                      <Modal
                                        size="lg"
                                        show={lgShow}
                                        onHide={() => setLgShow(false)}
                                        backdrop="static"
                                        keyboard={false}
                                      >
                                        <Modal.Header closeButton>
                                          <Modal.Title>{I18n.t("Certificate Preview")}</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                          <img className="Certification-img img-fluid mx-auto d-block" src={data.image.url} />
                                        </Modal.Body>
                                        <Modal.Footer>
                                          <Button variant="secondary" onClick={() => setLgShow(false)}>
                                            {I18n.t("Close")}
                                          </Button>
                                          <Button variant="primary" target="_blank" download href={data.pdf.url}>Download</Button>

                                        </Modal.Footer>
                                      </Modal>
                                    </div>
                                    <div className="col-md-8">
                                      <div className="my-4">
                                        <div className=" justify-content-between">{/* media */}
                                          <div className="group text-center text-md-left">
                                            <a className="h4">{data.offer.name}</a>
                                            <ul className="list-inline mt-1">
                                              <li className="list-inline-item mr-2">
                                                <i class="fas fa-user-alt"></i> {data.offer.user.full_name}
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                        <p className="description_certificate ml-md-4 text-center text-md-left"> {I18n.t("Release date")} :  <Moment format="DD/MM/YYYY" withTitle>
                                          {data.created_at}
                                        </Moment>
                                        </p>
                                        <hr />
                                        <div className="d-md-flex justify-content-between align-items-center text-center">
                                          <div className="float-md-left">
                                            <FacebookShareButton url={data.image.url} className="mr-1">
                                              <FacebookIcon size={30} round={true} />
                                            </FacebookShareButton>
                                            <WhatsappShareButton url={data.image.url} className="mr-1">
                                              <WhatsappIcon size={30} round={true} />
                                            </WhatsappShareButton>
                                            <LinkedinShareButton url={data.image.url} className="mr-1">
                                              <LinkedinIcon size={30} round={true} />
                                            </LinkedinShareButton>
                                          </div>
                                          <div className="float-md-right m-2 m-md-0">
                                            <a className="btn-dark p-2 mr-3 download_btn" style={{ borderRadius: '3px' }}
                                              target="_blank"
                                              href={data.pdf.url}
                                              download

                                            ><i class="fa fa-download" aria-hidden="true"></i>   {I18n.t("Download Pdf")}</a>
                                            <a className="btn-primary p-2 mr-md-3 download_btn" style={{ borderRadius: '3px' }}
                                              target="_blank"
                                              href={data.image.url}
                                              download
                                            ><i class="fa fa-download" aria-hidden="true"></i>{I18n.t("Download Image")}</a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                              {upcoming.map((list) => (
                                <div className="list-card align-items-center shadow mt-4">
                                  <div className="row">
                                    <div className="col-4 px-lg-4 my-3">
                                      <img className="w-100 Certification-img" src={list.offer.certificate.media.url} />

                                    </div>
                                    <div className="col-8 align-self-center">
                                      <div className="my-4">
                                        <div className="media justify-content-between">
                                          <div className="group">
                                            <a className="h4">{list.offer.name}</a>
                                            <ul className="list-inline mt-1">
                                              <li className="list-inline-item mr-2">
                                                <i className="icofont-user-alt-3"></i> {list.offer.user.full_name}
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                        <hr className="mt-4" />
                                        <ProgressBar animated now={list.percentage_complete} label={list.percentage_complete + "%  " + I18n.t("Complete")} style={{ height: '14px' }} />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </>
                          }

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        </div>
        <Footer />
      </body>
    </>
  )
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Certificate);
