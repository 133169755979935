import React, { useState, useEffect } from 'react';
import Topbar from '../components/Topbar';
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';
import './style.css';
import { toggleLoader } from '../actions/loader';
import { connect } from 'react-redux';
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';
import "bootstrap/dist/css/bootstrap.min.css";
import { formatCreditCardNumber, formatExpirationDate, formatCVC } from './CardUtils';
import Payment from 'payment';
import { CourseDetail, getUserProfile, getCountry, getStates, getCities, displayError, displaySuccess, getCreditCardRates, knowCoursePrice, accessUserLogin, displayErrorString } from '../Utils/utils';
import axios from "axios";
import config from "../config/config";
import queryString from 'query-string';
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import { getUserData } from '../actions/userdata';
import { useHistory } from 'react-router-dom';
import Toast from 'react-bootstrap/Toast';

import { Translate, I18n } from "react-redux-i18n";
import ReactInputMask from "react-input-mask";
const FreeCheckout = ({ value, onChange, ...props }) => {
  const history = useHistory();

  const localePriceFormat = (localStorage.getItem('langauge_selected') == 'en' || localStorage.getItem('langauge_selected') == '') ? 'en' : 'pt-BR'
  let [mask, setMask] = React.useState(null);
  const [number, setCardNumber] = useState('');
  const [name, setCardName] = useState('');
  const [cvc, setCardSecurityCode] = useState('');
  const [expiry, setCardValidity] = useState('');
  const [focus, setCardFocused] = useState();
  const [issuer, setCardIssuer] = useState('');

  const [course_image, setCourseImage] = useState('');
  const [title, setTitle] = useState('');
  const [instructor_name, setInstructorName] = useState('');
  const [price, setPrice] = useState(0);
  const [installment_quantity, setInstallmentQuantity] = useState(1);

  const [first_name, setFirstName] = useState('');
  const [last_name, setLastName] = useState('');
  const [address, setAddress] = useState('');
  const [zip_code, setZipCode] = useState('');
  const [country_id, setCountryId] = useState(30);
  const [state_id, setStateId] = useState('');
  const [email, setEmail] = useState('');
  const [cpf, setCpf] = useState('');
  const [city_id, setCityId] = useState('');
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [countries, setCountries] = useState([]);
  const [payment_type, setPaymentType] = useState(3);
  const [boleto_rate, setBoletoRate] = useState(0);
  const [discount_percentage, setDiscountPrecentage] = useState('');
  const parsed = queryString.parse(window.location.search);
  const [cross_sell_id, setCrossSellId] = useState(parsed.cross_sell_id);

  const [card_holder_name, setCardHolderName] = useState('');
  const [card_no, setCardNo] = useState('');
  const [securityCode, setSecurityCode] = useState('');
  const [validThru, setValidThru] = useState('');
  const [card_rate, setCardRate] = useState('0');
  const [emailReadOnly, setEmailReadOnly] = useState(false);

  const [installment_no, setInstallmentNo] = useState([]);
  const [install, setInstall] = useState('');
  const [price_after_discount, SetPriceAfterDiscount] = useState('');
  const [price_after_installment, setPriceAfterInstallment] = useState('');
  const [userdata, setUserData] = useState(null);
  const [temp_var, setTempVar] = useState('');
  const [cpf_inter, setCpfInter] = useState('');
  const [zip_inter, setZipInter] = useState('');
  let [masked, setMasked] = React.useState(null);
  const [intrest_mode, setIntrestMode] = useState('');
  const [isPasscode, setIsPasscode] = useState('');
  const [isSubmit, setIsSubmit] = useState(false)
  const [code, setCode] = useState('')
  const [user_id, setUserId] = useState('');
  const [voucher_id, setVoucherId] = useState('');
  const [showA, setShowA] = useState(false)
  const [code2, setCode2] = useState('');

  const handleInputFocus = (e) => {
    setCardFocused({ focus: e.target.name });
  }
  const carddata = [];
  const handleInputChange = async (e) => {
    const { name, value } = e.target;
    var isRun = 0;
    if (e.target.name === "number") {
      e.target.value = formatCreditCardNumber(e.target.value);
      setCardNumber(e.target.value);
      carddata[e.target.name] = e.target.value;
      const issuer = Payment.fns.cardType(e.target.value);
      setCardIssuer(issuer);
      if (issuer !== null) {
        internalCreditCardRates();
      } else {
        var creditCard_rate = await getCreditCardRates();
        setBoletoRate(creditCard_rate.taxaBoleto);
      }
    } else if (e.target.name === "expiry") {
      e.target.value = formatExpirationDate(e.target.value);
      setCardValidity(e.target.value);
      carddata[e.target.name] = e.target.value;
    } else if (e.target.name === "cvc") {
      e.target.value = formatCVC(e.target.value);
      setCardSecurityCode(e.target.value);
      carddata[e.target.name] = e.target.value;
    } else if (e.target.name === "name") {
      setCardName(e.target.value);
      carddata[e.target.name] = e.target.value;
    }
  }
  useEffect(async () => {
    props.toggleLoader(true);
    var courseDetail_var = await CourseDetail(props.match.params.id);
    let quantity_customer = courseDetail_var.quantity_of_customer;
    let enroll_user = courseDetail_var.enroll_user_count;
    if (quantity_customer === "unlimited" || quantity_customer > enroll_user) {
      setCourseImage(courseDetail_var.media.url);
      setTitle(courseDetail_var.name);
      setInstructorName(courseDetail_var.user.full_name);
      setPrice(courseDetail_var.price);
      setIntrestMode(courseDetail_var.interest_mode);
      setIsPasscode(courseDetail_var.free_content)
      if (courseDetail_var.free_content == 2) {
        setIsSubmit(true)
      } else {
        setIsSubmit(false)
      }
      if (courseDetail_var.installment_quantity == 0) {
        setInstallmentQuantity(1);
        setInstall(1);
      } else {
        setInstallmentQuantity(courseDetail_var.installment_quantity);
      }
    } else {
      displayErrorString(I18n.t('This offer has reached limit to maximum number of enrolled students'));
      setTimeout(() => window.location.href = "/course-list", 3000);
    }
    props.toggleLoader(false);

  }, [price])
  const userCallMethod = async () => {
    var final_data = await accessUserLogin();
    return final_data;
  }
  useEffect(async () => {
    props.toggleLoader(true);
    var data = await accessUserLogin();
    setUserData(data)

    if (data === null) {
      setFirstName("");
      setLastName("");
      setEmail("");
      setAddress("");
      setZipCode("");
      setCpf("");
      setEmailReadOnly(false);
    } else {
      var getProfile_var = await getUserProfile();
      if (getProfile_var !== null) {
        setEmailReadOnly(true);
        setFirstName(getProfile_var.first_name);
        setLastName(getProfile_var.last_name);
        setEmail(getProfile_var.email);
        setAddress(getProfile_var.address);
        setCountryId(getProfile_var.country_id);
        setStateId(getProfile_var.state_id);
        setCityId(getProfile_var.city_id);
        setZipCode(getProfile_var.zip_code);
        setCpf(getProfile_var.cpf);
        setUserId(getProfile_var.id);
        if (getProfile_var.state !== null) {
          var get_states = await getStates(getProfile_var.country_id);
          setStates(get_states);
        }
        if (getProfile_var.city !== null) {
          var get_cities = await getCities(getProfile_var.state_id);
          setCities(get_cities);
        }

        if (getProfile_var?.cpf !== null) {
          setCpf(getProfile_var?.cpf);
          let nums = getProfile_var.cpf.replace(/[^0-9]/g, "");
          setMask("999.999.999-99");
          setCpfInter(nums);
        }
        if (getProfile_var.zip_code !== null) {
          let num = getProfile_var.zip_code.replace(/[^0-9]/g, "");
          setMasked("99.999-999");
          setZipCode(getProfile_var.zip_code);
          setZipInter(num);
        }

      }
    }
    props.toggleLoader(false);
  }, [])

  useEffect(async () => {
    fetchStates(30);
    props.toggleLoader(true);
    var country_option = await getCountry();
    setCountries(country_option)
    props.toggleLoader(false);
  }, [])
  const fetchStates = async (country_id) => {
    props.toggleLoader(true);
    var states_options = await getStates(country_id);
    setStates(states_options);
    setCountryId(country_id)
    props.toggleLoader(false);
  }
  const fetchCity = async (state_id) => {
    props.toggleLoader(true);
    var city_options = await getCities(state_id);
    setCities(city_options);
    setStateId(state_id)
    props.toggleLoader(false);
  }
  const PaymentSales = () => {
    var token = localStorage.getItem('token');
    props.toggleLoader(true);
    let path = config.siteUrl;
    axios.post(path + '/student/free/course/enroll', {
      offer_id: props.match.params.id,
      amount: price,
      installments: 1,
      // cpf: cpf_inter,
      address: address,
      zip_code: zip_inter,
      // payment_type: payment_type,
      paid_amount: grandTotal,
      cross_sell_id: cross_sell_id,
      credit_card_rate: 0,
      country_id: country_id,
      state_id: state_id,
      city_id: city_id,
      email: email,
      boleto_rate: 0,
      first_name: first_name,
      last_name: last_name,
      interest_mode: intrest_mode,
      discount_voucher_id: voucher_id,
    }, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    })
      .then(function (response) {
        props.toggleLoader(false);
        displaySuccess(I18n.t('Payment made successfully'));
        setTimeout(() => window.location.href = "/mycourse", 3000);
      })
      .catch(function (error) {
        props.toggleLoader(false);
        if (error.response.status === 401) {
          window.location.href = '/';
        }
        displayError(error);
      });
  }
  useEffect(async () => {
    props.toggleLoader(true);
    if (Object.keys(parsed).length !== 0) {
      var course_price_var = await knowCoursePrice(props.match.params.id, cross_sell_id);
      setDiscountPrecentage(course_price_var.discount_percentage);
    }
    props.toggleLoader(false);
  }, [])
  const subtotal = price_after_discount + (price_after_discount * (intrest_mode === "paid by producer" ? "0" : card_rate) / 100);
  const subtotal2 = price_after_installment + (price_after_installment * (intrest_mode === "paid by producer" ? "0" : card_rate) / 100);
  const grandTotal = installment_quantity === 0 || installment_quantity === 1 ? parseFloat(subtotal).toFixed(2) : parseFloat(subtotal2).toFixed(2);
  const creditSelect = () => {
    setPaymentType(3);
  }
  const boletoSelect = () => {
    setPaymentType(1);
    setInstall(1);
  }
  const boletoTotal = price_after_discount;
  const boletoTotal2 = price_after_installment;
  const grandBoletoTotal = installment_quantity === 0 || installment_quantity === 1 ? parseFloat(boletoTotal).toFixed(2) : parseFloat(boletoTotal2).toFixed(2)

  const calculations = () => {
    var discounted_price = price - (price * discount_percentage / 100);
    SetPriceAfterDiscount(discounted_price);
    var installedPrice = discounted_price / install;
    setPriceAfterInstallment(installedPrice);
  }

  const internalCreditCardRates = async () => {
    var installment = install;
    var installment_quantity = install;
    if (issuer === 'visa') {
      var creditCard_rate = await getCreditCardRates();
      if (installment_quantity === 0 || installment_quantity === 1) {
        setCardRate(creditCard_rate.taxaTotal.Visa.credit["1"]);
      } else {
        setCardRate(creditCard_rate.taxaTotal.Visa.credit[installment]);
      }
    } else if (issuer === 'mastercard') {
      var creditCard_rate = await getCreditCardRates();
      if (installment_quantity === 0 || installment_quantity === 1) {
        setCardRate(creditCard_rate.taxaTotal.MasterCard.credit["1"]);
      } else {
        setCardRate(creditCard_rate.taxaTotal.MasterCard.credit[installment]);
      }
    } else if (issuer === 'discover') {
      var creditCard_rate = await getCreditCardRates();
      if (installment_quantity === 0 || installment_quantity === 1) {
        setCardRate(creditCard_rate.taxaTotal.Discover.credit["1"]);
      } else {
        setCardRate(creditCard_rate.taxaTotal.Discover.credit[installment]);
      }
    }
    else if (issuer === 'amex') {
      var creditCard_rate = await getCreditCardRates();
      if (installment_quantity === 0 || installment_quantity === 1) {
        setCardRate(creditCard_rate.taxaTotal["American Express"].credit["1"]);
      } else {
        setCardRate(creditCard_rate.taxaTotal["American Express"].credit[installment]);
      }
    }
  }
  useEffect(() => {
    props.toggleLoader(true);
    if (issuer != "" && issuer != null) {
      internalCreditCardRates();
    }
    calculations();
    props.toggleLoader(false);
  }, [install, price, discount_percentage])

  useEffect(() => {
    var temp_arr = [];
    for (var i = 1; i <= installment_quantity; i++) {
      temp_arr.push(i);
    }
    setInstallmentNo(temp_arr);
    setInstall(installment_quantity);
  }, [installment_quantity])

  const zipHandle = ev => {
    let { value } = ev.target;
    let nums = value.replace(/[^0-9]/g, "");
    setMasked("99.999-999");
    setZipCode(ev.target.value);
    setZipInter(nums);
    if (nums.length < 3 && mask != null) {
      setMask(null);
      ev.target.value = nums;
    }
  };
  // const applyCoupon = () => {
  //   console.log(code)
  //   displaySuccess("Coupon Applied Successfully")
  //   setIsSubmit(true)
  // }

  const applyCoupon = () => {
    setCode2(code)
    var token = localStorage.getItem('token');
    props.toggleLoader(true);
    let path = config.siteUrl;
    axios.post(path + '/discount-voucher/apply', {
      offer_id: props.match.params.id,
      code: code,
      user_id: user_id,
    }, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    })
      .then(function (response) {
        props.toggleLoader(false);
        displaySuccess("Coupon Applied Successfully")
        setCode("");
        setShowA(true)
        setIsSubmit(false)
        setVoucherId(response.data.data.id);



      })
      .catch(function (error) {
        props.toggleLoader(false);
        if (error.response.status === 401) {
          window.location.href = '/';
        }
        displayError(error);
        // setShowB(false);
        // SetPriceAfterDiscount(price);
        setCode("");

      });

  }
  return (

    <>
      <body>
        <Topbar />
        <section className="inner-banner d-none d-sm-block">
          <div className="container">
            <ul className="list-unstyled thm-breadcrumb">
              <li><Link to="/">{I18n.t('Home')}</Link></li>
              <li className="active"><Link to="/my-course">{I18n.t('Checkout')}</Link></li>
            </ul>
            <h2 className="inner-banner__title">{I18n.t('Checkout')}</h2>
          </div>
        </section>
        <section className="inner-banner-responsive d-block d-sm-none">
          <div className="container">
            <Breadcrumb>
              <Breadcrumb.Item href="/">{I18n.t('Home')}</Breadcrumb.Item>
              <Breadcrumb.Item href="" active>
                {I18n.t('Checkout')}
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </section>
        <main>
          <div className="overflow-hidden">
            <div className="container">
              <div className="row">
                <div className="col-md-7 pl-md-0">
                  <div className="course__title">
                    <h4>{title} <br />
                    </h4>
                  </div>
                  <div className="row mt-4">
                    <>
                      <div className='col-sm-4'>
                        <label>{I18n.t('First Name')}</label>
                        <input type="text" className="form-control" placeholder={("First Name")}
                          value={first_name}
                          onChange={e => setFirstName(e.target.value)} />
                      </div>
                      <div className='col-sm-4'>
                        <label>{I18n.t('Last Name')}</label>
                        <input type="text" className="form-control" placeholder={("Last Name")}
                          value={last_name}
                          onChange={e => setLastName(e.target.value)} />
                      </div>
                      <div className="col-sm-4 mb-4">
                        <label>{I18n.t('Email Address')}</label>
                        <input type="text" className="form-control" placeholder={I18n.t("Email Address")}
                          value={email} readOnly={emailReadOnly} onChange={e => setEmail(e.target.value)} />
                      </div>
                    </>
                    <div className="col-md-12 mb-4">
                      <label>{I18n.t('Address')}</label>
                      <input type="text" className="form-control" placeholder={("Address")}
                        value={address}
                        onChange={e => setAddress(e.target.value)} />
                    </div>
                    <div className="col-md-4 col-xl-3 mb-4">
                      <label>{I18n.t('Country')}</label>
                      <select
                        className="form-control"
                        id="validationCustom04"
                        required=""
                        onChange={e => {
                          fetchStates(e.target.value)
                          setCountryId(e.target.value)
                        }} >
                        <option >{I18n.t('Select Country')}</option>
                        {countries.map((country) =>
                          <option value={country.id} selected={(country.id === country_id) ? true : false}>{country.name}</option>
                        )}
                      </select>
                    </div>
                    <div className="col-md-4 col-xl-3 mb-4">
                      <label>{I18n.t('State')}</label>
                      <select className="form-control" id="validationCustom04"
                        onChange={e => {
                          fetchCity(e.target.value)
                          setStateId(e.target.value)
                        }}>
                        <option  >{I18n.t('Select State')}</option>
                        {states.map((state) =>
                          <option value={state.id} selected={(state.id === state_id) ? true : false}>{state.name}</option>
                        )}
                      </select>
                    </div>
                    <div className="col-md-4 col-xl-3 mb-4">
                      <label>{I18n.t('City')}</label>
                      <select className="form-control" id="validationCustom04"
                        onChange={e => {
                          setCityId(e.target.value)
                        }}>
                        <option  >{I18n.t('Select City')}</option>
                        {cities.map((city) =>
                          <option value={city.id} selected={(city.id === city_id) ? true : false}>{city.name}</option>
                        )}
                      </select>
                    </div>
                    <div className="col-md-4 col-xl-3 mb-4">
                      <label>{I18n.t('ZipCode')}</label>
                      <ReactInputMask
                        className="form-control"
                        mask={masked}
                        onChange={zipHandle}
                        maskChar="_"
                        value={zip_code}
                        placeholder={I18n.t("ZipCode")}
                        {...props}
                      />
                    </div>
                  </div >

                  {/* < div className="form-group mt-3" style={{ textAlign: 'right' }}>
                    <button type="button" class="theme-btn black_line"
                      onClick={PaymentSales} disabled={isSubmit}>{I18n.t('Subscribe')}</button>
                  </div > */}
                  <br /><br />
                </div >
                <div className="col-lg-5 before">
                  <div className="order-summary__section">
                    <div className="order-summary__section__content">
                      <table className="product-table">
                        <tbody>
                          <tr className="product">
                            <td className="product__image">
                              <div className="product-thumbnail ">
                                <div className="product-thumbnail__wrapper">
                                  {/* <img className="product-thumbnail__image" src={course_image} /> */}
                                  <img className="product-thumbnail__image" src={course_image} />
                                </div>
                              </div>
                            </td>
                            <th className="product__description">
                              <span className="product__description__name">{title}</span>
                            </th>
                            <td className="product__price">
                              <span className="order-summary__emphasis">R$ {parseFloat(price).toLocaleString(localePriceFormat, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="3">
                              <hr />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="order-summary">
                      <table className="total-line-table">
                        <tbody>
                          {isPasscode == '2' ? <>


                            <tr>
                              <th className="total-line__name-disc">
                                <span style={{ paddingRight: '80px' }}>
                                  {I18n.t('Pass Code for Free Access')}
                                </span>
                              </th>
                            </tr>
                            <tr>
                              <th className="total-line__name">
                                <span>
                                  <input
                                    type="text" className="form-control" placeholder={I18n.t("Pass Code")}
                                    required style={{ width: '100%' }}
                                    onChange={e => setCode(e.target.value)}
                                    onKeyPress={(event) => {
                                      if (!/[A-Z0-9]/.test(event.key)) {
                                        event.preventDefault();
                                      }
                                      if (event.target.value.length > 10) {
                                        event.preventDefault();
                                      }
                                    }}
                                    value={code}
                                  />
                                </span>
                              </th>
                              <td className="total-line__price">
                                <span className="order-summary__small-text">
                                  <button type="button" class="check-btn black_line_check"
                                    onClick={applyCoupon}
                                  >{I18n.t('Apply')}</button>
                                </span>
                              </td>

                            </tr>
                            {/* {showB === true ? */}
                            <tr>
                              <Toast show={showA} onClose={(e) => {
                                // toggleShowA();
                                setIsSubmit(true)
                                setShowA(false);
                              }}>
                                <Toast.Header>
                                  <strong className="me-auto">{code2}</strong>
                                </Toast.Header>
                              </Toast>
                            </tr>
                            {/* : ""} */}

                          </> : null}
                          <tr>
                            <th className="total-line__name">
                              <span>
                                {I18n.t('Price')}
                              </span>
                            </th>
                            <td className="total-line__price">
                              <span className="order-summary__small-text">
                                R$ {parseFloat(price).toLocaleString(localePriceFormat, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <th className="total-line__name">
                              <span>
                                {I18n.t('Discount (-)')}
                              </span>
                            </th>
                            <td className="total-line__price">
                              <span className="order-summary__small-text">
                                {Object.keys(parsed).length !== 0 ? discount_percentage : "NA"}
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="3">
                              <hr />
                            </td>
                          </tr>
                          {installment_quantity === 0 || installment_quantity === 1 ?
                            "" :
                            <>
                              <tr>
                                <th className="total-line__name">
                                  <span>
                                    {I18n.t('Discounted Price')}
                                  </span>
                                </th>
                                <td className="total-line__price">
                                  <span className="order-summary__small-text">
                                    R$ {parseFloat(price_after_discount).toLocaleString(localePriceFormat, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <th className="total-line__name">
                                  <span>
                                    {I18n.t('Installment Quantity')}
                                  </span>
                                </th>
                                <td className="total-line__price">
                                  <span className="order-summary__small-text">
                                    {install}
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td colSpan="3">
                                  <hr />
                                </td>
                              </tr>
                            </>
                          }
                          {installment_quantity === 0 || installment_quantity === 1 ? <tr>
                            <th className="total-line__name">
                              <span>{I18n.t('Discounted Price')}
                              </span>
                            </th>
                            <td className="total-line__price">
                              <span className="order-summary__small-text">
                                R$ {parseFloat(price_after_discount).toLocaleString(localePriceFormat, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                              </span>
                            </td>
                          </tr>
                            :
                            <tr>
                              <th className="total-line__name">
                                <span>{I18n.t('Installment Price')}
                                </span>
                              </th>
                              <td className="total-line__price">
                                <span className="order-summary__small-text">
                                  R$ {parseFloat(price_after_installment).toLocaleString(localePriceFormat, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                </span>
                              </td>
                            </tr>
                          }
                          {payment_type === 1 ?
                            <tr>
                              <th className="total-line__name">
                                <span>
                                  {I18n.t('Boleto rates (+)')}
                                </span>
                              </th>
                              <td className="total-line__price">
                                <span className="order-summary__small-text">
                                  0%
                                </span>
                              </td>
                            </tr>
                            :
                            <tr>
                              <th className="total-line__name">
                                <span>
                                  {I18n.t('Credit card rates (+)')}
                                </span>
                              </th>
                              <td className="total-line__price">
                                <span className="order-summary__small-text">
                                  {intrest_mode === "paid by producer" ? 0 : card_rate} %
                                </span>
                              </td>
                            </tr>
                          }
                          <tr>
                            <th className="total-line__name">{I18n.t('Subtotal')}</th>
                            <td className="total-line__price">
                              <span className="payment__currency">R$</span>
                              <span className="order-summary">
                                {payment_type === 1 ? parseFloat(grandBoletoTotal).toLocaleString(localePriceFormat, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : parseFloat(grandTotal).toLocaleString(localePriceFormat, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                              </span>
                            </td>
                          </tr>
                        </tbody>

                        <tfoot className='bg-gray-totoal'>
                          <tr className="total-line">
                            <th className="payment-due-label">
                              <span className="payment-due-label__total">{I18n.t('Grand Total')}</span>
                            </th>

                            <td className="total-line__price">
                              <span className="payment__currency" style={{ fontSize: '16px' }}>{install} x </span>
                              {/* <span class="course-one__stars-count">{install} x</span> */}
                              <span className="payment__currency">R$</span>
                              <span className="payment-due__price">
                                {payment_type === 1 ? parseFloat(grandBoletoTotal).toLocaleString(localePriceFormat, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : parseFloat(grandTotal).toLocaleString(localePriceFormat, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                              </span>
                            </td>
                          </tr>
                        </tfoot>
                      </table>

                      < div className="form-group mt-5 mb-4" style={{ textAlign: 'right' }}>
                        <button type="button" class="theme-btn black_line"
                          onClick={PaymentSales} disabled={isSubmit}>{I18n.t('Subscribe')}</button>
                      </div >

                    </div>
                  </div>
                </div>
              </div >
            </div >
          </div >
        </main >
        <Footer />
      </body >
    </>
  )
}
const mapDispatchToProps = {
  toggleLoader,
  //getUserData
}
const mapStateToProps = (state) => {
  return {
    userdata: state.userdata
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(FreeCheckout);