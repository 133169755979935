import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import axios from "axios";
import { toggleLoader } from '../actions/loader';
import Topbar from '../components/Topbar';
import Footer from '../components/Footer';
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import { Link } from 'react-router-dom';
import Leftbar from '../components/Leftbar';
import config from "../config/config";
import {displayError,displaySuccess } from '../Utils/utils';
import { Translate, I18n } from "react-redux-i18n";

const ChangePassword = (props) => {
  const [current_password, setCurrentPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");

  const NewPassword = async () => {
    var token = localStorage.getItem('token');
    props.toggleLoader(true);
    let path = config.siteUrl;
    await axios.post(path + '/user/changepassword', {
      current_password: current_password,
      password: password,
      confirm_password: confirm_password,
    }, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        displaySuccess(I18n.t('Your password has been changed'));
        props.toggleLoader(false);
        setTimeout(() => window.location.href = "/mycourse", 3000);
      })
      .catch((error) => {
        displayError(error);
        props.toggleLoader(false);
        if (error.response.status === 401) {
          window.location.href = '/';
        }
      });
  };

  return (
    <>
      <body id="page-top">
        <div className="page-wrapper">

          <Topbar />
          {/* <InnerBanner/> */}
          <section className="inner-banner d-none d-sm-block">
            <div className="container">
              <ul className="list-unstyled thm-breadcrumb">
                <li><Link to="/">{I18n.t("Home")}</Link></li>
                <li className="active"><Link to="/my-course">{I18n.t("Change Password")}</Link></li>
              </ul>
              <h2 className="inner-banner__title">{I18n.t("Change Password")}</h2>
            </div>
          </section>
          <section className="inner-banner-responsive d-block d-sm-none">
            <div className="container">
              <Breadcrumb>
                <Breadcrumb.Item href="/">{I18n.t("Home")}</Breadcrumb.Item>
                <Breadcrumb.Item href="" active>
                   {I18n.t("Change Password")}
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </section>
          <>
            {/* <div class="container bootstrap snippets bootdey">
              <div class="row"> */}
            {/* <Leftbar/> */}
            <div class="container">
              <div class="main-body">
                <div class="row gutters-sm my-4">
                  <Leftbar menu={'change-password'} />
                  <div class="col-md-9">
                    <div class="card mb-3">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-sm-3">
                            <h6 class="mb-0">{I18n.t("Old Password")}</h6>
                          </div>
                          <div class="col-sm-9 text-secondary">
                            <input type="password" className="form-control"
                              placeholder={I18n.t("Old Password")} onChange={e => setCurrentPassword(e.target.value)} />
                          </div>
                        </div>
                        <hr />
                        <div class="row">
                          <div class="col-sm-3">
                            <h6 class="mb-0">{I18n.t("New Password")}</h6>
                          </div>
                          <div class="col-sm-9 text-secondary">
                          <input type="password" className="form-control"
                              placeholder={I18n.t("New Password")}  onChange={e => setPassword(e.target.value)} />
                          </div>
                        </div>
                        <hr />
                        <div class="row">
                          <div class="col-sm-3">
                            <h6 class="mb-0">{I18n.t("Confirm Password")}</h6>
                          </div>
                          <div class="col-sm-9 text-secondary">
                          <input type="password" className="form-control"
                              placeholder={I18n.t("Confirm Password")} onChange={e => setConfirmPassword(e.target.value)}  />
                          </div>
                        </div>
                        <hr />
                        <div class="row">
                          <div class="col-sm-12">
                          <button type="button" className="btn-lg btn-primary" onClick={NewPassword}>{I18n.t("Submit")}</button>
                          </div>
                        </div>
                      </div>
                    </div>




                  </div>
                </div>

              </div>
            </div>
            {/* </div>
            </div> */}
          </>
        </div>
        <Footer />
      </body>
    </>
  )
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
