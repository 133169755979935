import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import axios from "axios";
import { toggleLoader } from '../actions/loader';
import { Link } from 'react-router-dom';
import Topbar from '../components/Topbar';
import Footer from '../components/Footer';
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import './style.css';
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
const Faqs = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <body id="page-top">
        <div className="page-wrapper">

          <Topbar />
          <div>
            <section className="inner-banner d-none d-sm-block">
              <div className="container">
                <ul className="list-unstyled thm-breadcrumb">
                  <li><Link to="/">Home</Link></li>
                  <li className="active"><Link>FAQs</Link></li>
                </ul>
                <h2 className="inner-banner__title">FAQs</h2>
              </div>
            </section>
            <section className="inner-banner-responsive d-block d-sm-none">
              <div className="container">
                <Breadcrumb>
                  <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                  <Breadcrumb.Item href="" active>
                    FAQs
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>
            </section>
            <br />
            {/* <br /> */}
            <div className="faq_area section_padding_130" id="faq">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-12 col-sm-8 col-lg-6">
                    {/* <!-- Section Heading--> */}
                    <div className="section_heading text-center wow fadeInUp" data-wow-delay="0.2s" style={{ visibility: 'visible', animationDelay: '0.2s', animationName: "fadeInUp", marginTop: '-70px' }}>
                      <h3>Frequently Asked Questions</h3>
                      <p>Appland is completely creative, lightweight, clean &amp; super responsive app landing page.</p>
                      <div className="line"></div>
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center">
                  {/* <!-- FAQ Area--> */}
                  <div className="col-12 col-sm-10 col-lg-8">
                    <div class="accordion faq-accordian" id="faqAccordion">
                      <Accordion defaultActiveKey="0">
                        <Card className="card border-0 wow fadeInUp">
                          <Accordion.Toggle as={Card.Header} eventKey="0" className="card-header" >
                            <h6 class="mb-0 collapsed" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">How can I install this app?<span class="lni-chevron-up"></span></h6>
                            <div style={{ textAlign: 'right', marginTop: '-40px' }}>
                              <i class="fas fa-angle-down fa-lg " style={{color:'#26C9D3' }}></i>
                            </div>
                          </Accordion.Toggle>
                          <Accordion.Collapse eventKey="0">
                            <Card.Body>
                              <div class="card-body">
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto quidem facere deserunt sint animi sapiente vitae suscipit.</p>
                                <p>Appland is completely creative, lightweight, clean &amp; super responsive app landing page.</p>
                              </div>
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                        <Card className="card border-0 wow fadeInUp">
                          <Accordion.Toggle as={Card.Header} eventKey="1" className="card-header" >
                            <h6 class="mb-0 collapsed" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">How can I install this app?<span class="lni-chevron-up"></span></h6>
                            <div style={{ textAlign: 'right', marginTop: '-40px' }}>
                              <i class="fas fa-angle-down fa-lg " style={{color:'#26C9D3' }}></i>
                            </div>
                          </Accordion.Toggle>
                          <Accordion.Collapse eventKey="1">
                            <Card.Body>
                              <div class="card-body">
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto quidem facere deserunt sint animi sapiente vitae suscipit.</p>
                                <p>Appland is completely creative, lightweight, clean &amp; super responsive app landing page.</p>
                              </div>
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                        <Card className="card border-0 wow fadeInUp">
                          <Accordion.Toggle as={Card.Header} eventKey="2" className="card-header" >
                            <h6 class="mb-0 collapsed" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">How can I install this app?<span class="lni-chevron-up"></span></h6>
                            <div style={{ textAlign: 'right', marginTop: '-40px' }}>
                              <i class="fas fa-angle-down fa-lg " style={{color:'#26C9D3' }}></i>
                            </div>
                          </Accordion.Toggle>
                          <Accordion.Collapse eventKey="2">
                            <Card.Body>
                              <div class="card-body">
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto quidem facere deserunt sint animi sapiente vitae suscipit.</p>

                              </div>
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                        <Card className="card border-0 wow fadeInUp">
                          <Accordion.Toggle as={Card.Header} eventKey="3" className="card-header" >
                            <h6 class="mb-0 collapsed" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">How can I install this app?<span class="lni-chevron-up"></span></h6>
                            <div style={{ textAlign: 'right', marginTop: '-40px' }}>
                              <i class="fas fa-angle-down fa-lg " style={{color:'#26C9D3' }}></i>
                            </div>
                          </Accordion.Toggle>
                          <Accordion.Collapse eventKey="3">
                            <Card.Body>
                              <div class="card-body">
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto quidem facere deserunt sint animi sapiente vitae suscipit.</p>

                              </div>
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                        <Card className="card border-0 wow fadeInUp">
                          <Accordion.Toggle as={Card.Header} eventKey="4" className="card-header" >
                            <h6 class="mb-0 collapsed" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">How can I install this app?<span class="lni-chevron-up"></span></h6>
                            <div style={{ textAlign: 'right', marginTop: '-40px' }}>
                              <i class="fas fa-angle-down fa-lg " style={{color:'#26C9D3' }}></i>
                            </div>
                          </Accordion.Toggle>
                          <Accordion.Collapse eventKey="4">
                            <Card.Body>
                              <div class="card-body">
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Architecto quidem facere deserunt sint animi sapiente vitae suscipit.</p>

                              </div>
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      </Accordion>
                    </div>
                    <div className="support-button text-center d-flex align-items-center justify-content-center mt-4 wow fadeInUp" data-wow-delay="0.5s" style={{ visibility: 'visible', animationDelay: '0.2s', animationName: "fadeInUp" }}>
                      <i className="lni-emoji-sad"></i>
                      <p className="mb-0 px-2">Cant find your answers</p>
                      <Link to="/contactus"> Contact Us</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </body>
    </>
  )
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Faqs);
