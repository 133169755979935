import React, { useEffect, useCallback, useState, useRef } from 'react';
import { connect } from 'react-redux';
import axios from "axios";
import { toggleLoader } from '../actions/loader';
import Topbar from '../components/Topbar';
import Footer from '../components/Footer';
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import { Link } from 'react-router-dom';
// import './style.css';
import Leftbar from '../components/Leftbar';
import config from "../config/config";
import { getUserProfile, getCountry, getCities, getStates, getTimezoneDropdown, displayError, displaySuccess } from '../Utils/utils';
import Select from 'react-select';
import { Container, Row, Col, Image, Modal, Button } from 'react-bootstrap';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { Translate, I18n } from "react-redux-i18n";
import defaultImg from './images/index.jpeg';
import ReactInputMask from "react-input-mask";

const EditProfile = (props) => {
  const [first_name, setFirstName] = useState('');
  const [last_name, setLastName] = useState('');
  const [address, setAddress] = useState('');
  const [zip_code, setZipCode] = useState('');
  const [email, setEmail] = useState('');
  const [cpf, setCpf] = useState('');
  const [role_txt, setRole] = useState('');
  const [experience, setExperience] = useState('');
  const [speciality_in, setSpeciality] = useState('');
  const [facebook, setFacebook] = useState('');
  const [twitter, setTwitter] = useState('');
  const [linkedin, setLinkedin] = useState('');
  const [timezone, setTimezone] = useState([]);
  const [time, setTime] = useState('');
  const [phone_number, setPhoneNumber] = useState('');
  const [country_id, setCountryId] = useState(30);
  const [state_id, setStateId] = useState('');
  const [city_id, setCityId] = useState('');
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [countries, setCountries] = useState([]);

  const[instagram,setInstagram]=useState('');
  const [upImg, setUpImg] = useState(null);
  const imgRef = useRef(null);
  const [crop, setCrop] = useState({ unit: '%', width: 30, aspect: 1 / 1 });
  const [completedCrop, setCompletedCrop] = useState(null);
  const [imageData, setImageData] = useState(defaultImg);
  const previewCanvasRef = useRef(null);
  const [show, setShow] = useState(false);
  const[cpf_inter,setCpfInter]=useState('');
  let [mask, setMask] = React.useState(null);
 const[zip_inter,setZipInter]=useState('');
 let [masked, setMasked] = React.useState(null);

  useEffect(async () => {
    props.toggleLoader(true);
    var country_option = await getCountry();
    setCountries(country_option)
    props.toggleLoader(false);
  }, [])
  const fetchStates = async (country_id) => {
    props.toggleLoader(true);
    var states_options = await getStates(country_id);
    setStates(states_options);
    setCountryId(country_id)
    props.toggleLoader(false);
  }
  const fetchCity = async (state_id) => {
    props.toggleLoader(true);
    var city_options = await getCities(state_id);
    setCities(city_options);
    setStateId(state_id)
    props.toggleLoader(false);
  }
  useEffect(async () => {
    props.toggleLoader(true)
    var get_time = await getTimezoneDropdown();
    setTimezone(get_time);
    props.toggleLoader(false)
  }, [])
  useEffect(async () => {
    props.toggleLoader(true);
    var getProfile_var = await getUserProfile();
    if (getProfile_var !== null) {
      setFirstName(getProfile_var.first_name);
      setLastName(getProfile_var.last_name);
      setEmail(getProfile_var.email);
      setAddress(getProfile_var.address);
      setZipCode(getProfile_var.zip_code);
      setRole(getProfile_var.role_txt);
      setCpf(getProfile_var.cpf);
      // setCpfInter(getProfile_var.cpf_inter);
      setPhoneNumber(getProfile_var.phone_number);
      setExperience(getProfile_var.experience);
      setSpeciality(getProfile_var.speciality_in);
      setFacebook(getProfile_var.facebook);
      setLinkedin(getProfile_var.linkedin);
      setTwitter(getProfile_var.twitter);
      setTime(getProfile_var.timezone)
      setCountryId(getProfile_var.country_id);
      setStateId(getProfile_var.state_id);
      setCityId(getProfile_var.city_id);
      setInstagram(getProfile_var.instagram);
      if (getProfile_var.state !== null) {
        var get_states = await getStates(getProfile_var.country_id);
        setStates(get_states);
      }
      if (getProfile_var.city !== null) {
        var get_cities = await getCities(getProfile_var.state_id);
        setCities(get_cities);
      }
      if (getProfile_var.media !== null) {
        setImageData(getProfile_var.media.url);
      }
      if(getProfile_var.cpf !== null ){
        var nums = getProfile_var.cpf.replace(/[^0-9]/g, "");
        setMask("999.999.999-99");
        setCpf(getProfile_var.cpf);
        setCpfInter(nums);
      }
      if(getProfile_var.zip_code !== null){
        var num = getProfile_var.zip_code.replace(/[^0-9]/g, "");
        setMasked("99.999-999");
        setZipCode(getProfile_var.zip_code);
        setZipInter(num);
      }
      
    }
    props.toggleLoader(false);
  }, [])
  const handleClose = () => {
    setShow(false);
    document.getElementById('profile_picture').value = null;
  }
  const handleShow = () => setShow(true);
  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
  }, [completedCrop]);

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
      setShow(true)
    }
  };

  function generateDownload(canvas, crop) {
    if (!crop || !canvas) {
      return;
    }

    canvas.toBlob(
      (blob) => {
        var dataURL = new File([blob], "my_image.png", { type: "image/png", lastModified: new Date().getTime() })
        const data = new FormData();
        data.append('media', dataURL);
        var token = localStorage.getItem('token');
        props.toggleLoader(true);
        let path = config.siteUrl;
        axios.post(path + '/user/profilepicture', data, {
          headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
        })
          .then(function (response) {
            props.toggleLoader(false);
            displaySuccess(I18n.t('Profile Picture Uploaded Successfully'));
            setImageData(response.data.data.media.url)
            setUpImg(null);
            setShow(false)
            document.getElementById('profile_picture').value = null;
          })
          .catch(function (error) {
            props.toggleLoader(false);
            // if (error.response.status === 401) {
            //   window.location.href = '/';
            // }
            displayError(error);
          });
      },
      'image/png',
      1
    );
  }

  const StudentProfileUpdate = () => {
    var token = localStorage.getItem('token');
    props.toggleLoader(true);
    let path = config.siteUrl;
    axios.patch(path + '/user/updateuser/student', {
      first_name: first_name,
      last_name: last_name,
      address: address,
      zip_code: zip_inter,
      country_id: country_id,
      state_id: state_id,
      city_id: city_id,
      cpf: cpf_inter,
      role_txt: role_txt,
      experience: experience,
      speciality_in: speciality_in,
      facebook: facebook,
      twitter: twitter,
      linkedin: linkedin,
      timezone: time,
      phone_number : phone_number,
      instagram: instagram,
    }, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    })
      .then(function (response) {
        props.toggleLoader(false);
        displaySuccess(I18n.t('Profile Updated Successfully'));
        setTimeout(() => window.location.href = "/profile", 3000);
      })
      .catch(function (error) {
        props.toggleLoader(false);
        // if (error.response.status === 401) {
        //   window.location.href = '/';
        // }
        displayError(error);
      });
  }
  const handleChange = ev => {
    let { value } = ev.target;
    let nums = value.replace(/[^0-9]/g, "");
    setMask("999.999.999-99");
    setCpf(ev.target.value);
    setCpfInter(nums);
    if (nums.length < 3 && mask != null) {
      setMask(null);
      ev.target.value = nums;
    }
  };
  const zipHandle = ev => {
    let { value } = ev.target;
    let nums = value.replace(/[^0-9]/g, "");
    setMasked("99.999-999");
    setZipCode(ev.target.value);
    setZipInter(nums);
    if (nums.length < 3 && mask != null) {
      setMask(null);
      ev.target.value = nums;
    }
  };
  return (
    <>
      <body id="page-top">
        <div className="page-wrapper">

          <Topbar />
          <section className="inner-banner d-none d-sm-block">
            <div className="container">
              <ul className="list-unstyled thm-breadcrumb">
                <li><Link to="/">{I18n.t("Home")}</Link></li>
                <li className="active"><Link to="/my-course">{I18n.t("Profile")}</Link></li>
              </ul>
              <h2 className="inner-banner__title">{I18n.t("Profile")}</h2>
            </div>
          </section>
          <section className="inner-banner-responsive d-block d-sm-none">
            <div className="container">
              <Breadcrumb>
                <Breadcrumb.Item href="/">{I18n.t("Home")}</Breadcrumb.Item>
                <Breadcrumb.Item href="" active>
                  {I18n.t("Profile")}
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </section>
          <>
            <div class="container">
              <div class="main-body">
                <div class="row gutters-sm my-4">
                  <Leftbar menu={'profile'} />
                  <div class="col-md-9">
                    <div class="card mb-3">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-sm-3">
                            <h6 class="mb-0">{I18n.t("First Name")}</h6>
                          </div>
                          <div class="col-sm-9 text-secondary">
                            <input type="text" className="form-control"
                              placeholder={I18n.t("Your first name...")} value={first_name} onChange={e => setFirstName(e.target.value)} />
                          </div>
                        </div>
                        <hr />
                        <div class="row">
                          <div class="col-sm-3">
                            <h6 class="mb-0">{I18n.t("Last Name")}</h6>
                          </div>
                          <div class="col-sm-9 text-secondary">
                            <input type="text" className="form-control" placeholder={I18n.t('Your last name...')}
                              onChange={e => setLastName(e.target.value)}
                              value={last_name} />
                          </div>
                        </div>
                        <hr />
                        <div class="row">
                          <div class="col-sm-3">
                            <h6 class="mb-0">{I18n.t("Email")}</h6>
                          </div>
                          <div class="col-sm-9 text-secondary">
                            <input
                              readOnly={true}
                              type="email"
                              className="form-control"
                              value={email}
                              placeholder={I18n.t('Your email address...')}
                            />
                            <small className="form-text text-muted">
                              {I18n.t("Note that if you change your email, you will have to confirm it again.")}</small>
                          </div>
                        </div>
                        <hr />
                        <div class="row">
                          <div class="col-sm-3">
                            <h6 class="mb-0">{I18n.t("Phone")}</h6>
                          </div>
                          <div class="col-sm-9 text-secondary">
                            <input type="text" className="form-control" placeholder={I18n.t("Phone")}
                              value={phone_number}
                              onChange={e => setPhoneNumber(e.target.value)} />
                          </div>
                        </div>
                        <hr />
                        <div class="row">
                          <div class="col-sm-3">
                            <h6 class="mb-0">{I18n.t("Address")}</h6>
                          </div>
                          <div class="col-sm-9 text-secondary">
                            <input type="text" className="form-control" placeholder={I18n.t('Address')}
                              value={address}
                              onChange={e => setAddress(e.target.value)} />
                          </div>
                        </div>
                        <hr />
                        <div class="row">
                          <div class="col-sm-3">
                            <h6 class="mb-0">{I18n.t("Country")}</h6>
                          </div>
                          <div class="col-sm-9 text-secondary">
                            <select
                              className="form-control"
                              id="validationCustom04"
                              required=""
                              onChange={e => {
                                fetchStates(e.target.value)
                                setCountryId(e.target.value)
                              }}
                            >
                              <option >{I18n.t("Select Country")}</option>
                              {countries.map((country) =>
                                <option value={country.id} selected={(country.id === country_id) ? true : false}>{country.name}</option>
                              )}
                            </select>
                          </div>
                        </div>
                        <hr />
                        <div class="row">
                          <div class="col-sm-3">
                            <h6 class="mb-0">{I18n.t("State")}</h6>
                          </div>
                          <div class="col-sm-9 text-secondary">
                            <select className="form-control" id="validationCustom04"
                              onChange={e => {
                                fetchCity(e.target.value)
                                setStateId(e.target.value)
                              }}>
                              <option  >{I18n.t("Select State")}</option>
                              {states.map((state) =>
                                <option value={state.id} selected={(state.id === state_id) ? true : false}>{state.name}</option>
                              )}
                            </select>
                          </div>
                        </div>
                        <hr />
                        <div class="row">
                          <div class="col-sm-3">
                            <h6 class="mb-0">{I18n.t("City")}</h6>
                          </div>
                          <div class="col-sm-9 text-secondary">
                            <select className="form-control" id="validationCustom04"
                              onChange={e => {
                                setCityId(e.target.value)
                              }}>
                              <option >{I18n.t("Select City")}</option>
                              {cities.map((city) =>
                                <option value={city.id} selected={(city.id === city_id) ? true : false}>{city.name}</option>
                              )}
                            </select>
                          </div>
                        </div>
                        <hr />
                        <div class="row">
                          <div class="col-sm-3">
                            <h6 class="mb-0">{I18n.t("Timezone")}</h6>
                          </div>
                          <div class="col-sm-9 text-secondary">
                            <Select
                              name="timezone"
                              className=""
                              classNamePrefix="select"
                              options={timezone}
                              onChange={e => {
                                setTime(e.value)
                              }}
                              value={timezone.filter(({ value }) => value === time)}
                            />
                          </div>
                        </div>
                        <hr />
                        <div class="row">
                          <div class="col-sm-3">
                            <h6 class="mb-0">{I18n.t("Zip Code")}</h6>
                          </div>
                          <div class="col-sm-9 text-secondary">
                            {/* <input type="text" className="form-control" placeholder={I18n.t('Zip Code')}
                              onChange={e => setZipCode(e.target.value)}
                              value={zip_code} /> */}
                               <ReactInputMask
                              className="form-control"
                              mask={masked}
                              onChange={zipHandle}
                              // onChange={e => {
                              //   setCpf(e.target.value)
                              // handleChange}}
                              maskChar="_"
                              value={zip_code}
                              {...props}
                            />
                          </div>
                        </div>
                        <hr />
                        <div class="row">
                          <div class="col-sm-3">
                            <h6 class="mb-0">{I18n.t("CPF")}</h6>
                          </div>
                          <div class="col-sm-9 text-secondary">
                            {/* <input type="text" className="form-control" placeholder={I18n.t('CPF')}
                              onChange={e => setCpf(e.target.value)}
                              value={cpf} /> */}
                            <ReactInputMask
                              className="form-control"
                              mask={mask}
                              onChange={handleChange}
                              // onChange={e => {
                              //   setCpf(e.target.value)
                              // handleChange}}
                              maskChar="_"
                              value={cpf}
                              {...props}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="card mb-3">
                      <div class="card-body">
                        <div class="row">
                          <div class="col-sm-3">
                            <h6 class="mb-0">{I18n.t("Your photo")}</h6>
                          </div>
                          <div class="col-sm-9 text-secondary">
                            {/* {imageData !== null ? */}
                            <a href="" className="media-left mr-3">
                              <Image className="img-fluid" src={imageData} style={{ width: '80px', height: '80px' }} />
                            </a>
                            {/* :
                            <a href="" className="media-left mr-3">
                              <Image className="img-fluid" src={defaultImg} style={{ width: '80px', height: '80px' }} />
                            </a>} */}
                            <div className="media-body mt-2">
                              <div className="custom-file">
                                <input type="file" accept=".jpg,.jpeg,.png" name="profile_image" id="profile_picture" onChange={onSelectFile} />
                                <Modal size="lg" show={show} onHide={handleClose}>
                                  <Modal.Header closeButton>
                                    <Modal.Title>{I18n.t("Update Profile Picture")}</Modal.Title>
                                  </Modal.Header>
                                  <Modal.Body>

                                    <div className="row">
                                      <div className="col-md-6 mt-3">
                                        {(upImg) ? <>
                                          <div className="col-md-12 mt-3">
                                            <center>
                                              <ReactCrop
                                                src={upImg}
                                                onImageLoaded={onLoad}
                                                crop={crop}
                                                onChange={(c) => setCrop(c)}
                                                onComplete={(c) => setCompletedCrop(c)}
                                                className="react-crop-image"
                                              />
                                            </center>
                                          </div>
                                        </> : ""
                                        }
                                      </div>
                                      <div className="col-md-6 mt-3">
                                        {
                                          (upImg) ? <>
                                            <canvas
                                              ref={previewCanvasRef}
                                              style={{
                                                width: Math.round(completedCrop?.width ?? 0),
                                                height: Math.round(completedCrop?.height ?? 0)
                                              }}
                                            />
                                          </>
                                            : <>
                                            </>
                                        }
                                      </div>
                                    </div>

                                  </Modal.Body>
                                  <Modal.Footer>
                                    <button
                                      className="btn btn-warning mr-2"
                                      type="button"
                                      disabled={!completedCrop?.width || !completedCrop?.height}
                                      onClick={() =>
                                        generateDownload(previewCanvasRef.current, completedCrop)
                                      }
                                    >
                                      {I18n.t("Upload cropped image")}
                                    </button>
                                    <Button variant="secondary" onClick={handleClose}> {I18n.t("Close")} </Button>
                                  </Modal.Footer>
                                </Modal>
                                {/* <label className="custom-file-label" for="inputGroupFile01">Choose file</label> */}
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr />
                        <div class="row">
                          <div class="col-sm-3">
                            <h6 class="mb-0">{I18n.t("About You")}</h6>
                          </div>
                          <div class="col-sm-9 text-secondary">
                            <textarea rows="3" className="form-control" placeholder={I18n.t('About You')}
                              onChange={e => setRole(e.target.value)}
                              value={role_txt}></textarea>
                          </div>
                        </div>
                        <hr />
                        <div class="row">
                          <div class="col-sm-3">
                            <h6 class="mb-0">{I18n.t("Experience")}</h6>
                          </div>
                          <div class="col-sm-9 text-secondary">
                            <textarea rows="3" className="form-control" placeholder={I18n.t('Experience')}
                              onChange={e => setExperience(e.target.value)}
                              value={experience}></textarea>
                          </div>
                        </div>
                        <hr />
                        <div class="row">
                          <div class="col-sm-3">
                            <h6 class="mb-0">{I18n.t("Speciality")}</h6>
                          </div>
                          <div class="col-sm-9 text-secondary">
                            <textarea rows="3" className="form-control" placeholder={I18n.t('Speciality')}
                              onChange={e => setSpeciality(e.target.value)}
                              value={speciality_in}></textarea>
                          </div>
                        </div>
                        <hr />
                        <div class="row">
                          <div class="col-sm-3">
                            <h6 class="mb-0">Facebook</h6>
                          </div>
                          <div class="col-sm-9 text-secondary">
                            <input type="text" className="form-control" placeholder="Facebook"
                              onChange={e => setFacebook(e.target.value)}
                              value={facebook} />
                          </div>
                        </div>
                        <hr />
                        <div class="row">
                          <div class="col-sm-3">
                            <h6 class="mb-0">Twitter</h6>
                          </div>
                          <div class="col-sm-9 text-secondary">
                            <input type="text" className="form-control" placeholder="Twitter"
                              onChange={e => setTwitter(e.target.value)}
                              value={twitter} />
                          </div>
                        </div>
                        <hr />
                        <div class="row">
                          <div class="col-sm-3">
                            <h6 class="mb-0">Linkedin</h6>
                          </div>
                          <div class="col-sm-9 text-secondary">
                            <input type="text" className="form-control" placeholder="LinkedIn"
                              onChange={e => setLinkedin(e.target.value)}
                              value={linkedin} />
                          </div>
                        </div>
                        <hr />
                        <div class="row">
                          <div class="col-sm-3">
                            <h6 class="mb-0">Instagram</h6>
                          </div>
                          <div class="col-sm-9 text-secondary">
                            <input type="text" className="form-control" placeholder="Instagram"
                              onChange={e => setInstagram(e.target.value)}
                              value={instagram} />
                          </div>
                        </div>
                        <hr />

                        <div class="row">
                          <div class="col-sm-12" >
                            <button type="button" className="btn btn-primary mr-2"
                              onClick={StudentProfileUpdate} style={{ width: "200px" }}>{I18n.t("Update Profile")}</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        </div>
        <Footer />
      </body>
    </>
  )
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
