import React, { useState, useEffect } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { connect } from 'react-redux';
import { toggleLoader } from '../actions/loader';
import { Link } from 'react-router-dom';
import Topbar from '../components/Topbar';
import Footer from '../components/Footer';
import axios from "axios";
import config from "../config/config";
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import './style.css';
import Rating from './Rating';
import CourseAccordion from './CourseAccordion';
import ReactPlayer from 'react-player';
import parse from "html-react-parser";
import ReactPaginate from 'react-paginate';
import moment from 'moment';
import defaultImg from './images/index.jpeg';
import Avatar from 'react-avatar';
import myCourseD from './images/default-img.svg';

// import { StickyContainer, Sticky } from 'react-sticky';
import { Translate, I18n } from "react-redux-i18n";
import { CourseConsumption, topicViewSave, getRatings, getRatingsPercent, courseQuestions, crossSellAssign, displaySuccess, displayError } from '../Utils/utils';
// import Col from 'react-bootstrap/Col'
// import ReadMoreReact from 'read-more-react';
import ReactReadMoreReadLess from "react-read-more-read-less";
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;



const Course = (props) => {
  const [course, setCourse] = useState('');
  const [description, setDescription] = useState('');
  const [offer_benefit, setOfferBenefit] = useState([]);
  const [total_duration, setTotalDuration] = useState('');
  const [lessons, setLessons] = useState('');
  const [current_topic_title, setCurrrentTopicTitle] = useState('');
  const [topic_description, setTopicDescription] = useState('');
  const [my_rating, setMyRating] = useState(null);
  const [product, setProduct] = useState([]);
  const [url, setUrl] = useState('');
  const [url_type, setUrlType] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [path, setPath] = useState(config.siteUrl);
  const [course_ratings, setCourseRatings] = useState([]);

  const [hotmart_link, setHotmartLink] = useState(null);
  const [modalShow, setModalShow] = React.useState(false);
  const [average_rating, setAverageRating] = useState('');

  const [questions, setQuestions] = useState([]);
  const [current_page, setCurrentPage] = useState(1);
  const [temp_topic_id, setTempTopicId] = useState('');
  const [pageCount, setPageCount] = useState(1);
  const [search, setSearch] = useState('');

  const [question, setQuestion] = useState('');
  const [offer_id, setOfferId] = useState('');
  const [topic_id, setTopicId] = useState('');
  const [module_id, setModuleId] = useState('');
  const [cross_sell_assign, setCrossSellAssign] = useState('');
  const [cross_sell_id, setCrossSellId] = useState('');
  const [cross_sell_offer_id, setCrossSellOfferId] = useState('');
  const [cross_sell_slug, setCrossSellSlug] = useState('');
  const [html_desc, setHtmlDesc] = useState('');
  const [is_topic_watched, setTopicWatched] = useState('');
  const [topic_title, setTopicTitle] = useState('');
  const [imageState, SetImageState] = useState(0);
  const [is_allowed, setIsAllowed] = useState('');
  const [check_date, setCheckDate] = useState('');

  useEffect(async () => {
    props.toggleLoader(true)
    var course_consumption = await CourseConsumption(props.match.params.id, topic_id)
    if (course_consumption !== null) {

      setCourse(course_consumption.course);
      setDescription(course_consumption.description);
      setOfferBenefit(course_consumption.offer_benefit)
      setTotalDuration(course_consumption.total_duration);
      setLessons(course_consumption.lessons);
      setProduct(course_consumption.product);
      setMyRating(course_consumption.my_rating)
      // setIsAllowed(course_consumption.product.)
      setCheckDate(course_consumption.created_at);
      console.log(course_consumption.created_at)
      var thisProductId = 0;
      var thisModuleId = 0;
      var thisTopicId = 0;
      var thisInformationSet = 0;

      // if (course_consumption.product.length > 0) {
      //   course_consumption.product.map((product_course) => {
      //     thisProductId = product_course.id

      //     // return thisProductId;
      //     if (product_course.module.length > 0) {
      //       product_course.module.map((module_course) => {
      //         thisModuleId = module_course.id
      //         // return thisModuleId;
      //         if (module_course.topic.length > 0) {
      //           module_course.topic.map((topic_course) => {
      //             if (topic_course.topic_watched === null) {
      //               thisTopicId = topic_course.id;
      //               return thisTopicId;

      //             }
      //           })
      //         }
      //       })
      //     }
      //   });
      // }
      // console.log(thisProductId)
      // console.log(thisModuleId)
      // console.log(course_consumption)

      if (course_consumption.product.length > 0) {
        for (var i = 0; i < course_consumption.product.length; i++) {
          if (thisInformationSet == 1) { break; }
          let modulesLength = course_consumption.product[i].module.length;
          if (modulesLength > 0) {
            for (var j = 0; j < modulesLength; j++) {
              if (thisInformationSet == 1) { break; }
              let topicLength = course_consumption.product[i].module[j].topic.length;
              if (topicLength > 0) {
                for (var k = 0; k < topicLength; k++) {
                  let thisTopicInfo = course_consumption.product[i].module[j].topic[k];
                  if (thisTopicInfo.topic_watched == null) {
                    if (thisTopicInfo.is_allowed === true) {
                      thisProductId = i;
                      thisModuleId = j;
                      thisTopicId = k;
                      thisInformationSet = 1;
                      break;
                    }
                  }
                }
              }
            }
          }
        }
      }
      if (course_consumption.product[thisProductId]?.module.length === 0) {
        SetImageState(1);
      } else {
        if (course_consumption.product[thisProductId]?.module[thisModuleId]?.topic.length === 0) {
          SetImageState(2);
        } else {
          if (course_consumption.product[thisProductId]?.module[thisModuleId]?.topic[thisTopicId]?.video !== null && course_consumption.product[thisProductId]?.module[thisModuleId]?.topic[thisTopicId]?.video !== undefined) {
            setUrlType('video')
            setUrl(course_consumption.product[thisProductId]?.module[thisModuleId]?.topic[thisTopicId]?.video.url)
            setTopicId(course_consumption.product[thisProductId]?.module[thisModuleId]?.topic[thisTopicId].id);
            topicViewSave({
              offer_id: props.match.params.id,
              topic_id: course_consumption.product[thisProductId]?.module[thisModuleId]?.topic[thisTopicId].id
            });
            console.log(course_consumption.product[thisProductId]?.module[thisModuleId]?.topic[thisTopicId].id);
          }
          if (course_consumption.product[thisProductId]?.module[thisModuleId]?.topic[thisTopicId]?.pdf !== null && course_consumption.product[thisProductId]?.module[thisModuleId]?.topic[thisTopicId]?.pdf !== undefined) {
            setUrlType('pdf')
            setUrl(course_consumption.product[thisProductId]?.module[thisModuleId]?.topic[thisTopicId]?.pdf.media.url)
            setTopicId(course_consumption.product[thisProductId]?.module[thisModuleId]?.topic[thisTopicId].id);
            topicViewSave({
              offer_id: props.match.params.id,
              topic_id: course_consumption.product[thisProductId]?.module[thisModuleId]?.topic[thisTopicId].id
            });

          }
          if (course_consumption.product[thisProductId]?.module[thisModuleId]?.topic[thisTopicId] !== null && course_consumption.product[thisProductId]?.module[thisModuleId]?.topic[thisTopicId] !== undefined) {
            setCurrrentTopicTitle(course_consumption.product[thisProductId]?.module[thisModuleId]?.topic[thisTopicId].name)

            var temp_text_content = course_consumption.product[thisProductId]?.module[thisModuleId]?.topic[thisTopicId].text_content;
            if (temp_text_content !== null || temp_text_content !== '') {
              temp_text_content = temp_text_content.replace("<a ", "<a target='_new' ")
            }
            setTopicDescription((temp_text_content))
            setTopicId(course_consumption.product[thisProductId]?.module[thisModuleId]?.topic[thisTopicId].id);
            topicViewSave({
              offer_id: props.match.params.id,
              topic_id: course_consumption.product[thisProductId]?.module[thisModuleId]?.topic[thisTopicId].id
            });
            var temp_topic_title = course_consumption.product[thisProductId]?.module[thisModuleId]?.topic[thisTopicId].name;
            setTopicTitle(temp_topic_title);
            var ques_var = await courseQuestions(props.match.params.id, course_consumption.product[thisProductId]?.module[thisModuleId]?.topic[thisTopicId].id, current_page);
            setQuestions(ques_var.data);
            setPageCount(ques_var.last_page);

            props.toggleLoader(false)
          }
          /// getting ratings the very first time
          var getratings = await getRatings(props.match.params.id, course_consumption.product[thisProductId]?.module[thisModuleId]?.topic[thisTopicId].id);
          setCourseRatings(getratings.data);
          var getratings_percent = await getRatingsPercent(props.match.params.id, course_consumption.product[thisProductId]?.module[thisModuleId]?.topic[thisTopicId].id);
          setAverageRating(getratings_percent.average_rating);
          //ratings ends

          // to fetch cross sell
          var cross_sell_assign_var = await crossSellAssign(props.match.params.id, course_consumption.product[thisProductId]?.module[0].id, course_consumption.product[thisProductId]?.module[thisModuleId]?.topic[thisTopicId].id)

          if (cross_sell_assign_var != null) {
            setCrossSellAssign(cross_sell_assign_var.cross_sell.media.url)
            setCrossSellId(cross_sell_assign_var.cross_sell_id);
            setCrossSellOfferId(cross_sell_assign_var.cross_sell.offer_id);
            setCrossSellSlug(cross_sell_assign_var.cross_sell.offer.slug);
          } else {
            setCrossSellAssign('');
          }
        }
      }


      // if (course_consumption.product[thisProductId]?.module[thisModuleId]?.topic[thisTopicId]?.video !== null && course_consumption.product[thisProductId]?.module[thisModuleId]?.topic[thisTopicId]?.video !== undefined) {
      //   setUrlType('video')
      //   setUrl(course_consumption.product[thisProductId]?.module[thisModuleId]?.topic[thisTopicId]?.video.url)
      //   setTopicId(course_consumption.product[thisProductId]?.module[thisModuleId]?.topic[thisTopicId].id);
      //   topicViewSave({
      //     offer_id: props.match.params.id,
      //     topic_id: course_consumption.product[thisProductId]?.module[thisModuleId]?.topic[thisTopicId].id
      //   });
      //   console.log(course_consumption.product[thisProductId]?.module[thisModuleId]?.topic[thisTopicId].id);
      // }

      // if (course_consumption.product[thisProductId]?.module[thisModuleId]?.topic[thisTopicId]?.pdf !== null && course_consumption.product[thisProductId]?.module[thisModuleId]?.topic[thisTopicId]?.pdf !== undefined) {
      //   setUrlType('pdf')
      //   setUrl(course_consumption.product[thisProductId]?.module[thisModuleId]?.topic[thisTopicId]?.pdf.media.url)
      //   setTopicId(course_consumption.product[thisProductId]?.module[thisModuleId]?.topic[thisTopicId].id);
      //   topicViewSave({
      //     offer_id: props.match.params.id,
      //     topic_id: course_consumption.product[thisProductId]?.module[thisModuleId]?.topic[thisTopicId].id
      //   });

      // }
      // if (course_consumption.product[thisProductId]?.module[thisModuleId]?.topic[thisTopicId] !== null && course_consumption.product[thisProductId]?.module[thisModuleId]?.topic[thisTopicId] !== undefined) {
      //   setCurrrentTopicTitle(course_consumption.product[thisProductId]?.module[thisModuleId]?.topic[thisTopicId].name)

      //   var temp_text_content = course_consumption.product[thisProductId]?.module[thisModuleId]?.topic[thisTopicId].text_content;
      //   if (temp_text_content !== null || temp_text_content !== '') {
      //     temp_text_content = temp_text_content.replace("<a ", "<a target='_new' ")
      //   }
      //   setTopicDescription((temp_text_content))
      //   setTopicId(course_consumption.product[thisProductId]?.module[thisModuleId]?.topic[thisTopicId].id);
      //   topicViewSave({
      //     offer_id: props.match.params.id,
      //     topic_id: course_consumption.product[thisProductId]?.module[thisModuleId]?.topic[thisTopicId].id
      //   });
      //   var temp_topic_title = course_consumption.product[thisProductId]?.module[thisModuleId]?.topic[thisTopicId].name;
      //   setTopicTitle(temp_topic_title);
      //   var ques_var = await courseQuestions(props.match.params.id, course_consumption.product[thisProductId]?.module[thisModuleId]?.topic[thisTopicId].id, current_page);
      //   setQuestions(ques_var.data);
      //   setPageCount(ques_var.last_page);

      //   props.toggleLoader(false)
      // }

      // /// getting ratings the very first time
      // var getratings = await getRatings(props.match.params.id, course_consumption.product[thisProductId]?.module[thisModuleId]?.topic[thisTopicId].id);
      // setCourseRatings(getratings.data);
      // var getratings_percent = await getRatingsPercent(props.match.params.id, course_consumption.product[thisProductId]?.module[thisModuleId]?.topic[thisTopicId].id);
      // setAverageRating(getratings_percent.average_rating);
      // //ratings ends

      // // to fetch cross sell
      // var cross_sell_assign_var = await crossSellAssign(props.match.params.id, course_consumption.product[thisProductId]?.module[0].id, course_consumption.product[thisProductId]?.module[thisModuleId]?.topic[thisTopicId].id)

      // if (cross_sell_assign_var != null) {
      //   setCrossSellAssign(cross_sell_assign_var.cross_sell.media.url)
      //   setCrossSellId(cross_sell_assign_var.cross_sell_id);
      //   setCrossSellOfferId(cross_sell_assign_var.cross_sell.offer_id);
      //   setCrossSellSlug(cross_sell_assign_var.cross_sell.offer.slug);
      // } else {
      //   setCrossSellAssign('');
      // }
      // setHtmlDesc(parse(topic_description));
      // console.log(html_desc)
    }
    props.toggleLoader(false)
  }, [])
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  }
  const playVideo = async (url_received, type, topic_name, text_content, module_id, id) => {

    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    setUrlType(type)
    setUrl(url_received)
    //alert(text_content)
    if (text_content !== null) {
      text_content = text_content.replace("<a ", "<a target='_new' ")
    }
    //alert(text_content)
    if (text_content === null) {
      text_content = "";
    }
    setTopicTitle(topic_name)
    setTopicDescription((text_content))
    setCurrrentTopicTitle(topic_name)
    setTopicId(id);
    await topicViewSave({
      offer_id: props.match.params.id,
      topic_id: id
    });

    // to fetch cross sell
    var cross_sell_assign_var = await crossSellAssign(props.match.params.id, module_id, id)
    if (cross_sell_assign_var != null) {
      setCrossSellAssign(cross_sell_assign_var.cross_sell.media.url)
      setCrossSellId(cross_sell_assign_var.cross_sell_id);
      setCrossSellOfferId(cross_sell_assign_var.cross_sell.offer_id);
      setCrossSellSlug(cross_sell_assign_var.cross_sell.offer.slug);
    } else {
      setCrossSellAssign('');
    }

    var course_consumption = await CourseConsumption(props.match.params.id)
    setProduct(course_consumption.product);
    setSearch("");

    var ques_var = await courseQuestions(props.match.params.id, id, current_page);
    setQuestions(ques_var.data);
    setPageCount(ques_var.last_page);

    var getratings = await getRatings(props.match.params.id, id);
    setCourseRatings(getratings.data);

    var getratings_percent = await getRatingsPercent(props.match.params.id, id);
    setAverageRating(getratings_percent.average_rating)
  }
  const printStars = (avg_star) => {
    var print_html = [];
    for (var i = 1; i <= 5; i++) {
      if (avg_star >= i) {
        print_html.push(<i className="icofont-star text-warning"></i>);
      } else {
        print_html.push(<i className="icofont-star rate-select " style={{ color: '#eee' }}></i>);
      }
    }
    return print_html;
  }
  const reloadRating = async (id) => {
    var course_consumption = await CourseConsumption(props.match.params.id)
    setProduct(course_consumption.product);
    // setMyRating(course_consumption.my_rating)

    var getratings = await getRatings(props.match.params.id, id);
    setCourseRatings(getratings.data);

    var getratings_percent = await getRatingsPercent(props.match.params.id, id);
    setAverageRating(getratings_percent.average_rating)
  }
  const searchFilter = async (e) => {
    var ques_var = await courseQuestions(props.match.params.id, topic_id, current_page, e)
    setQuestions(ques_var.data)
    setTempTopicId(ques_var.topic_id);
    setPageCount(ques_var.last_page)
  }
  const searchFilterNoLoader = async (e) => {
    var ques_var = await courseQuestions(props.match.params.id, topic_id, current_page, e)
    if (ques_var !== null) {
      setQuestions(ques_var.data)
      setPageCount(ques_var.last_page)
      console.log(ques_var.data)
    }

    // setSearch("");
  }
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const addQuestion = () => {
    var token = localStorage.getItem('token');
    props.toggleLoader(true);
    let path = config.siteUrl;
    axios.post(path + '/student/course/addquestion', {
      question: question,
      offer_id: props.match.params.id,
      topic_id: topic_id
    }, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    })
      .then(async (response) => {
        props.toggleLoader(false);
        displaySuccess(I18n.t('Question Added Successfully'));
        var ques_var = await courseQuestions(props.match.params.id, topic_id, current_page);
        setQuestions(ques_var.data);
        setPageCount(ques_var.last_page);
        // setQuestion('');
        setSearch("");
      })
      .catch(function (error) {
        props.toggleLoader(false);
        if (error.response.status === 401) {
          window.location.href = '/';
        }
        displayError(error);
      });
  }
  const handlePageClick = async (e) => {
    // window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    const selectedPage = e.selected + 1;
    var ques_var = await courseQuestions(props.match.params.id, topic_id, selectedPage);
    setPageCount(ques_var.last_page)
    setQuestions(ques_var.data);
  };
  const parsed_topic_description = () => {
    setHtmlDesc(parse(topic_description));
    // console.log()
  }
  return (
    <>
      <body id="page-top">
        <div className="page-wrapper">

          <Topbar />
          {/* <InnerBanner/> */}
          {/* <section className="inner-banner d-none d-sm-block">
            <div className="container">
              <ul className="list-unstyled thm-breadcrumb">
                <li><Link to="/">Home</Link></li>
                <li className="active"><Link to="/my-course">Course</Link></li>
              </ul>
              <h2 className="inner-banner__title">Course</h2>
            </div>
          </section> */}
          <section className="inner-banner-responsive d-block d-sm-none">
            <div className="container">
              <Breadcrumb>
                <Breadcrumb.Item href="/">{I18n.t("Home")}</Breadcrumb.Item>
                <Breadcrumb.Item href="" active>
                  {I18n.t("Course")}
                </Breadcrumb.Item>
              </Breadcrumb>
            </div >
          </section >
          <>

            <main>
              <div className="main-container-hosted-login container mob-white">
                <div className="row">{/*  main-row disqus-row  */}
                  <div className="col-lg-5 col-xl-5">
                    <div className='navbar-desc sidebar position-sticky' Sticky="top">
                      <div>
                        <h1 className='course-title'> {course}</h1>
                      </div>
                      <div>
                        <div className="navbar-desc mb-5">
                          <div className="course-nav-title">
                            <h2 className='class-content'>{I18n.t('class content')}</h2>
                            <h4 className="class-content-detail">
                              {lessons} | {I18n.t('Total duration')} {total_duration}
                            </h4>
                          </div>
                          <div className="content-drawer">
                            <CourseAccordion
                              product={product}
                              playVideo={(e, type, topic_name, text_content, module_id, id) => playVideo(e, type, topic_name, text_content, module_id, id)}
                              offer_id={props.match.params.id}
                              onRatingGiven={(id) => reloadRating(id)}
                              // topic_id={topic_id}
                              playing_url={url}
                              my_rating={my_rating}
                              key={my_rating}
                              check_date={check_date}
                            // is_allowed={is_allowed}
                            // key={url}
                            />

                          </div>
                        </div >
                      </div >
                    </div >
                    <div class="d-flex justify-content-center">
                      <div class="content text-center">
                        <div class="ratings">
                          <h6 className='avg-rate'>{I18n.t("Average Rating of this topic")}</h6>
                          <span class="product-rating">{imageState === 1 || imageState === 2 ? "0" : average_rating !== null ? parseFloat(average_rating).toFixed(1) : "0"}
                          </span><span>/5</span>
                          <div className="review-star">
                            {printStars(average_rating)}
                          </div>
                          <div class="rating-text">
                            <span>{course_ratings.length} {I18n.t("Rating")}</span>
                          </div>
                          {course_ratings.map((rating, index) => (
                            <div className="row mt-3" style={{ paddingBottom: '15px' }}>
                              <div className="col-auto">
                                <div className=''>
                                  <img className='user_review_consumption' src={(rating.user.media !== null)
                                    ? rating.user.media.url : defaultImg} />
                                  {/* <img src={team1} style={{ maxWidth: '100%', borderRadius: '30px' }} /> */}
                                </div>
                              </div>
                              <div className="col-auto align-self-center">
                                <div className="user-name text-left">
                                  <h6>{rating.user.first_name + ' ' + rating.user.last_name}</h6>
                                </div><br />
                                <div class="stars-rev">
                                  {printStars(rating.rating)}
                                  <data style={{ marginLeft: '10px' }}>
                                    {moment(rating.user.created_at).format('DD/MM/YYYY')}
                                  </data>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div >
                  <div className="col-lg-7 col-xl-7" style={{ paddingTop: '40px' }}>
                    <div className="navbar-desc">
                      {topic_title}
                      <div className="course-video">
                        {/* <ReactPlayer
                          url={"https://www.youtube.com/watch?v=F18Rxw6ftcM&feature=youtu.be"}
                          key={url}
                          width="100%"
                          controls={true}
                          playing={true}
                          className="h-450"
                        /> */}
                        {imageState === 1 ?
                          <div className="default_img">
                            <img src={myCourseD} className="img-fluid" />
                            <h5 className="">{I18n.t('No Modules')} !</h5>
                          </div>
                          : imageState === 2 ?
                            <div className="default_img">
                              <img src={myCourseD} className="img-fluid" />
                              <h5 className="">{I18n.t('No Topics')} !</h5>
                            </div>
                            :
                            <>
                              {(url_type === 'video') ? <div className="course-css "><ReactPlayer
                                url={url}
                                key={url}
                                // width={"100%"}
                                // height={"100%"}
                                controls={true}
                                playing={false}
                                config={{
                                  file: {
                                    attributes: {
                                      onContextMenu: e => e.preventDefault(),
                                      controlsList: 'nodownload'
                                    }
                                  }
                                }}
                              // className="h-450"
                              /></div> : ''}
                              {
                                (url_type === 'pdf') ? <>
                                  <div className="course-css "><Document
                                    className="custom_pdf_document_row"
                                    file={path + '/pdfbase64?url=' + url}
                                    onLoadSuccess={onDocumentLoadSuccess}
                                  >
                                    <Page className="custom_pdf_page_row" pageNumber={pageNumber} width={500} />
                                  </Document>
                                    <div className="row">
                                      <div className="col-md-6 col-xs-6 mt-2 mb-2">
                                        <p>{I18n.t("Page")} {pageNumber} of {numPages}</p>
                                      </div>
                                      <div className="col-md-6 col-xs-6 mt-2 mb-2">
                                        <div>
                                          <i onClick={e => {
                                            var temp_page_numer = pageNumber;
                                            setPageNumber(--temp_page_numer)
                                          }} class="fas fa-chevron-circle-left pointer"></i>
                                          <i onClick={e => {
                                            var temp_page_numer = pageNumber;
                                            setPageNumber(++temp_page_numer)
                                          }} class="fas fa-chevron-circle-right pointer"></i>
                                        </div>
                                      </div>
                                    </div></div>
                                </> : ''
                              }
                              {url_type === '' || url_type === null ?
                                <div  >
                                  {(topic_description !== '' || topic_description !== null) ?
                                    <div className="desc-rect">
                                      <div className="desc-title">{I18n.t("Topic Description")}</div>
                                      <div className="desc-content">
                                        <p >
                                          {parse(topic_description)}
                                        </p>
                                      </div>
                                    </div> : ''}

                                </div> : ''}
                            </>
                        }



                      </div>

                      {(cross_sell_assign !== '') ? <>
                        <section className="erson cardp">
                          <h2 className="person__main-title mb-3">
                            {current_topic_title}
                          </h2>
                          <div className="person__wrap d-flex">
                            <Link to={"/checkout/" + cross_sell_offer_id + "/" + cross_sell_slug + "?cross_sell_id=" + cross_sell_id}>
                              <img src={cross_sell_assign} style={{ maxWidth: '100%' }} />
                            </Link>
                          </div>
                        </section>
                      </>
                        : ""}
                      {url_type === '' || url_type === null ? "" :

                        <div  >
                          {(topic_description !== '' || topic_description !== null) ?
                            <div className="desc-rect">
                              <div className="desc-title">{I18n.t("Topic Description")}</div>
                              <div className="desc-content">
                                <p >
                                  {/* <ReadMoreReact text={parse(topic_description)}
                                      min={80}
                                      ideal={100}
                                      max={200} /> */}
                                  {/* <ReactReadMoreReadLess
                                    charLimit={300}
                                    readMoreText={"Read more ▼"}
                                    readLessText={"Read less ▲"}
                                    children={''}
                                  >
                                    {topic_description}
                                  </ReactReadMoreReadLess> */}
                                  {parse(topic_description)}
                                </p>
                              </div>
                            </div> : ''}
                        </div>}
                    </div>

                  </div>
                </div >

                <div className='row'>
                  <div className='col-md-12'>
                    <div className='ques-part'>
                      <div class="top-title">
                        <h3 class="mb-0">{I18n.t("Answer Center")}</h3>
                        <span class="ans-sub-head">{I18n.t("For doubts, search here")} :</span>
                      </div>
                      <div class="search-box">
                        <div class="search-css">
                          <input type="text" class="form-control" value={search} onChange={e => {
                            setSearch(e.target.value)
                            setQuestion(e.target.value)
                            searchFilterNoLoader(e.target.value)
                          }} />
                        </div>
                        <div class="search-button">
                          {(questions.length === 0) ?
                            <button type="button" class="text-ellipsis btn ques-box" onClick={e => {
                              addQuestion()
                            }}>{I18n.t("Add Question")}</button>
                            : <button type="button" class="text-ellipsis btn btn-primary" >{I18n.t("Search")} </button>}
                        </div>
                      </div>
                      <br />
                      <div className='row no-gutters'>
                        {questions.map((ques, index) => (

                          <div className='col-xl-4 col-md-6 mt-2'>
                            <div class="ques-css">
                              <h5>  Q{index + 1}. {ques.question}</h5>
                              <div class="row">
                                <div class="col-auto">
                                  <div class="ques-image">
                                    {ques.user.media !== null ?
                                      <img src={ques.user.media.url} style={{ maxWidth: '100%', borderRadius: '30px' }} />
                                      : <Avatar name={ques.user.first_name} size="40" textSizeRatio={1.75} round="60px" />}
                                  </div>
                                </div>
                                <div class="col-md-9 col-lg-10 col-9 pl-0">
                                  <div class="name-css">{I18n.t("By")} {ques.user.first_name} {ques.user.last_name}</div>
                                  <div class="ques-date">{I18n.t("Created on")}  {moment(ques.user.created_at).format('DD/MM/YYYY')}</div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col">
                                  <div class="ques-box">
                                    {/* <div class="ques-box-head">{parse !== '' ? (ques.answer) : ''}</div> */}
                                    <div class="ques-box-head">{ques.answer}</div>
                                  </div>
                                </div>
                              </div>
                              <br />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div >

            </main >
            <Rating
              show={modalShow}
              onHide={() => setModalShow(false)}
              onRatingGiven={(id) => reloadRating(id)}
              offer_id={props.match.params.id}
              topic_id={topic_id}
              my_rating={my_rating}
              key={my_rating}
            />
            <div className="row no-gutters mb-5">
              <div className="mx-auto d-block">
                <ReactPaginate
                  previousLabel={I18n.t("prev")}
                  nextLabel={I18n.t("next")}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={pageCount}
                  marginPagesDisplayed={0}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              </div>
            </div>
          </>
        </div >
        <Footer />
      </body >
    </>
  );
}

const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Course);
