import React from 'react';
import Loader from 'react-loader-spinner'
import { connect } from 'react-redux';

const LoadingSpinner = (props) => {
  return (
    <div className={(props.loader.isLoading) ? 'loader_block' : 'loader_block hide-display'}>
      <span>
        <Loader
          type="Bars"
          color=" #26C9D3"
          height={100}
          width={100}
          visible={props.loader.isLoading}
        />
      </span>
    </div>
  )
};
const mapStateToProps = (state) => {
  return {
    loader: state.loader,
  }
}
export default connect(mapStateToProps)(LoadingSpinner);