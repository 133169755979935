import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import axios from "axios";
import { toggleLoader } from '../actions/loader';
import Topbar from '../components/Topbar';
import Footer from '../components/Footer';
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import { Link } from 'react-router-dom';
import Leftbar from '../components/Leftbar';
import Alert from 'react-bootstrap/Alert'
import ReactPaginate from 'react-paginate';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import { liveEventsListing, pastLiveEvents, getUserProfile } from '../Utils/utils';
import { Translate, I18n } from "react-redux-i18n";

const LiveEvent = (props) => {
  const [events_list, setEventsList] = useState([]);
  const [current_page, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [count, setCount] = useState(0);
  const [pastEvent, setPastEvent] = useState([]);
  const [zoom_id, setZoomId] = useState('');
  const [zoom_pwd, setZoomPwd] = useState('');
  const [first_name, setFirstName] = useState('User');
  const [last_name, setLastName] = useState('');

  const MINUTE_MS = 60000;

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  useEffect(async () => {
    props.toggleLoader(true);
    var get_events_list = await liveEventsListing(current_page);
    setEventsList(get_events_list.data);
    setPageCount(get_events_list.last_page);
    // setTimeout(() => window.location.href = "/live-events", 60000);



    props.toggleLoader(false);
  }, []);


  useEffect(async () => {

    var getProfile_var = await getUserProfile();
    if (getProfile_var !== null) {
      setFirstName(getProfile_var.first_name);
      setLastName(getProfile_var.last_name);
    }

    const interval = setInterval(async () => {
      var get_events_list = await liveEventsListing(current_page);
      setPageCount(get_events_list.last_page)
      setEventsList(get_events_list.data);
    }, MINUTE_MS);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [])

  const handlePageClick = async (e) => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    const selectedPage = e.selected + 1;
    props.toggleLoader(true);
    var get_events_list = await liveEventsListing(selectedPage);
    setPageCount(get_events_list.last_page)
    setEventsList(get_events_list.data);
    props.toggleLoader(false);
  };
  useEffect(async () => {
    props.toggleLoader(true);
    var get_past_events = await pastLiveEvents(current_page);
    setPastEvent(get_past_events.data);
    setPageCount(get_past_events.last_page);
    // setTimeout(() => window.location.href = "/live-events", 60000);
    props.toggleLoader(false);
  }, []);

  // useEffect(() => {
  //   let urlString = "https://www.example.com/j/83746815830?pwd=cXRkVWJMcjlpb0h5TWlUVVZHK2MwZz09";
  //   let paramString = urlString.split('?');
  //   let params_arr = paramString[1].split('=')[1];
  //   let meeting_arr = (paramString[0].split("/")).pop();
  // }, [])
  const zoom = (link, slug) => {
    let urlString = link;
    let paramString = urlString.split('?');
    let params_arr = paramString[1].split('=')[1];
    let meeting_arr = (paramString[0].split("/")).pop();
    let name = first_name.toLowerCase() + "-" + last_name.toLowerCase();
    var language = localStorage.getItem('langauge_selected');
    if (language !== undefined && language !== '') {
      var finalLang = language;
    } else {
      var finalLang = "pt";
    }

    let customURL = "https://meeting.dutraforeducation.com.br/" + meeting_arr + "/" + params_arr + "/" + name + "/" + finalLang;
    window.open(customURL, "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=50,left=50");

  }



  return (
    <>
      <body id="page-top">
        <div className="page-wrapper">

          <Topbar />
          {/* <InnerBanner/> */}
          <section className="inner-banner d-none d-sm-block">
            <div className="container">
              <ul className="list-unstyled thm-breadcrumb">
                <li><Link to="/"> {I18n.t("Home")}</Link></li>
                <li className="active"><Link to="/my-course">{I18n.t("Live Events")}</Link></li>
              </ul>
              <h2 className="inner-banner__title"> {I18n.t("Live Events")}</h2>
            </div>
          </section>
          <section className="inner-banner-responsive d-block d-sm-none">
            <div className="container">
              <Breadcrumb>
                <Breadcrumb.Item href="/"> {I18n.t("Home")}</Breadcrumb.Item>
                <Breadcrumb.Item href="" active>
                  {I18n.t("Live Events")}
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </section>
          <>
            <div class="container">
              <div class="main-body">
                <div class="row gutters-sm my-4">
                  <Leftbar menu={'live'} />
                  <div class="col-md-9">
                    <div className="page-section mt-2 mt-lg-0">
                      <div className="page__container">
                        <div className="col-12">
                          <Tabs defaultActiveKey="Upcoming" transition={false} id="noanim-tab-example"
                            style={{ backgroundColor: ' #eaf9f6' }} >
                            <Tab eventKey="Upcoming" title={I18n.t("Upcoming")} style={{ border: '1px solid #eee' }}>
                              <div className="row" >
                                {(events_list.length === 0) ?
                                  <div className="col-md-12" style={{ textAlign: 'center', width: '100%' }}>
                                    <Alert key={'alert'} > {/* variant={'info'} */}
                                      <img src='img/no-event.png' style={{ textAlign: 'center', width: '150px' }} />
                                      <h5>   {I18n.t("No Upcoming Events")}</h5>
                                    </Alert>
                                  </div>
                                  : ''}
                                {events_list.map((event) => (
                                  <div className="col-md-6 col-lg-4 mb-3">
                                    <div className="card shadow h-100">
                                      <img src={event.media.url} alt="img" className="w-100" style={{ height: '180px', objectFit: 'cover' }} />
                                      <div className="card-body">
                                        <h4 className="card-title mb-1 mx-2Line" style={{ fontSize: '20px' }}>{event.title}</h4>
                                        <div className="d-flex user-area justify-content-center mt-3 pb-0">
                                          {event.is_live === true ?
                                            <>
                                              {event.embed_link !== null ?
                                                <a className="btn-outline-danger btn btn-live_event px-3"
                                                  onClick={() => zoom(event.embed_link, event.id)}
                                                >
                                                  <i className="icofont-focus"></i> {I18n.t(event.live_in_days)}
                                                </a>
                                                :
                                                <Link to={"/course/liveevent/" + event.id}> <a className="btn-outline-danger btn btn-live_event px-3">
                                                  <i className="icofont-focus"></i> {I18n.t(event.live_in_days)}</a>3
                                                </Link>
                                              }
                                            </>
                                            :
                                            <a className="btn btn-live_event px-3">
                                              <i className="icofont-focus"></i>{I18n.t(event.live_in_days)} </a>
                                          }
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>

                            </Tab>
                            <Tab eventKey="Past Events" title={I18n.t("Past Events")} style={{ border: '1px solid #eee', padding: '15px' }}>
                              <div className="row mt-3">
                                {(pastEvent.length === 0) ?
                                  <div className="col-md-12" style={{ textAlign: 'center', width: '100%' }}>
                                    <Alert key={'alert'} >{/* variant={'info'} */}
                                      <img src='img/no-event.png' style={{ textAlign: 'center', width: '150px' }} />
                                      <h5>   {I18n.t("No Past Events")}</h5>

                                    </Alert>
                                  </div>
                                  : ''}
                                {pastEvent.map((event) => (
                                  <div className="col-md-4 mb-3">
                                    <div className="card shadow-sm h-100" >
                                      <img src={event.media.url} alt="img" className="w-100" style={{ height: '180px', objectFit: 'cover', borderRadius: '2px' }} />
                                      <div className="card-body">
                                        <h4 className="card-title mb-1 mx-2Line" style={{ fontSize: '20px' }}>{event.title}</h4>
                                        <div className="d-flex user-area justify-content-center mt-3 pb-0">
                                          {event.is_live === true ?
                                            // <Link to={"/course/liveevent/" + event.id}> 
                                            <a className="btn-outline-danger px-2 border-radius-3">
                                              <i className="icofont-focus"></i> {I18n.t(event.live_in_days)}
                                            </a>
                                            // </Link>
                                            :
                                            <a className="btn-outline-info px-2 border-radius-3">
                                              <i className="icofont-focus"></i> {I18n.t(event.live_in_days)}
                                            </a>
                                          }
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </Tab>
                          </Tabs>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        </div>
        <Footer />
      </body>
    </>
  )
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(LiveEvent);
