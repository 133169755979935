import React , { useState ,useEffect} from 'react';
import Topbar from '../components/Topbar';
import Carousel from '../components/Carousel';
import CourseCard from '../components/CourseCard';
import FreeRegister from '../components/FreeRegister';
import TopCategories from '../components/TopCategories';
import MeetTeachers from '../components/MeetTeachers';
import Footer from '../components/Footer';
// import HappyStudent from '../components/HappyStudent';
import TopNav from '../components/TopNav';
import CourseResponsive from '../components/CourseResponsive';
import CategoriesResponsive from '../components/CategoriesResponsive';
import { connect } from 'react-redux';
import axios from "axios";
import { toggleLoader } from '../actions/loader';
import { Translate, I18n } from "react-redux-i18n";

const Dashboard = (props) => {
const [reset_token, setResetToken]=useState('')
const [temp_show, setTempShow]=useState(false);

  useEffect(()=>{
    console.log(props.match.params)
    if(props.match.params.token !==''  ){
      // var reset_token = props.match.params.token;
      setResetToken(props.match.params.token)
      
    }
  },[])

const handleShowLogin = (e) => {
  setTempShow(e)
};

  return (
    <>
      <body id="page-top">
        {/* <TopNav /> */}
        <div className="page-wrapper">

          <Topbar reset_token={reset_token} handleShowLogin={e=>handleShowLogin(e)} temp_show={temp_show} />
          <div>
            <Carousel 
            handleShowLogin={e=>handleShowLogin(e)} />
            <section className="course-one__top-title home-one">
              <div className="container">
                <div className="block-title mb-0">
                  <h2 className="block-title__title">{I18n.t("Explore our")}
                    <br />
                    {I18n.t("Popular Courses")}</h2>
                </div>
              </div>
            </section>
            <CourseCard />
            <CourseResponsive/>
            <FreeRegister />
            <TopCategories />
            <CategoriesResponsive/>
            {/* <HappyStudent /> */}
            <MeetTeachers />
          </div>
          <Footer />
        </div>
      </body>
    </>
  )
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
