import React, { useState, useEffect } from 'react';
import Topbar from '../components/Topbar';
import Footer from '../components/Footer';
import { connect } from 'react-redux';
import axios from "axios";
import { toggleLoader } from '../actions/loader';
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import { Link } from 'react-router-dom';
import Leftbar from '../components/Leftbar';
import { getNotification, notificationRead } from '../Utils/utils';
import ReactPaginate from 'react-paginate';
import moment from 'moment';
import './style.css';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import Alert from 'react-bootstrap/Alert'
import { Translate, I18n } from "react-redux-i18n";

const Notifications = (props) => {
  const [notification_list, setNotificationList] = useState([]);
  const [current_page, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [notification_read, setNotificationRead] = useState('');

  useEffect(async () => {
    props.toggleLoader(true);
    var get_noti_var = await getNotification(current_page);
    if (get_noti_var !== null) {
      setNotificationList(get_noti_var.data);
      setPageCount(get_noti_var.last_page);
      console.log(get_noti_var.data)
    }
    props.toggleLoader(false);
  }, [])

  const handlePageClick = async (e) => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    const selectedPage = e.selected + 1;
    props.toggleLoader(true);
    var get_noti_var = await getNotification(selectedPage);
    setNotificationList(get_noti_var.data);
    setPageCount(get_noti_var.last_page);
    props.toggleLoader(false);
  };
  useEffect(async () => {
    props.toggleLoader(true);
    var get_noti_var = await notificationRead();
    setNotificationRead(get_noti_var)
    props.toggleLoader(false);
  }, [])

  return (
    <>
      <body id="page-top">
        <div className="page-wrapper">

          <Topbar />
          {/* <InnerBanner/> */}
          <section className="inner-banner d-none d-sm-block">
            <div className="container">
              <ul className="list-unstyled thm-breadcrumb">
                <li><Link to="/">{I18n.t("Home")}</Link></li>
                <li className="active"><Link to="/my-course">{I18n.t("Notification")}</Link></li>
              </ul>
              <h2 className="inner-banner__title">{I18n.t("Notification")}</h2>
            </div>
          </section>
          <section className="inner-banner-responsive d-block d-sm-none">
            <div className="container">
              <Breadcrumb>
                <Breadcrumb.Item href="/">{I18n.t("Home")}</Breadcrumb.Item>
                <Breadcrumb.Item href="" active>
                  {I18n.t("Notification")}
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </section>
          <>
            <div className="container">
              <div className="">
                <div className="row gutters-sm my-4">
                  <Leftbar menu={'notification'} />

                  <div className="col-lg-9 right">
                    <div className="page-section mt-2 mt-lg-0">
                      {(notification_list.length === 0) ? <Tr>
                        <Td colspan="12" style={{ textAlign: 'center' }}>
                          <Alert key={'alert'} variant={'info'}>
                            {I18n.t('No Notifications')}
                          </Alert>
                        </Td>
                      </Tr> : ''}
                      {notification_list.map((list) => (
                        <div className="box-body p-0">
                          <div className="p-3 d-flex align-items-center border-bottom osahan-post-header">
                            <div className="dropdown-list-image mr-3 d-flex align-items-center bg-danger justify-content-center rounded-circle text-white">
                              {(list.length === 0) ? <Tr>
                                <Td colspan="12" style={{ textAlign: 'center' }}>
                                  <Alert key={'alert'} variant={'info'}>
                                    {I18n.t('No Notification')}
                                  </Alert>
                                </Td>
                              </Tr> : ''}
                              {list.sender !== null ?
                                <>
                                  {list.sender.media !== null ?
                                    <img class="rounded-circle" style={{ width: '60px' }} src={list.sender.media.url} />
                                    :
                                    "D"}
                                </>
                                : "D"}
                            </div>
                            <div className="font-weight-bold mr-3">
                              {list.notification_group !== null ?
                                <>
                                  
                                  <>
                                    <div className="text-truncate">{I18n.t(list.notification_group.title)}</div>
                                    <div className="small">{I18n.t(list.notification_group.description)}</div>
                                    {list.notification_group_id === 9 ?
                                      <>
                                        <div className="small" style={{ marginBottom: '-10px' }}>{I18n.t('Question')} : {(list.question_text)}</div>
                                        <div className="small">{I18n.t('Answer')} : {(list.answer_text)}</div>
                                      </> : ""}
                                  </>
                                  {/* } */}
                                </>
                                :
                                <>
                                  <div className="text-truncate">{"NA"}</div>
                                  <div className="small">{list.receiver.full_name}</div>
                                </>}
                            </div>
                            <span className="ml-auto mb-auto">
                              <div className="btn-group">
                              </div>
                              <br />
                              <div className="text-right text-muted pt-1">
                                {moment(list.created_at).format('DD MMM ')}</div>
                            </span>
                          </div>
                        </div>
                      ))}
                    </div>


                    {(notification_list.length > 0) ?
                      <ReactPaginate
                        previousLabel={I18n.t('prev')}
                        nextLabel={I18n.t('next')}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={pageCount}
                        marginPagesDisplayed={0}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"} />
                      : ''}
                  </div>
                </div>
              </div>
            </div>


          </>
        </div>
        <Footer />
      </body>
    </>
  )
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
