import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import axios from "axios";
import { toggleLoader } from '../../actions/loader';
import { Link } from 'react-router-dom';
import emailImage from './images/email.png'
import phoneImage from './images/phone.png'
import Col from 'react-bootstrap/Col'
import { getUserData, } from '../../actions/userdata';
import { accessUserLogin, getUserProfile } from '../../Utils/utils'
import config from "../../config/config";
import { displayError, displaySuccess} from '../../Utils/utils'
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher'
import { Translate, I18n } from "react-redux-i18n";

const Footer = (props) => {
  const [user_data, setUserData] = useState(null);
  const[email,setEmail]=useState('');
  // const redirect = () => {
  //   window.location.href = "https://nifty-mccarthy-f9c2c8.netlify.app/"
  // }
  const userCallMethod = async () => {
    var final_data = await accessUserLogin();
    props.getUserData(final_data);
    return final_data;
  }
  useEffect(() => {
    var data = userCallMethod();
    setUserData(data)
  }, [])
  const subscribeNewsletter = () => {
    var token = localStorage.getItem('token');
    // props.toggleLoader(true);
    let path = config.siteUrl;
    axios.post(path + '/newsletter/register', {
      email:email,
    }, {
      headers: { 'Content-Type': 'application/json'},
    })
      .then(function (response) {
        displaySuccess(I18n.t('you have been subscribed succesfully'));
        // setTimeout(() => window.location.href = "/certificates", 3000);
      })
      .catch(function (error) {
        displayError(error);
      });
  }
  return (
    <>
      <footer className="site-footer">
        <div className="site-footer__upper">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-6 col-sm-12">
                <div className="footer-widget footer-widget__about">
                  <h2 className="footer-widget__title">{I18n.t("About Us")}</h2>
                  <p className="footer-widget__text">Existimos para inspirar e impulsionar a construção de negócios virtuosos</p>
               
                    <div className="footer-widget__btn-block">
                      <img src={phoneImage} width="35px" />
                      <div className="right_side_value">
                      <h5 className="mb-0 text-white">{I18n.t("Phone")}</h5>
                        <a href="tel:+ 55 51 3527-4145" className="text-white"> + 55 51 3527-4145</a>
                      </div>
                    </div>
                    <div className="footer-widget__btn-block">
                      <img src={emailImage} width="40px" />
                      <div className="right_side_value">
                      <h5 className="mb-0 text-white">{I18n.t("Email")}</h5>
                        <a href="mailto:relacionamento@metododutra.com.br" className="text-white"> relacionamento@metododutra.com.br</a>
                      </div>

                    </div>
                </div>
              </div>
              <Col xl="3" lg="6" md="6" sm="6" xs="6">
                <div className="footer-widget footer-widget__link">
                  <h2 className="footer-widget__title">{I18n.t("Usefull Link")}</h2>
                  <div className="footer-widget__link-wrap">
                    <ul className="list-unstyled footer-widget__link-list">
                      {/* <li><Link to="/contactus">Contact Us</Link></li>
                      <li><Link to="/faqs">FAQs</Link></li> */}
                      <li><Link to="/termsandconditions">{I18n.t("Terms and Conditions")}</Link></li>
                      <li><Link to="/privacypolicy">{I18n.t("Privacy Policy")}</Link></li>
                      <li><LanguageSwitcher/></li>
                    </ul>

                  </div>
                </div>
              </Col>
              <Col xl="3" lg="6" md="6" sm="6" xs="6">
                <div className="footer-widget footer-widget__link">
                  <h2 className="footer-widget__title">{I18n.t("Usefull Link")}</h2>
                  <div className="footer-widget__link-wrap">
                    <ul className="list-unstyled footer-widget__link-list">
                      <li><Link to='/course-list'>{I18n.t("Courses")}</Link></li>
                      <li><Link to='/instructor-list'>{I18n.t("Instructors")}</Link></li>
                      
                    </ul>

                  </div>
                </div>
              </Col>


              <div className="col-xl-3 col-lg-6 col-sm-12">
                <div className="footer-widget footer-widget__gallery">
                  <h2 className="footer-widget__title">{I18n.t("Subscribe")}</h2>
                  <div>
                    <input type="text" placeholder={I18n.t("Email Address")}  className="form-control" style={{ height: "60px" }} onChange={e => setEmail(e.target.value)}/>
                    <div className="btn_div mt-4">
                      <button className="theme-btn footer_btn w-100" style={{ textTransform: "capitalize" }} onClick={subscribeNewsletter}>{I18n.t("Subscribe")}</button>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <div className="site-footer__bottom">
          <div className="container">
            {/* <p className="site-footer__copy">&copy; Copyright 2021 by Dutra.com | Design by  <a href="#">CodiFly</a></p> */}
            <div className="site-footer__social">
              <a href="https://www.facebook.com/MetodoDutra" target="_blank"><i className="fab fa-facebook-square"></i></a>
              <a href="https://www.instagram.com/metododutra" target="_blank"><i className="fab fa-instagram"></i></a>
              <a href="https://www.linkedin.com/company/dutra-consultores" target="_blank"><i class="icofont-linkedin"></i></a>
              <a href="https://www.metododutra.com.br " target="_blank"><i class="icofont-web"></i></a>

            </div>
          </div>
        </div>
      </footer>
    </>
  )
}
const mapDispatchToProps = {
  toggleLoader,
  getUserData
}
const mapStateToProps = (state) => {
  return {
    userdata: state.userdata
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Footer);
