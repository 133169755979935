import React, { useEffect, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { toggleLoader } from '../actions/loader';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button';
import { Rating, RatingView } from 'react-simple-star-rating'
import Form from 'react-bootstrap/Form';
import config from "../config/config";
import axios from "axios";
import { displayError, displaySuccess, CourseConsumption, displayErrorString } from '../Utils/utils'
import { Translate, I18n } from "react-redux-i18n";
import moment from 'moment';
const CourseAccordion = (props) => {
  const [rating, setRating] = useState(5) // initial rating value
  const [image_quality, setImageQuality] = useState(false)
  const [content_improved, setContentImproved] = useState(false)
  const [sound_quality, setSoundQuality] = useState(false)
  const [hate_speech, setHateSpeech] = useState(false)
  const [interest_me, setContentInterestMe] = useState(false)
  const [offensive_content, setOffensiveContent] = useState(false)
  const [topic_id, setTopicId] = useState(props.topic_id)
  const [modalShow, setModalShow] = React.useState(false);
  const [offer_id, setOfferId] = useState(props.offer_id)
  // const [is_allowed, setIsAllowed] = useState(0);
  const [now_date, setNowDate] = useState('');

  const [show, setShow] = useState(false);
  const handleClose = () => setModalShow(false);
  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
  const handleRating = (rate) => {
    setRating(rate)
    if (rate > 3) {
      setImageQuality(false)
      setContentImproved(false)
      setSoundQuality(false)
      setHateSpeech(false)
      setContentInterestMe(false)
      setOffensiveContent(false)
    }
  }

  const submitReview = async () => {
    const data = {
      rating: rating,
      topic_id: topic_id,
      offer_id: offer_id,
      image_quality: image_quality,
      content_improved: content_improved,
      sound_quality: sound_quality,
      hate_speech: hate_speech,
      interest_me: interest_me,
      offensive_content: offensive_content
    }
    let path = config.siteUrl;
    var token = localStorage.getItem('token');
    axios.post(path + "/student/course/rating", data, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        displaySuccess(I18n.t('Rating Successfull'));
        props.toggleLoader(false);
        props.onRatingGiven(topic_id)
        props.onHide()
      })
      .catch((error) => {
        props.toggleLoader(false);
        displayError(error);
      });
  }
    ;
  const check_date = () => {
    let date1 = new Date(props.check_date);
    date1.setDate(date1.getDate() + 7)
    // let date1 = new Date(props.check_date).setDate(new Date(props.check_date).getDate() + 7);
    let date2 = new Date();
    // console.log(date1)
    return(parseInt((date1 - date2) / (1000 * 60 * 60 * 24), 10));
    // console.log(parseInt((date1 - date2) / (1000 * 60 * 60 * 24), 10));
  }
  return (
    <>

      <Accordion defaultActiveKey={"product_0"} >
        {props.product.map((products, index) => (
          <Card className="justify-content-between hosted-table " style={{ marginBottom: "15px" }}>
            <Accordion.Toggle as={Card.Header} eventKey={"product_" + index} className="card-toggle" >
              <div className="row">
                <div className="col-lg-10 col-10">
                  <h2 className="class-subcontent-materi m-0">
                    {products.name}
                  </h2>
                </div>
                <div className="col-lg-2 col-2" style={{ textAlign: 'right' }}>
                  <i class="fas fa-angle-down fa-lg " style={{ color: '#26C9D3' }}></i>
                </div>
              </div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={"product_" + index} className="ReactCollapse--collapse ">
              <>
                <Card.Body className='p-0 '>
                  <Accordion defaultActiveKey={"product_0_module_0"} className="">
                    {products.module.map((modules, index2) => (
                      // <Accordion defaultActiveKey={"product_0_module_0"} className="">
                      <Card className="justify-content-between bg-white" style={{ marginBottom: "15px" }}>
                        <Accordion.Toggle as={Card.Header} eventKey={"product_" + index + "_module_" + index2} className="card-toggle border-bottom pb-2" >
                          <div className="row">
                            <div className="col-lg-10 col-10">
                              <h2 className="class-subcontent-materi m-0">
                                {modules.name}
                              </h2>
                            </div>
                            <div className="col-lg-2 col-2" style={{ textAlign: 'right' }}>
                              <i class="fas fa-angle-down fa-lg " style={{ color: '#26C9D3' }}></i>
                            </div>
                          </div>
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey={"product_" + index + "_module_" + index2} className="ReactCollapse--collapse">
                          <>
                            {modules.topic.map((topics) => (
                              <Card.Body className=" hosted-table-subcontent hosted-table-subcontent-selected" >
                                {/* {topics.is_allowed !== false ?:} */}
                                <div className={topics.is_allowed !== false ? 'row' : 'row allow-css'}
                                  onClick={e => {
                                    if (topics.is_allowed !== false) {
                                      if (topics.video === null && topics.pdf === null) {
                                        props.playVideo('', null, topics.name, topics.text_content, modules.id, topics.id)
                                      } else if (topics.video !== null) {
                                        props.playVideo(topics.video.url, 'video', topics.name, topics.text_content, modules.id, topics.id)
                                      } else if (topics.pdf !== null && topics.video === null) {
                                        props.playVideo(topics.pdf.media.url, 'pdf', topics.name, topics.text_content, modules.id, topics.id)
                                      } else {
                                        return;
                                      }
                                    } else {
                                      check_date()
                                      displayErrorString(I18n.t("This resource will be accessible after ") + check_date() + I18n.t(" days from now"));
                                    }

                                  }}>
                                  <div className="col-2 align-self-center">
                                    {
                                      (topics.video === null && topics.pdf === null)
                                        ?
                                        <div className="check-css" >
                                          {
                                            <i className="fas fa-tv pointer" style={{ color: 'rgb(36, 112, 117)' }} />
                                          }
                                        </div>
                                        : ''
                                    }
                                    {(topics.video !== null)
                                      ?
                                      <div className="icon_topic" >
                                        {
                                          (props.playing_url === topics.video.url) ?
                                            <i class="far fa-pause-circle fa-lg pointer" style={{ color: 'rgb(36, 112, 117)' }}></i> :
                                            <i class="far fa-play-circle fa-lg pointer" style={{ color: 'rgb(36, 112, 117)' }}></i>
                                        }
                                      </div>
                                      : ''}
                                    {(topics.pdf !== null && topics.video === null) ? <div
                                      className="first_icon"
                                    >
                                      <i class="fas fa-file-pdf pointer " style={{ color: 'rgb(36, 112, 117)' }}></i>
                                    </div> : ''}
                                  </div>
                                  <div className='col-7 col-lg-8 class-subcontent-list m-0 p-0'>
                                    <div>{topics.name}</div>

                                    <div className="star-css">
                                      {/* {
                                        (topics.topic_watched !== null) ? */}
                                      <>
                                        {(topics.topic_rating !== null) ?
                                          <>
                                            <i class="icofont-star text-warning"
                                              style={{ fontSize: '18px' }}
                                              onClick={e => {
                                                if (topics.is_allowed === true) {
                                                  setModalShow(true)
                                                  setTopicId(topics.id)
                                                  // initilise var with topic rating object values 
                                                  setRating(topics.topic_rating.rating)
                                                  setImageQuality(topics.topic_rating.image_quality)
                                                  setContentImproved(topics.topic_rating.content_improved)
                                                  setSoundQuality(topics.topic_rating.sound_quality)
                                                  setHateSpeech(topics.topic_rating.hate_speech)
                                                  setContentInterestMe(topics.topic_rating.interest_me)
                                                  setOffensiveContent(topics.topic_rating.offensive_content)
                                                }
                                              }}
                                            >
                                              <span className="star-rate" style={{ fontWeight: '50', fontSize: '14px', color: '#666' }}>{I18n.t('Rate Topic')}</span></i>
                                          </>
                                          :
                                          <>
                                            <i class="icofont-star rate-select"
                                              style={{ fontSize: '18px' }}
                                              onClick={e => {
                                                if (topics.is_allowed === true) {
                                                  setModalShow(true)
                                                  setTopicId(topics.id)
                                                  //initialise var with default values 
                                                  setRating()
                                                  setImageQuality()
                                                  setContentImproved()
                                                  setSoundQuality()
                                                  setHateSpeech()
                                                  setContentInterestMe()
                                                  setOffensiveContent()
                                                }
                                              }}>
                                              <span className="star-rate" style={{ fontWeight: '50', fontSize: '14px', color: '#666' }}>{I18n.t('Rate Topic')}</span>
                                            </i>
                                          </>
                                        }
                                        {/* <span className="star-rate" style={{ fontWeight: '100' }}>{I18n.t('Rate Topic')}</span> */}

                                      </>
                                    </div>

                                  </div>
                                  <div className='col-3 col-lg-2 text-right'>
                                    {(topics.topic_watched !== null) ?
                                      <div className="check-css"  >
                                        <i style={{ color: 'green' }} class="fas fa-check-circle"></i>
                                      </div>
                                      : ""}
                                    <div className='class-list'>
                                      {
                                        (topics.video !== null)
                                          ?
                                          (topics.duration_in_minutes !== null && topics.duration_in_minutes !== 'null') ?
                                            topics.duration_in_minutes + I18n.t(" min") : I18n.t("Watch")
                                          :
                                          <span style={{ marginLeft: '-2px' }}>{I18n.t("View")}</span>
                                      }
                                    </div>

                                  </div>
                                  <div className='col-auto'>

                                  </div>

                                </div>

                              </Card.Body >
                            ))}
                          </>
                        </Accordion.Collapse >
                      </Card >
                      // {/* </Accordion> */}
                    ))}
                  </Accordion >
                </Card.Body >

              </>
            </Accordion.Collapse >
          </Card >
        ))}

      </Accordion >
      <Modal show={modalShow} onHide={handleClose}>
        <Modal.Header closeButton>
          <div className="modal-title h4"> {I18n.t("Provide Your Feedback")}</div>
        </Modal.Header>
        <Modal.Body>
          <p style={{ textAlign: 'center', fontSize: '20px' }}>
            <Rating onClick={handleRating} ratingValue={rating} /* Rating Props */ />
          </p>
          {
            (rating <= 3) ?
              <div className="row">
                <div className='col'>
                  <div className='select-help-improve'>
                    <h6>{I18n.t("Help us improve, what impacted your grade negatively")}?</h6>
                    <form>
                      <div className="row">
                        <div className='col-lg-5 mt-1'>
                          <div class="mb-3 form-group">
                            <div class="form-check">
                              <input type="checkbox" id="formBasicCheckbox1" class="form-check-input checkbox-rate"
                                checked={image_quality}
                                onClick={e => { setImageQuality(e.target.checked) }} />
                              <label title="" for="formBasicCheckbox1" class="form-check-label">{I18n.t("Image Quality")}
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className='col-lg-7 mt-1'>
                          <div class="mb-3 form-group">
                            <div class="form-check">
                              <input type="checkbox" id="formBasicCheckbox1" class="form-check-input checkbox-rate" checked={content_improved}
                                onClick={e => { setContentImproved(e.target.checked) }} />
                              <label title="" for="formBasicCheckbox1" class="form-check-label">{I18n.t("Content could be improved")}
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className='col-lg-5 mt-1'>
                          <div class="mb-3 form-group">
                            <div class="form-check">
                              <input type="checkbox" id="formBasicCheckbox1" class="form-check-input checkbox-rate" checked={sound_quality}
                                onClick={e => { setSoundQuality(e.target.checked) }} />
                              <label title="" for="formBasicCheckbox1" class="form-check-label">{I18n.t("Sound Quality")}
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className='col-lg-7 mt-1'>
                          <div class="mb-3 form-group">
                            <div class="form-check">
                              <input type="checkbox" id="formBasicCheckbox1" class="form-check-input checkbox-rate" checked={offensive_content}
                                onClick={e => { setOffensiveContent(e.target.checked) }} />
                              <label title="" for="formBasicCheckbox1" class="form-check-label">{I18n.t("Offensive Content")}
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className='col-lg-5 mt-1'>
                          <div class="mb-3 form-group">
                            <div class="form-check">
                              <input type="checkbox" id="formBasicCheckbox1" class="form-check-input checkbox-rate" checked={hate_speech}
                                onClick={e => { setHateSpeech(e.target.checked) }} />
                              <label title="" for="formBasicCheckbox1" class="form-check-label">{I18n.t("Hate Speech")}
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className='col-lg-7 mt-1'>
                          <div class="mb-3 form-group">
                            <div class="form-check">
                              <input type="checkbox" id="formBasicCheckbox1" class="form-check-input checkbox-rate" checked={interest_me}
                                onClick={e => { setContentInterestMe(e.target.checked) }} />
                              <label title="" for="formBasicCheckbox1" class="form-check-label">{I18n.t("The content doesnt interest me")} </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              : ''
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {I18n.t("Close")}
          </Button>
          <Button variant="primary" onClick={e => {
            submitReview(topic_id)
            handleClose()
          }}>
            {I18n.t("Submit")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CourseAccordion);