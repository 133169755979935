import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toggleLoader } from '../actions/loader';
import { connect } from 'react-redux';
import Topbar from '../components/Topbar';
import TopNav from '../components/TopNav';
import './style.css';
import team1 from './images/team-1-1.jpg'
import team2 from './images/team-1-2.jpg'
import bg1 from './images/inner-bg-1-1.jpg'
import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import CourseRightBar from './courseRightBar';
import Footer from '../components/Footer';
import { CourseDetail } from '../Utils/utils';
import course1 from './images/course-1-1.jpg'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import parse from "html-react-parser";
import { Translate, I18n } from "react-redux-i18n";

import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import Alert from 'react-bootstrap/Alert'
import defaultImg from './images/index.jpeg';
const CourseDetails = (props) => {
  const [course_name, setCourseName] = useState('');
  const [courseImage, setCourseImage] = useState('');
  const [instructor_image, setInstructorImage] = useState('');
  const [inst_name, setInstName] = useState('');
  const [avg_rating, setAvgRating] = useState('');
  const [rating_count, setRatingCount] = useState('');
  const [category_name, setCategoryName] = useState('');
  const [description, setDesription] = useState('');
  const [price, setPrice] = useState('');
  const [duration, setDuration] = useState('');
  const [lectureCount, setLecturecount] = useState('');
  const [level, setLevel] = useState('');
  const [language, setLanguage] = useState('');
  const [slug, setSlug] = useState('');
  const [id, setId] = useState('');
  const [enroll_user, setEnrollUser] = useState('');
  const [lecture_list, setLectureList] = useState([]);
  const [offer_instructor, setOfferInstructor] = useState([]);
  const [quantity_of_customer, setQuantityOfCustomer] = useState('');
  const [free_content, setFreeContent] = useState('');

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true)
  }
  useEffect(async () => {
    props.toggleLoader(true);
    var courseDetail_var = await CourseDetail(props.match.params.id);
    setCourseName(courseDetail_var.name);
    setCourseImage(courseDetail_var.media.url);
    if (courseDetail_var.user !== null) {
      setInstName(courseDetail_var.user.full_name);
      if (courseDetail_var.user.media !== null) {
        setInstructorImage(courseDetail_var.user.media.url);
      }
    }
    setAvgRating(courseDetail_var.avg_rating);
    setRatingCount(courseDetail_var.rating_count);
    setCategoryName(courseDetail_var.category.name);
    setDesription(courseDetail_var.description);
    setPrice(courseDetail_var.price);
    setDuration(courseDetail_var.duration);
    setLecturecount(courseDetail_var.lecture_count);
    setLevel(courseDetail_var.level.name);
    setLanguage(courseDetail_var.language.name);
    setEnrollUser(courseDetail_var.enroll_user_count)
    setLectureList(courseDetail_var.lecture_list);
    setOfferInstructor(courseDetail_var.offer_instructor);
    setId(courseDetail_var.id);
    setSlug(courseDetail_var.slug);
    setQuantityOfCustomer(courseDetail_var.quantity_of_customer);
    setFreeContent(courseDetail_var.free_content);
    props.toggleLoader(false);

  }, [])
  const printStars = (avg_star) => {
    var print_html = [];
    for (var i = 1; i <= 5; i++) {
      if (avg_star >= i) {
        print_html.push(<i className="icofont-star text-warning"></i>);
      } else {
        print_html.push(<i className="icofont-star rate-select " style={{ color: '#eee' }}></i>);
      }
    }
    return print_html;
  }
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <div className="page-wrapper">
        <Topbar />
        {/* <TopNav /> */}
        <section className="course-details">
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="course-detail-data">
                  <h3 className='instructor-name ' onClick={e => handleShow()} style={{ cursor: 'pointer' }}>
                    <i class="fa fa-users"></i>  {I18n.t("View Instructors")}
                  </h3>
                  <div className="course-name-other">
                    <div className="course-name-other-left">
                      <h2 className="course-name-detail">{course_name}</h2>
                      <div className="course-one__stars">
                        <span className="course-one__stars-wrap">
                          {printStars(avg_rating)}
                        </span>
                        <span className="course-one__stars-count">{avg_rating}</span>
                      </div>
                    </div>
                    <div className="course-name-other-right">
                      <a className="course-one__category">
                        {I18n.t(category_name)}
                      </a>
                    </div>
                  </div>
                  {/* <div className="course-one__image">
                    <img src={courseImage} alt />
                    <i className="far fa-heart" />
                  </div> */}
                  <Tabs defaultActiveKey="overview" className="course-details__tab-navs list-unstyled nav nav-tabs active" variant="pills"
                  >
                    <Tab eventKey="overview" title={I18n.t("Overview")}>
                      <div className="tab-pane show animated fadeInUp"  >
                        <p className="course-details__tab-text" >
                          {parse(description)}
                        </p>
                      </div>
                    </Tab>
                    <Tab eventKey="curriculum" title={I18n.t("Curriculum")}>
                      {lecture_list.map((list) => (
                        <div className="tab-pane  animated fadeInUp" >
                          <h3 className="course-details__tab-title">
                            {list.title}
                          </h3>
                          <br />
                          <ul className="course-details__curriculum-list list-unstyled">
                            {list.topic.map((topics) => (
                              <li>
                                <div className="course-details-report-list-left">
                                  <div className="course-details__meta-icon video-icon">
                                    <i className="fas fa-play" />
                                  </div>{topics.title}
                                  {/* <span>Preview</span> */}
                                </div>
                                {topics.duration !== null ?
                                  <div className="course-details__curriculum-list-right">
                                    {topics.duration}
                                  </div>
                                  : ""}
                              </li>
                            ))}
                          </ul>
                          <br />
                        </div>
                      ))}
                    </Tab>

                  </Tabs>
                </div>
              </div>
              <CourseRightBar
                courseImage={courseImage}
                price={price}
                free_content={free_content}
                duration={duration}
                lectureCount={lectureCount}
                level={level}
                language={language}
                enroll_user={enroll_user}
                slug={slug}
                id={props.match.params.id}
                quantity_of_customer={quantity_of_customer} />
            </div>
          </div>
        </section>
        <>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <div className="modal-title-c h4"> {I18n.t("View all instructors")}</div>
            </Modal.Header>
            <Modal.Body>
              <>
                {(offer_instructor.length === 0) ?
                  <Tr>
                    <Td colspan="12" style={{ textAlign: 'center' }}>
                      <Alert key={'alert'} variant={'info'} style={{ width: '350px', marginLeft: '50px' }}>
                        {I18n.t('No Instructor')}
                      </Alert>
                    </Td>
                  </Tr> : ''}
                {offer_instructor.map((inst) => (


                  <div className="row" style={{ paddingBottom: '20px' }}>
                    <div className="col-lg-3">
                      <div className='user-image-c'>
                        <Link to={"/instructor-detail/" + inst.user.id}>
                          {inst.user.media !== null ?
                            <img src={inst.user.media.url} className="img-fluid" style={{ borderRadius: '4px' }} />
                            : <img src={defaultImg} className="img-fluid" style={{ borderRadius: '4px' }} />}
                        </Link>
                      </div>
                    </div>
                    <div className="col-lg-9">
                      <div className="user-name-c">
                        <Link to={"/instructor-detail/" + inst.user.id}>
                          <h6>{inst.user.first_name} {inst.user.last_name}</h6>
                        </Link>
                      </div>
                      <br />
                    </div>
                  </div>
                ))}
              </>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                {I18n.t("Close")}
              </Button>
            </Modal.Footer>
          </Modal>
        </>
        <Footer />
      </div>
    </>
  )
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CourseDetails);