import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import axios from "axios";
import { toggleLoader } from '../../actions/loader';
import { Link } from 'react-router-dom';
import { getUserData } from '../../actions/userdata';
import { displayError, displaySuccess, accessUserLogin } from '../../Utils/utils';
import config from "../../config/config";
import { Translate, I18n } from "react-redux-i18n";

const FreeRegister = ({ getUserData, userdata }) => {
  const [user_data, setUserData] = useState(null);
  const [email, setEmail] = useState("");
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");

  const userCallMethod = async () => {
    var final_data = await accessUserLogin();
    getUserData(final_data);
    return final_data;
  }
  useEffect(() => {
    var data = userCallMethod();
    setUserData(data)
  }, [])
  const RegisterUser = async () => {
    var token = localStorage.getItem('token');
    let path = config.siteUrl;
    await axios.post(path + '/register/user', {
      email: email,
      first_name: firstname,
      last_name: lastname,
      password: password,
      password_confirmation: confirm_password,
      user_type: 1,
    }, {
      headers: { 'Content-Type': 'application/json', Authorization: ` ${token}` },
    })
      .then((response) => {
        displaySuccess(I18n.t('user registered succesfully ! verify your email now'));
        // props.toggleLoader(false);

      })
      .catch((error) => {
        displayError(error);
        // props.toggleLoader(false);
      });
  };
  return (
    <>
      {(userdata.userData === null || userdata.userData === '')
        ? <>
          <section class="form_section form_register ">
            <div class="container">
              <div class="row">
                <div class="col-lg-7 d-none d-sm-block">
                  <div class="form_register__content">
                    <h3>{I18n.t("Get Free Registration")}</h3>
                    <h2 class="form_register__title">{I18n.t("Sign up and get access to an amazing knowledge base")} <br />
                      {/* {I18n.t("your knowledge base")} */}
                    </h2>
                    {/* <p>
                      {I18n.t("There are many variations of passages of lorem ipsum available but the majority have suffered alteration in some form.")}
                    </p> */}

                  </div>
                </div>
                <div class="col-lg-5 d-flex justify-content-lg-end justify-content-sm-start">

                </div>
              </div>
            </div>
          </section>
          <section class="form-count-one">
            <div class="container">
              <div class="row">
                <div className='col-lg-1 col-md-2'>
                  <img src='img/image-5.png' className='img-fluid' />
                </div>
                <div class="col-lg-6 col-md-5">
                  <div class="form-count-one__content">
                    <h2 class="form-count-one__title"> {I18n.t("A new teaching method")}</h2>
                    <p class="form-count-one__tag-line">
                      {I18n.t("Discover a multidisciplinary knowledge base, with several instructors and lots of experience exchange")}
                    </p>
                    {/* <!-- /.form-count-one__tag-line --> */}

                  </div>
                </div>
                <div class="col-lg-5">
                  <div class="become-teacher__form">
                    <div class="become-teacher__form-top">
                      <h2 class="become-teacher__form-title">
                        {I18n.t("Fill your Registration")}
                      </h2>
                    </div>
                    <div action="" class="become-teacher__form-content contact-form-validated">
                      <input type="text" placeholder={I18n.t("First Name")} name="firstname"
                        onChange={e => setFirstName(e.target.value)} />
                      <input type="text" placeholder={I18n.t("Last Name")} name="lastname"
                        onChange={e => setLastName(e.target.value)} />
                      <input type="text" placeholder={I18n.t("Email Address")} name="email"
                        onChange={e => setEmail(e.target.value)} />
                      <input type="password" placeholder={I18n.t("Password")} name="password"
                        onChange={e => setPassword(e.target.value)} />
                      <input type="password" placeholder={I18n.t("Confirm Password")} name="confirmpassword"
                        onChange={e => setConfirmPassword(e.target.value)} />
                      <button type="submit" class="thm-btn become-teacher__form-btn"
                        onClick={RegisterUser}> {I18n.t("Register")}</button>
                    </div>
                    <div class="result text-center"></div>
                  </div>
                </div>
              </div>
            </div>
            <img src='img/dot-pattern.png' className='dotted_img' />
          </section></> : ""}
    </>
  )
}
const mapDispatchToProps = {
  toggleLoader,
  getUserData
}
const mapStateToProps = (state) => {
  return {
    userdata: state.userdata
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(FreeRegister);
