import React, { useState, useEffect } from 'react';
import image1 from './images/img-1.jpg'
import finance from './images/finance.png'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { connect } from 'react-redux';
import axios from "axios";
import { toggleLoader } from '../../actions/loader';
import { categoryList } from '../../Utils/utils';
import { Link } from 'react-router-dom';
import { Translate, I18n } from "react-redux-i18n";

const TopCategories = () => {
  const [category, setCategory] = useState([]);

  useEffect(async () => {
    var categoryList_var = await categoryList();
    setCategory(categoryList_var);
  }, [])

  return (
    <>
      <section class="categories-top bg-white">
        <div class="container">
          <div class="section-title text-center">
            <span class="section-title__tagline">{I18n.t("Checkout New List")}</span>
            <h2 class="section-title__title">{I18n.t("Top Categories")}</h2>
          </div>
          <div class="row d-none d-sm-block">
            <div class="col-xl-12">
              <div class="categories-top__wrapper">
                <div class="row">
                  {category.map((list) => (
                    <div class="category-list_div col"> 
                      <Link to={"/course-list?category=" + list.id}>
                        <div className='top_category_ '>
                           <div className='category_icon one'>{/* <i class="fas fa-bezier-curve"></i>  */}
                            <img src={list.web_media.url}/>
                          </div>
                          <h2>{I18n.t(list.name)}</h2>
                          {/* <h5>188 Course</h5> */}
                        </div>
                      </Link>
                    </div>
                  ))}
                  {/* <div class="col-xl-3 col-lg-4 col-md-6 wow fadeInUp animated">
                  <div className='top_category_ '>
                    <div className='category_icon two'><i class="fas fa-bezier-curve"></i></div>
                    <h2>Design</h2>
                    <h5>188 Course</h5>
                  </div>
                </div>
                <div class="col-xl-3 col-lg-4 col-md-6 wow fadeInUp animated">
                  <div className='top_category_ '>
                    <div className='category_icon three'><i class="far fa-images"></i></div>
                    <h2>Photography</h2>
                    <h5>188 Course</h5>
                  </div>
                </div>
                <div class="col-xl-3 col-lg-4 col-md-6 wow fadeInUp animated">
                  <div className='top_category_ '>
                    <div className='category_icon four'><i class="far fa-lightbulb"></i></div>
                    <h2>Marketing</h2>
                    <h5>188 Course</h5>
                  </div>
                </div>
                <div class="col-xl-3 col-lg-4 col-md-6 wow fadeInUp animated">
                  <div className='top_category_ '>
                    <div className='category_icon five'><i class="fas fa-music"></i></div>
                    <h2>Music</h2>
                    <h5>188 Course</h5>
                  </div>
                </div>
                <div class="col-xl-3 col-lg-4 col-md-6 wow fadeInUp animated">
                  <div className='top_category_ '>
                    <div className='category_icon four'><i class="far fa-lightbulb"></i></div>
                    <h2>Marketing</h2>
                    <h5>188 Course</h5>
                  </div>
                </div>
                <div class="col-xl-3 col-lg-4 col-md-6 wow fadeInUp animated">
                  <div className='top_category_ '>
                    <div className='category_icon one'><i class="far fa-lightbulb"></i></div>
                    <h2>Development</h2>
                    <h5>188 Course</h5>
                  </div>
                </div>
                <div class="col-xl-3 col-lg-4 col-md-6 wow fadeInUp animated">
                  <div className='top_category_ '>
                    <div className='category_icon two'><i class="fas fa-bezier-curve"></i></div>
                    <h2>Design</h2>
                    <h5>188 Course</h5>
                  </div>
                </div> */}
                  {/* {category.map((list) => (
                    <div class="col-xl-3 col-lg-6 col-md-6 wow fadeInUp animated">
                      <div class="categories-top__single">
                        <div class="categories-top__single-img">
                          <img src={image1} alt="" />
                          <div class="categories-top__single-overlay">
                            <div class="categories-top__single-overlay-text1">
                              <p>30 full courses</p>
                            </div>
                            <Link to={"/course-list?category=" + list.id}>
                              <div class="categories-top__single-overlay-text2">
                                <h4>{list.name}</h4>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))} */}
                </div>
              </div>
              <div class="btn_div text-center mt-3">
                <Link to="/course-list" className="theme-btn black_line">{I18n.t("View all course")}</Link>
              </div>

            </div>
          </div>
        </div>
      </section>
    </>
  )
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(TopCategories);
