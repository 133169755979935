import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import axios from "axios";
import apiUrl from "../config/apiPath";
import config from "../config/config";
import { Translate, I18n } from "react-redux-i18n";

export const displayErrorString = (error) => {
  toast.configure({
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: false,
  })
  toast.error(error);
}
export const displayError = (error) => {
  toast.configure({
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: false,
  })
  // var mystring = error.response;
  // mystring.replace(/./g,' ');
  // console.log(mystring)
  if (error.response.status === 422) {
    var row = [];
    Object.keys(error.response.data.errors).forEach((key, i) => {
      row.push(error.response.data.errors[key][0]);
      // console.log(error.response.data.errors);
    })
    const Msg = ({ closeToast, toastProps }) => (
      // string = "";
      row.map((char, i) => {
        return (
          <li>
            {I18n.t(char.replace(/\./g, ''))}
          </li>

        );
      })
    )
    // var mystring = Msg;

    // mystring.replace(/./g,' ');
    toast.error(Msg);
  } else if (error.response.status === 400 || error.response.status === 401) {
    toast.error(I18n.t((error.response.data.message).replace(/\.|\!/g, '')));
  } else {
    toast.error(error.message);
  }
}
export const displaySuccess = (success) => {
  toast.configure({
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: false,
  })
  toast.success(success);
}

export const verifyEmail = async (token) => {
  let path = config.siteUrl;
  //var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/auth/verify/" + token, {
    headers: { 'Content-Type': 'application/json' },
  })
    .then(function (response) {
      return true;
    })
    .catch(function (error) {
      return false;
    });
  return response_final;
}
export const accessUserLogin = async () => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/auth/me", {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then(function (response) {
      return response.data.data;
    })
    .catch(function (error) {
      return null;
    });
  return response_final;
}
export const categoryList = async () => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var result = await axios.get(path + "/common/categories", {
    headers: { 'Content-Type': 'application/json' },
  })
    .then((response) => {
      return response.data.data
    })
    .catch((error) => {
      return null
    });
  return result;
}
export const levelList = async () => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var result = await axios.get(path + "/common/level", {
    headers: { 'Content-Type': 'application/json' },
  })
    .then((response) => {
      return response.data.data
    })
    .catch((error) => {
      return null
    });
  return result;
}

export const allCoursesList = async (page = 1, search = '', category_filter = [], level_filter = 'all', price_filter = 'all', sorting = 'recent_courses') => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.post(path + "/common/course/listing?page=" + page, {
    search: search,
    category_filter: category_filter,
    instructor_fiter: [],
    price: price_filter,
    level: level_filter,
    sorting: sorting
  }, {
    headers: { 'Content-Type': 'application/json' },
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return []
    });
  return response_final;
}

export const courseList = async () => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var result = await axios.get(path + "/common/popular/course/listing", {
    headers: { 'Content-Type': 'application/json' },
  })
    .then((response) => {
      return response.data.data
    })
    .catch((error) => {
      return null
    });
  return result;
}
export const CourseDetail = async (id) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/common/course/details/" + id, {
    headers: { 'Content-Type': 'application/json' },
  })
    .then((response) => {
      return response.data.data
    })
    .catch((error) => {
      if (error.response.status === 400) {
        displayErrorString(error.response.data.message);
        setTimeout(() => window.location.href = "/course-list", 2000);
      } else {
        return null
      }
    });
  return response_final;
}

export const instructorList = async () => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var result = await axios.get(path + "/common/popular/instructor", {
    headers: { 'Content-Type': 'application/json' },
  })
    .then((response) => {
      return response.data.data
    })
    .catch((error) => {
      return null
    });
  return result;
}

export const allInstructorList = async (page = 1, search = '') => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var result = await axios.get(path + "/common/instructor/listing?page=" + page + '&search=' + search, {
    headers: { 'Content-Type': 'application/json' },
  })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      return null
    });
  return result;
}
export const instructorDetail = async (id) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/common/instructor/details/" + id, {
    headers: { 'Content-Type': 'application/json' },
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      if (error.response.status === 400) {
        displayErrorString(error.response.data.message);
        setTimeout(() => window.location.href = "/instructor-list", 2000);
      } else {
        return null
      }
    });
  return response_final;
}
export const instructorCourseList = async (id) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/common/instructor/course/listing/" + id, {
    headers: { 'Content-Type': 'application/json' },
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      return null

    });
  return response_final;
}
export const getUserProfile = async () => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var result = await axios.get(path + "/user/profile", {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then((response) => {
      return response.data.data
    })
    .catch((error) => {
      if (error.response.status === 401) {
        // window.location.href = '/';
      } else {
        return null
      }
    });
  return result;
}
export const getCountry = async (id) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var result = await axios.get(path + "/common/country", {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then((response) => {
      return response.data.data
    })
    .catch((error) => {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return null
      }
    });
  return result;
}
export const getStates = async (country_id = null) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var result = await axios.get(path + "/common/state/" + country_id, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then((response) => {
      return response.data.data
    })
    .catch((error) => {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return null
      }
    });
  return result;
}

export const getCities = async (state_id = null) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var result = await axios.get(path + "/common/city/" + state_id, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then((response) => {
      return response.data.data
    })
    .catch((error) => {
      // if (error.response.status === 401) {
      //   window.location.href = '/';
      // } else {
      return null
      // }
    });
  return result;
}
export const getTimezoneDropdown = async () => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/common/timezone/dropdown", {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      // if (error.response.status === 401) {
      //   window.location.href = '/';
      // } else {
      return null
      // }
    });
  return response_final;
}
export const myCoursesList = async (page = 1, search = '', status = '') => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.post(path + "/student/course/listing?page=" + page, {
    search: search,
    status: status
  }, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return []
      }
    });
  return response_final;
}
export const getCertificate = async () => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/student/certificate_list", {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return null
      }
    });
  return response_final;
}
export const upcomingCertificate = async () => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/student/upcoming_certificate", {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return null
      }
    });
  return response_final;
}
export const liveEventsListing = async (page = 1) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.post(path + "/student/event/listing?page=" + page, {

  }, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return []
      }
    });
  return response_final;
}
export const pastLiveEvents = async (page = 1) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.post(path + "/student/event/past/listing?page=" + page, {

  }, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return []
      }
    });
  return response_final;
}
export const CourseConsumption = async (id) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/student/course/" + id + "/consumption", {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then((response) => {
      return response.data.data
    })
    .catch((error) => {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        if (error.response.status === 400) {
          displayErrorString(error.response.data.message);
        setTimeout(() => window.location.href = "/mycourse", 2000);
        } else {
          return null
        }
      }
    });
  return response_final;
}
export const topicViewSave = async (data = {}) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.post(path + "/student/topic/view", data, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return null
      }
    });
  return response_final;
}
export const courseQuestions = async (id, topic_id, page = 1, search = '') => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var result = await axios.get(path + "/student/course/" + id + "/questions/" + topic_id + "?page=" + page + "&search=" + search, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return null
      }
    });
  return result;
}
export const getRatings = async (id, topic_id) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/common/course/rating/listing?page=1&offer_id=" + id + "&topic_id=" + topic_id, {
    headers: { 'Content-Type': 'application/json' },
  })
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      return null

    });
  return response_final;
}

export const getRatingsPercent = async (id, topic_id) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/common/course/rating?offer_id=" + id + "&topic_id=" + topic_id, {
    headers: { 'Content-Type': 'application/json' },
  })
    .then((response) => {
      return response.data.data
    })
    .catch((error) => {
      return null

    });
  return response_final;
}
export const crossSellAssign = async (offer_id, module_id, topic_id) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.post(path + "/cross_sell/available/sell", {
    offer_id: offer_id,
    module_id: module_id,
    topic_id: topic_id,
  }, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      // if (error.response.status === 401) {
      //   window.location.href = '/';
      // } else {
      //   return null
      // }
      return null
    });
  return response_final;
}
export const getCreditCardRates = async () => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/payment/card/rates", {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      // if (error.response.status === 401) {
      //   window.location.href = '/';
      // } else {
      return null
      // }
    });
  return response_final;
}
export const knowCoursePrice = async (offer_id, cross_sell_id) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.post(path + "/student/know/course/price", {
    offer_id: offer_id,
    cross_sell_id: cross_sell_id
  }, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return null
      }
    });
  return response_final;
}
export const eventDetails = async (id) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var result = await axios.get(path + "/student/eventdetail/" + id, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then((response) => {
      return response.data.data
    })
    .catch((error) => {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else if (error.response.status === 404) {
        displayErrorString(error.response.data.message);
        setTimeout(() => window.location.href = "/live-events", 2000);
      } else {
        return null
      }
    });
  return result;
}
export const eventView = async (data = {}) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.post(path + "/student/event/view", data, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return null
      }
    });
  return response_final;
}
export const liveCrossSell = async (event_id) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.post(path + "/cross_sell/available/event", {
    event_id: event_id
  }, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return null
      }
    });
  return response_final;
}
export const liveQuestions = async (page = 1, search = '') => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.post(path + "/student/allquestion" + "?page=" + page + "&search=" + search, {

  }, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return []
      }
    });
  return response_final;
}
export const liveCount = async () => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/student/dashboard/count", {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return []
      }
    });
  return response_final;
}
export const notificationCount = async () => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/notification/count", {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return null
      }
    });
  return response_final;
}
export const notificationRead = async () => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.get(path + "/notification/read", {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return null
      }
    });
  return response_final;
}
export const getNotification = async (page = 1) => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var response_final = await axios.post(path + "/notification?page=" + page, {

  }, {
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response.status === 401) {
        window.location.href = '/';
      } else {
        return []
      }
    });
  return response_final;
}

export const carouselList = async () => {
  let path = config.siteUrl;
  var token = localStorage.getItem('token');
  var result = await axios.get(path + "/common/carousels", {
    headers: { 'Content-Type': 'application/json' , Authorization: `Bearer ${token}` },
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      return []
    });
  return result;
}