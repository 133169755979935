import React, { useState, useEffect } from 'react';
import emailImage from './images/email.png'
import phoneImage from './images/phone.png'
import userImage from './images/user-login.png'
import logo from './images/logo-dark.png'
import { Link } from 'react-router-dom';
import { Button, Form, Modal } from 'react-bootstrap/'
import './style.css';
// import GoogleLogin from 'react-google-login';
import config from "../../config/config";
import { displayError, displaySuccess, accessUserLogin } from '../../Utils/utils';
import { connect } from 'react-redux';
import axios from "axios";
import { toggleLoader } from '../../actions/loader';
import { getUserData } from '../../actions/userdata';
import { Navbar, Nav } from 'react-bootstrap';
import Container from 'react-bootstrap/Container'
import { Translate, I18n } from "react-redux-i18n";
import queryString from 'query-string';

const Topbar = ({ toggleLoader, signup, getUserData, userdata, handleShowLogin, temp_show }) => {
  const languageSelected = (localStorage.getItem('langauge_selected') == 'en' || localStorage.getItem('langauge_selected') == '') ? 'en' : 'pt' 
  const [show, setShow] = useState(false);
  const [showRegister, setShowRegister] = useState(false);
  const [showForgot, setShowForgot] = useState(false);
  const [email, setEmail] = useState("");
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");

  const [loginEmail, setLoginEmail] = useState('');
  const [loginPassword, setLoginPassword] = useState('');
  const [emailForgot, setEmailForgot] = useState('');
  const [passwordReset, setPasswordReset] = useState('');
  const [confirm_password_reset, setConfirmPasswordReset] = useState('');

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  // handleShowLogin=(e)=>setShow(e)

  const handleCloseRegister = () => setShowRegister(false)
  const handleRegister = () => setShowRegister(true);

  const handleCloseForgot = () => setShowForgot(false)
  const handleForgot = () => setShowForgot(true);
  const [user_data, setUserData] = useState(null);
  const handleCloseReset = () => setShowForgot(false);
  const handleReset = () => setShowForgot(true);
  const [showReset, setShowReset] = useState(false);

  // const [googleEmail, setGoogleEmail] = useState('');
  // const [googlePassword, setGooglePassword] = useState('');
  // const responseGoogle = (response) => {
  //   googleLogin(response);
  // }
  // const responseGoogleFail = (response) => {
  // }

  useEffect(() => {
    setShow(temp_show)
  }, [temp_show])
  
  
  useEffect(() => {
    if (window.location.pathname === "/password/reset" ) {
      setShowReset(true);
    }else if(window.location.pathname === "/reset" ){
      setShowReset(true);
    }else{
      setShowReset(false);
    }
  }, [])

  const userCallMethod = async () => {
    var final_data = await accessUserLogin();
    getUserData(final_data);
    return final_data;
  }
  useEffect(() => {
    var data = userCallMethod();
    setUserData(data)
    console.log(languageSelected);
  }, [])

  // const logout = () => {
  //   localStorage.removeItem('token');
  //   displaySuccess('Logout Successfull');
  //   setTimeout(() => window.location.href = "/", 3000);
  // }
  const RegisterUser = async () => {
    var token = localStorage.getItem('token');
    let path = config.siteUrl;
    await axios.post(path + '/register/user', {
      email: email,
      first_name: firstname,
      last_name: lastname,
      password: password,
      password_confirmation: confirm_password,
      user_type: 1,
      language : languageSelected,
    }, {
      headers: { 'Content-Type': 'application/json', Authorization: ` ${token}` },
    })
      .then((response) => {
        displaySuccess(I18n.t('user registered succesfully  verify your email now'));
        toggleLoader(false);
        setShowRegister(false);
      })
      .catch((error) => {
        displayError(error);
        toggleLoader(false);
      });
  };

  const Login = (e) => {
    e.preventDefault();
    toggleLoader(true);
    let path = config.siteUrl;
    path = path + '/auth/login';
    axios.post(path, {
      email: loginEmail,
      password: loginPassword,
    })
      .then(function (response) {
        toggleLoader(false);
        displaySuccess(I18n.t('Login Successfull'));
        localStorage.setItem('token', response.data.data.access_token);
        setTimeout(() => window.location.href = "/mycourse", 3000);
        setShow(false);
      })
      .catch((error) => {
        displayError(error);
        toggleLoader(false);
      });
  };
  // const GoogleSignin = (e) => {
  //   e.preventDefault();
  //   // props.toggleLoader(true);
  //   let path = config.siteUrl;
  //   path = path + '/signup-google';
  //   axios.post(path, {
  //     email: googleEmail,
  //     password: googlePassword,
  //   })
  //     .then(function (response) {
  //       // props.toggleLoader(false);
  //       displaySuccess(I18n.t('Login Successfull'));
  //       localStorage.setItem('token', response.data.data.access_token);
  //       setTimeout(() => window.location.href = "/mycourse", 3000);
  //       setShow(false);
  //     })
  //     .catch((error) => {
  //       displayError(error);
  //       // props.toggleLoader(false);
  //     });
  // };
  const ForgotPassword = async (token) => {
    // var token = localStorage.getItem('token');
    toggleLoader(true);
    let path = config.siteUrl;
    await axios.post(path + '/password/reset', {
      email: emailForgot,
      language : languageSelected,
    }, {
      headers: { 'Content-Type': 'application/json', Authorization: ` Bearer ${token}` },
    })
      .then((response) => {
        displaySuccess(I18n.t('Password reset email has been sent'));
        toggleLoader(false);
        setShowForgot(false);
      })
      .catch((error) => {
        displayError(error);
        toggleLoader(false);
      });
  };
  const ChangePassword = async (props) => {
    toggleLoader(true);
    let path = config.siteUrl;
    const token = queryString.parse(window.location.search);
    await axios.post(path + '/password/change', {
      password: passwordReset,
      password_confirmation: confirm_password_reset,
      token: token.token,
    }, {
      headers: { 'Content-Type': 'application/json' },
    })
      .then((response) => {
        displaySuccess(I18n.t('Your password has been changed'));
        setTimeout(() => window.location.href = "/", 3000);
        toggleLoader(false);
        setShowReset(false);
      })
      .catch((error) => {
        displayError(error);
        if (error.response.status === 401) {
          window.location.href = '/';
        }
        toggleLoader(false);
      });
  };
  return (
    <>
      <div class="topbar-one d-none d-sm-block">
        <div class="container">
          <div class="topbar-one__left">
            <span className='d-sm-none d-lg-inline-block'>{I18n.t("Have any Question")}? </span>
            <a href="tel:+ 55 51 3527-4145" class="ml-2"><img src={phoneImage} width="25" className='d-sm-none d-md-inline-block' /> + 55 51 3527-4145</a>
            <a href="mailto:relacionamento@metododutra.com.br"><img src={emailImage} width="32" className='d-sm-none d-md-inline-block' />&nbsp; relacionamento@metododutra.com.br</a>

          </div>
          {(userdata.userData === null || userdata.userData === '') ?
            <div class="topbar-one__right">
              <a >
                <Button variant="" className="bg-transparent text-dark login-btn" onClick={handleShow}>
                  <img src={userImage} width="25" /> &nbsp;&nbsp; {I18n.t("Login")}
                </Button>
              </a>
              <a>
                <Button className="" onClick={handleRegister}>
                  {I18n.t("Register")}
                </Button>
              </a>
            </div>
            :
            <div class="topbar-one__right">
              <Link to="/profile">
                <i class="fa fa-user" aria-hidden="true"></i>  {userdata.userData.first_name} {userdata.userData.last_name}
              </Link>
              {/* <a>
                <div className="media-body"
                  onClick={logout} style={{ cursor: 'pointer' }} >
                  Logout  <i class="fa fa-sign-out" aria-hidden="true"></i>
                </div>
              </a> */}
            </div>
          }
        </div>
      </div>
      <header className="site-header site-header__header-one d-none d-sm-block">
        <nav className="navbar navbar-expand-lg m-nav-blue header-navigation stricky">
          <div className="container clearfix">
            <div className="logo-box clearfix">
              <Link className="navbar-brand" to="/">
                <img src={logo} className="main-logo" width="128" />
              </Link>

              <button className="menu-toggler" data-target=".main-navigation">
                <span className="kipso-icon-menu"></span>
              </button>
            </div>
            <div className="main-navigation">
              <ul className=" navigation-box">
                <li>
                  <Link to="/course-list">{I18n.t("COURSES")}</Link>
                </li>
                <li>
                  <Link to="/instructor-list">{I18n.t("INSTRUCTORS")}</Link>
                  <ul className="sub-menu" />
                </li>
                {(userdata.userData === null || userdata.userData === '')
                  ? "" : <li><Link to="/mycourse">{I18n.t("MY COURSES")}</Link></li>}
              </ul>
            </div>
            {/* <div className="right-side-box">
              <a className="header__search-btn search-popup__toggler" href="#">
                <i className="fas fa-search"></i>
              </a>
            </div> */}
          </div>
        </nav>
      </header>
      <div className="d-block d-sm-none">
        <Navbar bg="light" expand="lg">
          <Container>
            <Link to="/"><Navbar.Brand href="#home">
              <img src={logo} className="main-logo" width="128" />
            </Navbar.Brand></Link>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link> {I18n.t("Have any Question")}?</Nav.Link>
                <Nav.Link >
                  <a href="tel:+ 55 51 3527-4145"  class="ml-2"><img src={phoneImage} width="25" /> + 55 51 3527-4145</a>
                </Nav.Link>
                <Nav.Link >
                  <a href="mailto:relacionamento@metododutra.com.br" class="ml-2"><img src={emailImage} width="25" /> relacionamento@metododutra.com.br</a>
                </Nav.Link>
                {(userdata.userData === null || userdata.userData === '') ?
                  <Nav.Link>
                    <a >
                      <Button variant="primary" onClick={handleShow}>
                        <img src={userImage} width="25" /> {I18n.t("Login")}
                      </Button>
                    </a> &nbsp;
                    <a>
                      <Button variant="primary" onClick={handleRegister}>
                        {I18n.t("Register")}
                      </Button>
                    </a>
                  </Nav.Link>
                  :
                  <div class="topbar-one__right">
                    <Link to="/profile">
                      <i class="fa fa-user" aria-hidden="true"></i> {userdata.userData.first_name} {userdata.userData.last_name}
                    </Link>
                    {/* <a>
                      <div className="media-body"
                        onClick={logout} style={{ cursor: 'pointer' }} >
                        Logout  <i class="fa fa-sign-out" aria-hidden="true"></i>
                      </div>
                    </a> */}
                  </div>
                }

                <Nav.Link >

                  <li>
                    <Link to="/course-list">{I18n.t("COURSES")}</Link>
                  </li>
                  <li>
                    <Link to="/instructor-list">{I18n.t("INSTRUTORES")}</Link>
                    <ul className="sub-menu" />

                  </li>
                  {(userdata.userData === null || userdata.userData === '')
                  ? "" : <li><Link to="/mycourse">{I18n.t("MY COURSES")}</Link></li>}
                  {/* <li>
                    <Link to="#">{I18n.t("My Courses")}</Link>
                  </li> */}
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
      <Modal show={show} onHide={handleClose} >

        <Modal.Body>
          <div className="signLogin">
            <Modal.Title ><h2>{I18n.t("Sign In")}</h2> </Modal.Title>
          </div>
          <Form>
            <div className='login_warper_main'>
              <div className="emailLogin">
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label style={{ fontSize: '14px', marginBottom: '-10px' }}>{I18n.t("Email Address")}</Form.Label>
                  <Form.Control type="email" placeholder={I18n.t("Enter Email Address")} className='mh-50' onChange={e => setLoginEmail(e.target.value)} />
                </Form.Group>
              </div>
              <div className="passLogin">
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label style={{ fontSize: '14px', marginBottom: '-10px' }}>{I18n.t("Password")}</Form.Label>
                  <Form.Control type="password" className='mh-50' placeholder={I18n.t("Enter Password")} onChange={e => setLoginPassword(e.target.value)} />
                </Form.Group>
              </div>
              <div className="forgotLog">
                <Link  onClick={(e) => {
                  handleForgot()
                  handleClose()
                }} > {I18n.t("Forgot Password?")} </Link>
              </div>
              <div className="btnLogin">
                <Button className="theme-btn black_line" onClick={handleClose} style={{ width: '100%' }} onClick={Login}>
                  {I18n.t("Login")}
                </Button>
              </div>
              {/* <div className="orLogin">
                <h3>Or</h3>
              </div> */}
              <div >
                {/* <GoogleLogin
                  // clientId={window.$GOOGLE_CLIENT_ID}
                  clientId="885534801766-tppuf5d4kek6g3tnb584kvahhaftu1cr.apps.googleusercontent.com"
                  buttonText="Login"
                  onSuccess={responseGoogle}
                  onFailure={responseGoogleFail}
                  cookiePolicy={'single_host_origin'}
                  render={renderProps => (
                    <button onClick={GoogleSignin} data-toggle="tooltip" data-placement="top" title={`Google Sign ${signup ? 'Up' : 'In'}`}
                      className='googleBtn' >
                      <img src="https://img.icons8.com/color/452/google-logo.png" /> {I18n.t("Continue with Google")}
                    </button>
                  )}
                /> */}
              </div>
            </div>
          </Form>
          <div className='signupLog'>
            {I18n.t("Not On Dutra For Education Yet?")}
            <Link  onClick={(e) => {
              handleRegister()
              handleClose()
            }} >
              {I18n.t("Sign Up")}
            </Link>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={showRegister} onHide={handleCloseRegister}>
        <Modal.Body>
          <div className="signReg">
            <Modal.Title ><h2 className='text-center'> {I18n.t("Sign Up")}</h2> </Modal.Title>
          </div>
          <Form>
            <div className='login_warper_main'>
              <div className='row'>
                <div className="col-lg-12">
                  <Form.Group controlId="exampleForm.ControlInput1" className='mb-2'>
                    <Form.Label style={{ fontSize: '14px', marginBottom: '-10px' }}>{I18n.t("First Name")}</Form.Label>
                    <Form.Control type="text" placeholder={I18n.t("First Name")} className='mh-50' onChange={e => setFirstName(e.target.value)} />
                  </Form.Group>
                </div>
                <div className="col-lg-12">
                  <Form.Group controlId="exampleForm.ControlInput1" className='mb-2'>
                    <Form.Label style={{ fontSize: '14px', marginBottom: '-10px' }}>{I18n.t("Last Name")}</Form.Label>
                    <Form.Control type="text" placeholder={I18n.t("Last Name")} className='mh-50' onChange={e => setLastName(e.target.value)} />
                  </Form.Group>
                </div>
                <div className="col-lg-12">
                  <Form.Group controlId="exampleForm.ControlInput1" className='mb-2'>
                    <Form.Label style={{ fontSize: '14px', marginBottom: '-10px' }}>{I18n.t("Email Address")}</Form.Label>
                    <Form.Control type="email" placeholder={I18n.t("Email Address")} className='mh-50' onChange={e => setEmail(e.target.value)} />
                  </Form.Group>
                </div>
                <div className="col-lg-12">
                  <Form.Group controlId="exampleForm.ControlInput1" className='mb-2'>
                    <Form.Label style={{ fontSize: '14px', marginBottom: '-10px' }}>{I18n.t("Password")}</Form.Label>
                    <Form.Control type="password" placeholder={I18n.t("Password")} className='mh-50' onChange={e => setPassword(e.target.value)} />
                  </Form.Group>
                </div>
                <div className="col-lg-12">
                  <Form.Group controlId="exampleForm.ControlInput1" >
                    <Form.Label style={{ fontSize: '14px', marginBottom: '-10px' }}>{I18n.t("Confirm Password")}</Form.Label>
                    <Form.Control type="password" placeholder={I18n.t("Confirm Password")} className='mh-50' onChange={e => setConfirmPassword(e.target.value)} />
                  </Form.Group>
                </div>
                <div className="col-lg-12">
                  <Button className="theme-btn black_line w-100" onClick={RegisterUser}>
                    {I18n.t("Register")}
                  </Button>
                </div>
                <div className='signupLog'>
                  {I18n.t("Already On Dutra For Education?")}
                  <Link to="#" onClick={(e) => {
                    handleShow()
                    handleCloseRegister()
                  }} >
                    {I18n.t("Login")}
                  </Link>
                </div>
              </div>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal show={showForgot} onHide={handleCloseForgot}>
        <Modal.Body>
          <div className="forgotLogin">
            <Modal.Title ><h2> {I18n.t("Forgot Password")}</h2> </Modal.Title>
          </div>
          <Form>
            <div className='login_warper_main'>
              <div className="forgotInfo">{I18n.t("Enter your Email Address")}</div>
              <div className="emailForgot">
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label style={{ fontSize: '14px', marginBottom: '-10px' }}>{I18n.t("Email Address")}</Form.Label>
                  <Form.Control type="email" placeholder={I18n.t("Enter Email Address")} onChange={e => setEmailForgot(e.target.value)} />
                </Form.Group>
              </div>
              <div className="btnReset">
                <Button className="theme-btn black_line w-100" onClick={ForgotPassword}>
                  {I18n.t("Reset Password")}
                </Button>
              </div>
              <div className='signupLog'>
                {I18n.t("Remember Password?")}
                <Link to="#" onClick={(e) => {
                  handleShow()
                  handleCloseForgot()
                }} >
                  {I18n.t("Login")}
                </Link>
              </div>
            </div>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal show={showReset} onHide={handleCloseReset}>
        <Modal.Body style={{ background: '#ecedee' }}>
          <div className="forgotLogin">
            <Modal.Title ><h2 style={{ color: "#5e5c5c" }}>{I18n.t("Reset Password")}</h2> </Modal.Title>
          </div>
          <Form>
            <div className="forgotInfo">{I18n.t("Enter New Password.")}</div>
            <div className="emailForgot">
              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label style={{ fontSize: '14px', marginBottom: '-10px' }}>{I18n.t("New Password")}</Form.Label>
                <Form.Control type="password" placeholder={I18n.t("New Password")} onChange={e => setPasswordReset(e.target.value)} />
              </Form.Group>
            </div>
            <div className="emailForgot">
              <Form.Group controlId="exampleForm.ControlInput1">
                <Form.Label style={{ fontSize: '14px', marginBottom: '-10px' }}>{I18n.t("Confirm Password")}</Form.Label>
                <Form.Control type="password" placeholder={I18n.t("Confirm Password")} onChange={e => setConfirmPasswordReset(e.target.value)} />
              </Form.Group>
            </div>
            <div className="btnReset">
              <Button variant="secondary" onClick={ChangePassword} style={{ width: '100%' }}>
                {I18n.t("Reset Password")}
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  )
}
const mapDispatchToProps = {
  toggleLoader,
  getUserData
}
const mapStateToProps = (state) => {
  return {
    userdata: state.userdata
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Topbar);
