import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import axios from "axios";
import { toggleLoader } from '../actions/loader';
import Topbar from '../components/Topbar';
import Footer from '../components/Footer';
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import { Link } from 'react-router-dom';
import Leftbar from '../components/Leftbar';
import Alert from 'react-bootstrap/Alert'
import ReactPaginate from 'react-paginate';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import './style.css';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import { eventDetails, displaySuccess, displayError, eventView, liveCrossSell, liveQuestions } from '../Utils/utils';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import config from "../config/config";
import moment from 'moment';
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Translate, I18n } from "react-redux-i18n";
import { Rating, RatingView } from 'react-simple-star-rating'

const LiveEventDetail = (props) => {
  const [title, setTitle] = useState('');
  const [media, setMedia] = useState('');
  const [meeting_type, setMeetingType] = useState('');
  const [meeting_link, setMeetingLink] = useState('');
  const [upcoming, setUpcoming] = useState([]);
  const [launch_date, setLaunchDate] = useState('');

  const [rating, setRating] = useState(5) // initial rating value
  const [image_quality, setImageQuality] = useState(false)
  const [content_improved, setContentImproved] = useState(false)
  const [sound_quality, setSoundQuality] = useState(false)
  const [hate_speech, setHateSpeech] = useState(false)
  const [interest_me, setContentInterestMe] = useState(false)
  const [offensive_content, setOffensiveContent] = useState(false)
  const [topic_id, setTopicId] = useState()
  const [modalShow, setModalShow] = React.useState(false);
  const [my_rating, setMyRating] = useState('');
  const [live_content_id, setLiveContentId] = useState('')
  const [crossSellImage, setCrossSellImage] = useState('');
  const [cross_sell_id, setCrossSellId] = useState('');
  const [questions, setQuestions] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [search, setSearch] = useState('');
  const [question, setQuestion] = useState('');
  const [current_page, setCurrentPage] = useState(1);
  const [temp_topic_id, setTempTopicId] = useState(42);
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  useEffect(async () => {
    props.toggleLoader(true);
    var get_event_details = await eventDetails(props.match.params.id);
    if (get_event_details !== null) {
      setTitle(get_event_details.title);
      setMedia(get_event_details.media.url)
      setMeetingType(get_event_details.meeting_type);
      setMeetingLink(get_event_details.meeting_link);
      setUpcoming(get_event_details.upcoming_events);
      setMyRating(get_event_details.my_rating);
      setLaunchDate(get_event_details.lunch_date_time);
      if (get_event_details.my_rating !== null) {
        setLiveContentId(get_event_details.my_rating.live_content_id);
        setImageQuality(get_event_details.my_rating.image_quality);
        setContentImproved(get_event_details.my_rating.content_improved);
        setSoundQuality(get_event_details.my_rating.sound_quality);
        setHateSpeech(get_event_details.my_rating.hate_speech);
        setContentInterestMe(get_event_details.my_rating.interest_me);
        setOffensiveContent(get_event_details.my_rating.offensive_content);
        setRating(get_event_details.my_rating.rating);
      }
      else {
        setLiveContentId(get_event_details.id);
      }
      eventView({
        live_event_id: props.match.params.id,
      });
      var live_ques = await liveQuestions(current_page);
      setQuestions(live_ques.data);
      setPageCount(live_ques.last_page);
    }
    props.toggleLoader(false);
  }, [])
  const submitReview = async () => {
    const data = {
      rating: rating,
      live_content_id: live_content_id,
      image_quality: image_quality,
      content_improved: content_improved,
      sound_quality: sound_quality,
      hate_speech: hate_speech,
      interest_me: interest_me,
      offensive_content: offensive_content
    }
    let path = config.siteUrl;
    var token = localStorage.getItem('token');
    axios.post(path + "/student/event/rating", data, {
      headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        displaySuccess(I18n.t('Rating Successfull'));
        props.toggleLoader(false);
        props.onRatingGiven()
        props.onHide()
        setTimeout(() => window.location.href = "/course/livevent/:id", 3000);
      })
      .catch((error) => {
        props.toggleLoader(false);
        // if (error.response.status === 401) {
        //   window.location.href = '/';
        // }
        displayError(error);

      });
  }
  const handleRating = (rate) => {
    setRating(rate)
    if (rate > 3) {
      setImageQuality(false)
      setContentImproved(false)
      setSoundQuality(false)
      setHateSpeech(false)
      setContentInterestMe(false)
      setOffensiveContent(false)
    }
  }
  const handleClose = () => setModalShow(false);

  useEffect(async () => {
    props.toggleLoader(true);
    var cross_sell_var = await liveCrossSell(props.match.params.id);
    if (cross_sell_var !== null) {
      if (cross_sell_var.cross_sell.media !== null) {
        setCrossSellImage(cross_sell_var.cross_sell.media.url);
      }
    }

    props.toggleLoader(false);
  }, [])

  const handlePageClick = async (e) => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    const selectedPage = e.selected + 1;
    var live_ques = await liveQuestions(selectedPage);
    setQuestions(live_ques.data);
    setPageCount(live_ques.last_page);
  };
  const searchFilterNoLoader = async (e) => {
    var live_ques = await liveQuestions(current_page, e)
    if(live_ques !== null){
      setQuestions(live_ques.data);
      setPageCount(live_ques.last_page);
    }
    
  }
  const searchFilter = async () => {
    var live_ques = await liveQuestions(current_page, search)
    // setTempTopicId(live_ques.topic_id);
    setQuestions(live_ques.data);
    setPageCount(live_ques.last_page);
  }
  return (
    <>
      <body id="page-top">
        <div className="page-wrapper">

          <Topbar />
          {/* <InnerBanner/> */}
          <>
            <div className="my-3">
              <div className="">
                <div className='container'>
                  <div className="row">
                    <div className='col-md-12 col-xl-9 mx-auto'>
                      <nav>
                        <ol className="breadcrumb align-items-center bg-transparent p-0 mb-0">
                          <li className="breadcrumb-item"><Link to="/" className="fs-6 text-secondary">{I18n.t("Home")}</Link></li>
                          <li className="breadcrumb-item"><Link to="/live-event" className="fs-6 text-secondary">{I18n.t("Live Events")}</Link></li>
                          {/* <li className="breadcrumb-item"><a href="#" className="fs-6 text-secondary">Graphice Design</a></li> */}
                          <li className="breadcrumb-item fs-6 text-secondary d-none d-lg-inline-block" aria-current="page">{title}</li>
                        </ol>
                      </nav>
                      <h3 className="font-title--sm font-weight-bold my-2">{title} <br /> </h3>
                      <div className='video_event my-4'>
                        <iframe height="506" src={meeting_link} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
                        <div className="icon-with-date-start d-flex align-items-center">
                          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M14.25 3H3.75C2.92157 3 2.25 3.67157 2.25 4.5V15C2.25 15.8284 2.92157 16.5 3.75 16.5H14.25C15.0784 16.5 15.75 15.8284 15.75 15V4.5C15.75 3.67157 15.0784 3 14.25 3Z" stroke="#00AF91" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M12 1.5V4.5" stroke="#00AF91" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M6 1.5V4.5" stroke="#00AF91" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"></path>
                            <path d="M2.25 7.5H15.75" stroke="#00AF91" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"></path>
                          </svg>  <span> {moment(launch_date).format('DD-MMM-YYYY')}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <section className='bottom_answer_review'>
              <div className='container'>
                <div className='col-md-10 mx-auto'>
                  <div className='row'>
                    <div className='col-md-8'>
                      <h4>{I18n.t("Answer Center")} <br />
                        <small style={{ fontSize: '14px' }}>{I18n.t("For doubts, search here")}: </small>
                      </h4>
                      <div>
                        <div className="input-group mb-4">
                          <input type="text" placeholder={I18n.t("Search")} className="form-control search-input radius-50 mh-68" onChange={e => {
                            setSearch(e.target.value)
                            setQuestion(e.target.value)
                            searchFilterNoLoader(e.target.value)
                          }} />
                          <span className="input-group-btn">
                            <button  className="header__search-btn search-popup__toggler search-button mt-2 mr-2">
                              <i className="fa fa-search"></i>
                            </button>
                          </span>
                        </div>

                      </div>
                      <div className="row">
                      {questions.map((ques, index) => (
                        <div className='col-md-6'>
                        <div class="ques-css">
                          <h5>  Q{index + 1}. {ques.question}</h5>
                          <div class="row">
                            <div class="col-md-3 col-lg-2">
                              <div class="ques-image">
                                <img src={ques.user.media.url} style={{ maxWidth: '100%', borderRadius: '30px' }} />
                              </div>
                            </div>
                            <div class="col-md-9 col-lg-10 col-9 pl-0">
                              <div class="name-css">{I18n.t("By")} {ques.user.first_name} {ques.user.last_name}</div>
                              <div class="ques-date">{I18n.t("Created on")}  {moment(ques.user.created_at).format('DD/MM/YYYY')}</div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col">
                              <div class="ques-box">
                                <div class="ques-box-head">{ques.answer}</div>
                              </div>
                            </div>
                          </div>
                          <br />
                        </div>
                        </div>
                      ))}
                      </div>
                      
                    </div>
                    <div className='col-md-4'>
                      <h5 className="second-title mb-4">
                        {I18n.t('Rate this event!')} </h5>
                      <div>
                        {(my_rating !== null) ?
                          <Button type="submit" className="btn-update-review" onClick={e => {
                            setModalShow(true)
                            // initilise var with topic rating object values 
                            setLiveContentId(live_content_id)
                            setRating(rating)
                            setImageQuality(image_quality)
                            setContentImproved(content_improved)
                            setSoundQuality(sound_quality)
                            setHateSpeech(hate_speech)
                            setContentInterestMe(interest_me)
                            setOffensiveContent(offensive_content)
                          }}>{I18n.t('Update Rating')}
                          </Button>
                          :
                          <Button type="submit" className="btn-success  " onClick={e => {
                            setModalShow(true)
                            setLiveContentId(live_content_id)
                            //initialise var with default values 
                            setRating()
                            setImageQuality()
                            setContentImproved()
                            setSoundQuality()
                            setHateSpeech()
                            setContentInterestMe()
                            setOffensiveContent()
                          }}>{I18n.t('Give Rating')}</Button>}
                      </div>
                      <br/>
                      <h5 className='text-primary'>{I18n.t("Upcoming Events")}</h5>
                      {(upcoming.length === 0) ? <div>
                        <p colspan="7" style={{ textAlign: 'center' }}>
                          <Alert key={'alert'} variant={'info'}>
                            {I18n.t('No Upcoming Events')}
                          </Alert>
                        </p>
                      </div> : ''}
                      {upcoming.map((event) => (<>
                        <hr />
                        <div className="media">
                          <Link to={"/course/liveevent/" + event.id} target="_blank">
                            <img className="mr-3" src={event.media.url} width={120} alt="Generic placeholder image" />
                          </Link>
                          <div className="media-body">
                            <h5 className="mt-0">{event.title}</h5>
                            <p style={{ fontSize: '14px' }} className='line-normal'>
                              <span>{event.lunch_date_time}</span>
                            </p>
                          </div>
                        </div></>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <Modal
              show={modalShow} onHide={handleClose}
              // {...props}
              size="md"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  {I18n.t('Provide Your Feedback')}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p style={{ textAlign: 'center' }}>
                  <Rating onClick={handleRating} ratingValue={rating} /* Rating Props */ />
                </p>
                {
                  (rating <= 3) ?
                    <div className='Row'>
                      <div className='col'>
                         <div className="">{/* select-help-improve */}
                          <h6>{I18n.t('Help us improve, what impacted your grade negatively')}?</h6>
                          <Form>
                            <div className="row">
                              <div className="col-md-12 mt-1">
                                <Form.Group className="mb-1" controlId="formBasicCheckbox1">
                                  <Form.Check
                                    type="checkbox"
                                    className='set-mt-2'
                                    label={I18n.t('Image Quality')}
                                    checked={image_quality}
                                    onClick={e => { setImageQuality(e.target.checked) }}
                                  />
                                </Form.Group>
                              </div>
                              <div className="col-md-12 mt-1">
                                <Form.Group className="mb-1" controlId="formBasicCheckbox2">
                                  <Form.Check type="checkbox" label={I18n.t('Content could be improved')}
                                    checked={content_improved} className='set-mt-2'
                                    onClick={e => { setContentImproved(e.target.checked) }}
                                  />
                                </Form.Group>
                              </div>
                              <div className="col-md-12 mt-1">
                                <Form.Group className="mb-1" controlId="formBasicCheckbox3">
                                  <Form.Check type="checkbox" label={I18n.t('Sound Quality')} className='set-mt-2'
                                    checked={sound_quality} 
                                    onClick={e => { setSoundQuality(e.target.checked) }}
                                  />
                                </Form.Group>
                              </div>
                              <div className="col-md-12 mt-1">
                                <Form.Group className="mb-1" controlId="formBasicCheckbox4">
                                  <Form.Check type="checkbox" label={I18n.t('Offensive content')} className='set-mt-2'
                                    checked={offensive_content}
                                    onClick={e => { setOffensiveContent(e.target.checked) }}
                                  />
                                </Form.Group>
                              </div>
                              <div className="col-md-12 mt-1">
                                <Form.Group className="mb-1" controlId="formBasicCheckbox5">
                                  <Form.Check type="checkbox" label={I18n.t('Hate Speech')} className='set-mt-2'
                                    checked={hate_speech}
                                    onClick={e => { setHateSpeech(e.target.checked) }}
                                  />
                                </Form.Group>
                              </div>
                              <div className="col-md-12 mt-1">
                                <Form.Group className="mb-1" controlId="formBasicCheckbox6">
                                  <Form.Check type="checkbox" label={I18n.t('The content doesnt interest me')}
                                    checked={interest_me} className='set-mt-2'
                                    onClick={e => { setContentInterestMe(e.target.checked) }}
                                  />
                                </Form.Group>
                              </div>
                            </div>
                          </Form>
                        </div>
                      </div>
                    </div>
                    : ''
                }
              </Modal.Body>

              <Modal.Footer style={{ textAlign: 'right' }}>
                
                <div className='col-auto pr-0'><Button variant="primary" className='w-100' onClick={e => {
                  submitReview()
                  handleClose()
                }}>{I18n.t('Submit')}</Button></div>
                <div className='col-auto pl-0'><Button variant="danger" className='w-100' onClick={handleClose}>{I18n.t('Close')}</Button></div>
              </Modal.Footer>
            </Modal>
          </>
        </div>
        <Footer />
      </body>
    </>
  )
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(LiveEventDetail);
