import { get } from 'lodash';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

function ProtectedRoute(props, ...rest) {
  var token = localStorage.getItem('token');
  if (props.userdata.userData !== null) {
    return (
      <Route
        {...props}
      />
    );
  } else {
    window.location.href = '/';
  }


}
const mapDispatchToProps = {
}
const mapStateToProps = (state) => {
  return {
    userdata: state.userdata
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedRoute);
