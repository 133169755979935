import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toggleLoader } from '../../actions/loader';
import { connect } from 'react-redux';
import course1 from '../images/course-1-1.jpg'
import defaultCourse from '../images/not-available.svg'
import ReactPaginate from 'react-paginate';
import { allCoursesList } from '../../Utils/utils';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import LazyLoad from 'react-lazyload';
import { Translate, I18n } from "react-redux-i18n";

import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import Alert from 'react-bootstrap/Alert'
import defaultImg from '../images/index.jpeg';
const CourseListing = (props) => {
  const localePriceFormat = (localStorage.getItem('langauge_selected') == 'en' || localStorage.getItem('langauge_selected') == '') ? 'en' : 'pt-BR'
  const [langSelected, setLangSelected] = useState('');
  const [course_list, setCourseList] = useState([]);
  const [current_page, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [search, setSearch] = useState('');
  const [is_loading, setIsLoading] = useState(true);
  const [sorting, setSorting] = useState('recent_courses');
  const [recent_courses, setRecentCourses] = useState('');
  const [show, setShow] = useState(false);
  const [temp_id, setTempId] = useState('');
  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    setShow(true)
    setTempId(id)
  }
  console.log(props.price)
  useEffect(async () => {
    props.toggleLoader(true);
    var course_var = await allCoursesList(current_page, search, props.category_filter, props.level_var, props.price, sorting);
    setCourseList(course_var.data);
    setPageCount(course_var.last_page);
    setIsLoading(false);
    props.toggleLoader(false);
  }, [sorting, props.category_filter, props.language])

  const handlePageClick = async (e) => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    setIsLoading(true);
    const selectedPage = e.selected + 1;
    var course_var = await allCoursesList(selectedPage);
    setPageCount(course_var.last_page)
    setCourseList(course_var.data);
    setIsLoading(false);
  };

  const searchFilter = async () => {
    setIsLoading(true);
    var course_var = await allCoursesList('1', search)
    setCourseList(course_var.data)
    setPageCount(course_var.last_page)
    setIsLoading(false);
  }
  const searchFilterNoLoader = async (e) => {
    setIsLoading(true);
    var course_var = await allCoursesList('1', e)
    setCourseList(course_var.data)
    setPageCount(course_var.last_page)
    setIsLoading(false);
  }

  const printStars = (avg_star) => {
    var print_html = [];
    for (var i = 1; i <= 5; i++) {
      if (avg_star >= i) {
        print_html.push(<i className="icofont-star text-warning"></i>);
      } else {
        print_html.push(<i className="icofont-star rate-select " style={{ color: '#eee' }}></i>);
      }
    }
    return print_html;
  }
  return (
    <>
      {/* <div className="mt-4 mt-md-0"> */}
      <div className="main-course-list">
        <div className="row">
          <div className="col-md-6 mb-3 mb-md-0">
            <div className="single-input-wrap">
              <input type="text" placeholder={I18n.t("Search your best courses")}
                onChange={e => {
                  setSearch(e.target.value)
                  searchFilterNoLoader(e.target.value)
                }}
              />
              <button
                onClick={e => searchFilter()}
              > <i className="icofont-search-1"></i> </button>
            </div>
          </div>
          <div className="col-md-6 col-lg-5 ml-auto text-md-right align-items-center">
            <select className="form-control float-right m-order_width" style={{ height: "44px" }}
              onChange={e => setSorting(e.target.value)}>
              <option value={'recent_courses'}>{I18n.t("Recent Courses")}</option>
              <option value={'highest_rated'}>{I18n.t("Highest Rated")}</option>
              <option value={'alphabetical'}>{I18n.t("Name A to Z")}</option>
            </select>
          </div>
        </div>
      </div>
      <div className="row">
        {
          is_loading && [1, 2, 3, 4, 5, 6].map((data) => (
            <div className="mt-3 mb-2 col-lg-3 col-md-4 col-sm-6">
              <div className="wrapper">
                <div className="animate br" style={{ height: '205px' }}></div>
                <div className="">
                  <h4 className="comment animate br mb-2">
                    <span></span>
                  </h4>
                  <h6 className="w50 mx-auto comment animate br"></h6>
                </div>
                <div className="comment animate br"></div>
              </div>
            </div>
          ))
        }
        {(course_list.length === 0) ?
          <img src={defaultCourse} />
          : ''}
        {course_list.map((list) => (

          <div className="col-xl-4 col-sm-6 mt-3">
            <div className="course-one__single color-1">
              <a className="course-one__category">{I18n.t(list.category.name)}</a>
              <div className="course-one__image1">
                {/* @lazyload({
                once: true,
                offset: 100
  }) */}
                <LazyLoad>
                  <img src={list.media.url} />
                </LazyLoad>
              </div>
              <div className="course-one__content">

                <div className="course-one__admin">
                  {/* {list.user !== null ?
                    <> {list.user.media !== null
                      ? <img src={list.user.media.url} className="img-fluid" />
                      : <img src={team1} className="img-fluid" />
                    }</>
                    : ""} */}
                  {/* <img src={team1} alt="" /> */}

                  {/* {list.user.first_name}{list.user.last_name} */}
                  <h3 className='instructor-name ' onClick={e => handleShow(list.id)} style={{ cursor: 'pointer' }}>
                    <i class="fa fa-users"></i> {I18n.t("View Instructors")}
                  </h3>

                </div>
                <Link to={"/course-detail/" + list.id + "/" + list.slug}>
                  <h2 className="course-one__title"><a>{list.name}</a></h2>
                </Link>
                <div className="course-one__stars">
                  <span className="course-one__stars-wrap">
                    {/* <i className="fa fa-star" />
                    <i className="fa fa-star" />
                    <i className="fa fa-star" />
                    <i className="fa fa-star" />
                    <i className="fa fa-star" /> */}
                    {printStars(list.avg_rating)}
                  </span>
                  {/* <span className="course-one__count">{list.avg_rating}</span> */}
                  <span className="course-one__stars-count">{list.avg_rating}</span>
                </div>
                <div className="course-one__meta">
                  <a ><i className="far fa-clock" /> {list.offer_detail.duration}</a>
                  <a ><i className="far fa-folder-open" /> {list.offer_detail.lecture_count} {I18n.t("Lectures")}</a>
                  {/* <a href="#">R$ {list.price}</a> */}
                  <a >
                    R$ {parseFloat(list.price).toLocaleString(localePriceFormat)}
                  </a>
                </div>
                <Link to={"/course-detail/" + list.id + "/" + list.slug} className="course-one__link">{I18n.t("See Preview")}</Link>
              </div>
            </div>
          </div>

        ))}
      </div>
      {course_list.length > 0 ?
        <div className="row no-gutters">
          <div className="mx-auto d-block">
            <ReactPaginate
              previousLabel={I18n.t("prev")}
              nextLabel={I18n.t("next")}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={pageCount}
              marginPagesDisplayed={0}
              pageRangeDisplayed={3}
              onPageChange={handlePageClick}
              containerClassName={"pagination mob-page"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
            />
          </div>
        </div>
        : ""}
      <>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <div className="modal-title-c h4">  {I18n.t("View all instructors")}</div>
          </Modal.Header>
          <Modal.Body>

            {
              course_list.map((list) => (
                temp_id === list.id ?
                  // <div className="row" style={{ paddingBottom: '15px' }}>
                  <>
                    {(list.offer_instructor.length === 0) ?
                      <Tr>
                        <Td colspan="12" style={{ textAlign: 'center' }}>
                          <Alert key={'alert'} variant={'info'} style={{ width: '350px', marginLeft: '50px' }}>
                            {I18n.t('No Instructor')}
                          </Alert>
                        </Td>
                      </Tr> : ''}
                    {list.offer_instructor.map((inst) => (

                      <div className="row" style={{ paddingBottom: '20px' }}>
                        <div className="col-lg-3">
                          <div className='user-image-c'>
                            <Link to={"/instructor-detail/" + inst.user.id}>
                              {inst.user.media !== null ?
                                <img src={inst.user.media.url} className="img-fluid" style={{ borderRadius: '4px' }} />
                                : <img src={defaultImg} className="img-fluid" style={{ borderRadius: '4px' }} />}
                            </Link>
                          </div>
                        </div>
                        <div className="col-lg-9">
                          <div className="user-name-c">
                            <Link to={"/instructor-detail/" + inst.user.id}>
                              <h6>{inst.user.first_name} {inst.user.last_name}</h6>
                            </Link>
                            {/* <h6>instructor name</h6> */}
                          </div>
                          <br />
                        </div>
                      </div>

                    ))}
                    {/* // </div> */}
                  </>
                  : ""
              ))}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              {I18n.t("Close")}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    </>
  )
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CourseListing);