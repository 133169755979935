import React, { useState, useEffect } from 'react';
import Topbar from '../components/Topbar';
import CourseCard from '../components/CourseCard';
import CourseResponsive from '../components/CourseResponsive';
import TopNav from '../components/TopNav';
import Footer from '../components/Footer';
import CourseListing from './courseListing';
import CourseListResponsive from './CourseListResponsive';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { connect } from 'react-redux';
import axios from "axios";
import { toggleLoader } from '../actions/loader';
import './course.css';
import { categoryList, levelList } from '../Utils/utils';
import { Link } from 'react-router-dom';
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import queryString from 'query-string';
import { Translate, I18n } from "react-redux-i18n";

const CourseList = () => {

  const [category, setCategory] = useState([]);
  const [category_filter, setCategoryFilter] = useState([]);
  const [initial_count, setInitialCount] = useState(1);
  const [level, setLevel] = useState([]);
  const [level_filter, setLevelFilter] = useState('');
  const [price, setPrice] = useState("all");

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  useEffect(async () => {
    var categoryList_var = await categoryList();
    setCategory(categoryList_var);
  }, [])
  const selectCategory = async (e, category_id) => {
    var temp_array = [...category_filter];
    var temp_count = initial_count + 1;
    if (e.target.checked === true) {
      temp_array.push(category_id);
    } else {
      var index = temp_array.indexOf(category_id);
      if (index > -1) {
        temp_array.splice(index, 1);
      }
    }
    setCategoryFilter(temp_array);
    setInitialCount(temp_count);
  }
  useEffect(async () => {
    var levelList_var = await levelList();
    setLevel(levelList_var);
  }, [])
  useEffect(async () => {
    const parsed = queryString.parse(window.location.search);
    if (parsed.category !== undefined) {
      // setParamsList(parsed.category);
      // setCategoryFilter(parsed.category)
      var temp_array2 = [...category_filter];
      temp_array2.push(parseInt(parsed.category))
      setCategoryFilter(temp_array2);
    }
  }, [])
  return (
    <>
      <body id="page-top">
        <div classNameName="page-wrapper">

          <Topbar />
          <div>
            <section class="inner-banner d-none d-sm-block">
              <div class="container">
                <ul className="list-unstyled thm-breadcrumb">
                  <li><Link to="/">{I18n.t("Home")}</Link></li>
                  <li className="active"><Link to="/course-list">{I18n.t("Courses")}</Link></li>
                </ul>
                <h2 class="inner-banner__title">{I18n.t("Courses")}</h2>
              </div>
            </section>
            <section class="inner-banner-responsive d-block d-sm-none">
              <div class="container">
                <Breadcrumb>
                  <Breadcrumb.Item href="/">{I18n.t("Home")}</Breadcrumb.Item>
                  <Breadcrumb.Item href="" active>
                    {I18n.t("Courses")}
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>
            </section>
            <section className="course-one course-page p-120">
              <div className="container">
                <div className="row">
                  {/* sidebar starts here */}
                  <div className="col-xl-3 col-lg-4">
                    <div className="sidebar sidebar-left mt-3">
                      <div className="d-none d-sm-block"> {/* col-md-4 col-xl-3 */}
                        <div className="page-sidebar">
                          <div className="page-sidebar-content">
                            <p className="widget-title heading">{I18n.t("Filter by category")} </p>
                            <ul className="widget">
                              {category.map((data, index) => (
                                <li className="term-item">
                                  <div className="custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" id={"art-design-ac" + index} name="example1"
                                      onClick={e => { selectCategory(e, data.id) }}
                                      checked={(category_filter.includes(data.id)) ? true : false}
                                    />
                                    <label className="custom-control-label" for={"art-design-ac" + index}>
                                      {I18n.t(data.name)}
                                    </label>
                                  </div>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                        <div className="page-sidebar sidebar-filter mt-4">
                          <div className="page-sidebar-content">
                            <p className="widget-title heading"> {I18n.t("Filter by")}</p>
                            <ul className="widget ">
                              <p className="widget-title heading mb-3"> {I18n.t("Price")}</p>
                              <li className="term-item">
                                <div className="custom-control custom-radio custom-control-inline">
                                  <input type="radio" className="custom-control-input" id="priceRadio" name="example" value="customEx" defaultChecked={true}
                                    onClick={e => {
                                      setPrice("all");
                                      var temp_count = initial_count + 1;
                                      setInitialCount(temp_count)
                                    }} />
                                  <label className="custom-control-label" for="priceRadio"> {I18n.t("All")}</label>
                                </div>
                              </li>
                              <li className="term-item">
                                <div className="custom-control custom-radio custom-control-inline">
                                  <input type="radio" className="custom-control-input" id="priceRadio1" name="example"
                                    value="customEx"
                                    onClick={e => {
                                      setPrice(1);
                                      var temp_count = initial_count + 1;
                                      setInitialCount(temp_count)
                                    }}
                                  />
                                  <label className="custom-control-label" for="priceRadio1"> {I18n.t("Free")}</label>
                                </div>
                              </li>
                              <li className="term-item">
                                <div className="custom-control custom-radio custom-control-inline">
                                  <input type="radio" className="custom-control-input" id="priceRadio2" name="example"
                                    value="customEx"
                                    onClick={e => {
                                      setPrice(0);
                                      var temp_count = initial_count + 1;
                                      setInitialCount(temp_count)
                                    }}
                                  />
                                  <label className="custom-control-label" for="priceRadio2"> {I18n.t("Paid")}</label>
                                </div>
                              </li>
                              <li className="term-item">
                                <div className="custom-control custom-radio custom-control-inline">
                                  <input type="radio" className="custom-control-input" id="priceRadio3" name="example"
                                    value="customEx"
                                    onClick={e => {
                                      setPrice(2);
                                      var temp_count = initial_count + 1;
                                      setInitialCount(temp_count)
                                    }}
                                  />
                                  <label className="custom-control-label" for="priceRadio3"> {I18n.t("Free With Passcode")}</label>
                                </div>
                              </li>
                            </ul>

                            <ul className="widget ">
                              <p className="widget-title heading mb-3"> {I18n.t("Level")}</p>
                              {level.map((list, index) => (
                                <li className="term-item">
                                  <div className="custom-control custom-radio custom-control-inline">
                                    <input type="radio" className="custom-control-input" id={"customRadioac" + index} name="example2" value="customEx"
                                      onClick={e => {
                                        setLevelFilter(list.id)
                                        var temp_count = initial_count + 1;
                                        setInitialCount(temp_count);
                                        // levelFilter()
                                      }}
                                    />
                                    <label className="custom-control-label" for={"customRadioac" + index}>
                                      {I18n.t(list.name)}
                                      {/* all */}
                                    </label>
                                  </div>
                                </li>
                              ))}
                            </ul>

                          </div>
                        </div>
                      </div>

                      <Accordion defaultActiveKey="0" className="d-block d-md-none filter_course">
                        <Card>
                          <Card.Header className="bg-transparent p-0">
                            <Accordion.Toggle as={Card.Header} variant="link" eventKey="0">
                              {I18n.t("Filters")}
                              <span className="filter-icon">
                                <i className="fas fa-filter" />
                              </span>
                            </Accordion.Toggle>
                          </Card.Header>
                          <Accordion.Collapse eventKey="0">
                            <Card.Body>
                              <div className="col-md-4 col-xl-3">
                                <div className="page-sidebar">
                                  <div className="page-sidebar-content">
                                    <p className="widget-title heading">{I18n.t("Filter by category")}</p>
                                    <ul className="widget">
                                      {category.map((data, index) => (
                                        <li className="term-item">
                                          <div className="custom-control custom-checkbox">
                                            <input type="checkbox" className="custom-control-input" id={"art-design-ac" + index} name="example1"
                                              onClick={e => { selectCategory(e, data.id) }}
                                              checked={(category_filter.includes(data.id)) ? true : false}
                                            />
                                            <label className="custom-control-label" for={"art-design-ac" + index}>{I18n.t(data.name)}</label>
                                          </div>
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                </div>
                                <div className="page-sidebar sidebar-filter mt-4">
                                  <div className="page-sidebar-content">
                                    <p className="widget-title heading">{I18n.t("Filter by")}</p>
                                    <ul className="widget ">
                                      <p className="widget-title heading mb-3">{I18n.t("Price")}</p>
                                      <li className="term-item">
                                        <div className="custom-control custom-radio custom-control-inline">
                                          <input type="radio" className="custom-control-input" id="priceRadioac" name="example" value="customEx"
                                            defaultChecked={true}
                                            onClick={e => {
                                              setPrice("all");
                                              var temp_count = initial_count + 1;
                                              setInitialCount(temp_count)
                                            }} />
                                          <label className="custom-control-label" for="priceRadioac">{I18n.t("All")}</label>
                                        </div>
                                      </li>
                                      <li className="term-item">
                                        <div className="custom-control custom-radio custom-control-inline">
                                          <input type="radio" className="custom-control-input" id="priceRadio1ac" name="example"
                                            value="customEx" onClick={e => {
                                              setPrice(1)
                                              var temp_count = initial_count + 1;
                                              setInitialCount(temp_count)
                                            }} />
                                          <label className="custom-control-label" for="priceRadioac">{I18n.t("Free")}</label>
                                        </div>
                                      </li>
                                      <li className="term-item">
                                        <div className="custom-control custom-radio custom-control-inline">
                                          <input type="radio" className="custom-control-input" id="priceRadio2ac" name="example"
                                            value="customEx" onClick={e => {
                                              setPrice(0);
                                              var temp_count = initial_count + 1;
                                              setInitialCount(temp_count)
                                            }} />
                                          <label className="custom-control-label" for="priceRadio2ac">{I18n.t("Paid")}</label>
                                        </div>
                                      </li>
                                      <li className="term-item">
                                        <div className="custom-control custom-radio custom-control-inline">
                                          <input type="radio" className="custom-control-input" id="priceRadio1ac" name="example"
                                            value="customEx" onClick={e => {
                                              setPrice(2)
                                              var temp_count = initial_count + 1;
                                              setInitialCount(temp_count)
                                            }} />
                                          <label className="custom-control-label" for="priceRadioac">{I18n.t("Free With Passcode")}</label>
                                        </div>
                                      </li>
                                    </ul>

                                    <ul className="widget ">
                                      <p className="widget-title heading mb-3">{I18n.t("Level")}</p>
                                      {level.map((list, index) => (
                                        <li className="term-item">
                                          <div className="custom-control custom-radio custom-control-inline">
                                            <input type="radio" className="custom-control-input" id={"customRadioac" + index} name="example2" value="customEx"
                                              onClick={e => {
                                                setLevelFilter(list.id)
                                                var temp_count = initial_count + 1;
                                                setInitialCount(temp_count);
                                                // levelFilter()
                                              }} />
                                            <label className="custom-control-label" for={"customRadioac" + index}>{I18n.t(list.name)}</label>
                                          </div>
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      </Accordion>
                    </div>
                  </div>

                  {/* sidebar ends here */}
                  <div class="col-xl-9 col-lg-8">
                    {/* <CourseCard/> */}
                    <CourseListing
                      key={initial_count}
                      category_filter={category_filter}
                      level_var={level_filter}
                      price={price} />
                    {/* <CourseListResponsive /> */}
                  </div>
                </div>
              </div>
            </section>
          </div>
          <Footer />
        </div>
      </body>
    </>
  )
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(CourseList);
