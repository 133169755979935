import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import axios from "axios";
import { toggleLoader } from '../actions/loader';
import Topbar from '../components/Topbar';
import Footer from '../components/Footer';
import Breadcrumb from 'react-bootstrap/Breadcrumb'
import { Link } from 'react-router-dom';
import './style.css';
import course1 from './img/course-1-1.jpg';
import courseStat from './img/courseStat.png';
import myCourseD from './img/default-img.svg';
import parse from 'html-react-parser';
import Leftbar from '../components/Leftbar';
import { myCoursesList } from '../Utils/utils';
import ReactPaginate from 'react-paginate';
import Modal from 'react-bootstrap/Modal'
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import { Translate, I18n } from "react-redux-i18n";

import defaultImg from './img/index.jpeg';
const MyCourse = (props) => {
  const localePriceFormat = (localStorage.getItem('langauge_selected') == 'en' || localStorage.getItem('langauge_selected') == '') ? 'en' : 'pt-BR'
  const [course_list, setCourseList] = useState([]);
  const [current_page, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [search, setSearch] = useState("");
  const [status, setStatus] = useState("");
  const [show, setShow] = useState(false);
  const [temp_id, setTempId] = useState('');
  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    setShow(true)
    setTempId(id)
  }
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  useEffect(async () => {
    props.toggleLoader(true);
    var get_course_list = await myCoursesList(current_page);
    setCourseList(get_course_list.data);
    setPageCount(get_course_list.last_page);
    props.toggleLoader(false);
  }, [])
  const searchBar = async () => {
    props.toggleLoader(true);
    var get_course_list = await myCoursesList(current_page, search)
    setCourseList(get_course_list.data)
    setPageCount(get_course_list.last_page)
    props.toggleLoader(false);
  }
  const paidContent = async () => {
    props.toggleLoader(true);
    var get_course_list = await myCoursesList(current_page, search, status)
    setCourseList(get_course_list.data)
    setPageCount(get_course_list.last_page)
    props.toggleLoader(false);
  }
  const handlePageClick = async (e) => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    const selectedPage = e.selected + 1;
    props.toggleLoader(true);
    var get_course_list = await myCoursesList(selectedPage);
    setPageCount(get_course_list.last_page)
    setCourseList(get_course_list.data);
    props.toggleLoader(false);
  };
  return (
    <>
      <body id="page-top">
        <div className="page-wrapper">

          <Topbar />
          {/* <InnerBanner/> */}
          <section className="inner-banner d-none d-sm-block">
            <div className="container">
              <ul className="list-unstyled thm-breadcrumb">
                <li><Link to="/">{I18n.t("Home")}</Link></li>
                <li className="active"><Link to="/my-course">{I18n.t("My Courses")}</Link></li>
              </ul>
              <h2 className="inner-banner__title">{I18n.t("My Courses")}</h2>
            </div>
          </section>
          <section className="inner-banner-responsive d-block d-sm-none">
            <div className="container">
              <Breadcrumb>
                <Breadcrumb.Item href="/">{I18n.t("Home")}</Breadcrumb.Item>
                <Breadcrumb.Item href="" active>
                  {I18n.t("My Courses")}
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </section>
          <>
            {/* <div class="container bootstrap snippets bootdey">
              <div class="row"> */}
            {/* <Leftbar/> */}
            <div class="container">
              <div class="main-body">
                <div class="row gutters-sm my-4">
                  <Leftbar menu={'course'} />
                  <div class="col-md-9">

                    {
                      (course_list.length > 0) ?
                        <div className="row">
                          <div className="col-md-6 search-form search-form--light mb-2 mb-md-0">
                            <input type="text" className="form-control" placeholder={I18n.t("Filter by name")} id="searchSample02"
                              onChange={e => {
                                setSearch(e.target.value)
                                searchBar()
                              }}
                            />
                            <button className="btn1" type="button"><i className='icofont-search-1'></i></button>
                          </div>
                          <div className="col-md-5">
                            <div className="form-group">
                              <select id="published01" className="form-control custom-select">
                                <option>Status</option>
                                <option onClick={e => {
                                  setStatus(1)
                                  paidContent()
                                }}>{I18n.t("Paid Content")}</option>
                                <option onClick={e => {
                                  setStatus(0)
                                  paidContent()
                                }}>{I18n.t("Free Content")}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        : ''}
                    {course_list.length > 0 ?
                      <>
                        {course_list.map((list) => (
                          <>
                            {list.status === 'active' ?
                              <div className="course-card ">

                                <div className='row'>
                                  <div className="col-md-4 col-xl-4">
                                    <div className='course-image new-image h-240' style={{ backgroundImage: 'url(' + encodeURI(list.offer.media.url) + ')' }} >
                                      {/* <img src={list.offer.media.url} /> */}

                                    </div>
                                  </div >
                                  <div className="col-md-8 col-xl-8">
                                    <div >
                                      <div className="course-card_type">
                                        <i class="far fa-play-circle"></i>   {I18n.t(list.offer.category.name)}
                                        <h3 className='instructor-name pull-right' onClick={e => handleShow(list.offer.id)} style={{ cursor: 'pointer' }}>
                                          <i class="fa fa-users"></i> {I18n.t("View Instructors")}
                                        </h3>
                                      </div>
                                      <h2 className="course-name">
                                        {list.offer.name}
                                      </h2>
                                      <div className="course-desc" style={{ fontSize: '14zpx', fontWeight: '100', height: '72px' }}>
                                        {parse(list.offer.description)}
                                      </div>
                                      <div className="lecture-count">
                                        {I18n.t("Total Duration")} : {list.offer_detail.duration}
                                        {/* {list.offer_detail.lecture_count === 0 ? "" : */}
                                          <Link to={"/course/" + list.offer_id + "/consumption"}>
                                            <div className="btn btn-pink btn-class pull-right" >
                                              {I18n.t("Class Access")}
                                            </div>
                                          </Link>
                                        {/* } */}
                                        <div className="lecture-count1" >
                                          {(list.offer.free_content) === 0 ?
                                            <span className="text-dark font-weight-bold"> R$ {parseFloat(list.offer.price).toLocaleString(localePriceFormat)}</span>
                                            :
                                            <span className="text-dark font-weight-bold"> {I18n.t("Free Content")}</span>}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <hr />
                                <div className='progress-line bg-transparent pt-0'>
                                  <div className='d-flex'>
                                    <p class="mb-2 progress__text">{I18n.t("Progress")}</p>
                                    <p class="mb-2 progress__text ml-auto">{list.percentage_complete}%</p>
                                  </div>
                                  <div className='progress'>
                                    <div class="progress-bar" role="progressbar" style={{ width: list.percentage_complete + '%' }}>

                                    </div>
                                  </div>
                                </div>
                              </div>
                              :
                              <div>
                                <div className="course-card position-relative" >
                                  <div className='row' style={{ filter: 'blur(2px)' }}>
                                    <div className="col-md-4 col-xl-4">
                                      <div className='course-image new-image h-240' style={{ backgroundImage: 'url(' + encodeURI(list.offer.media.url) + ')' }} >
                                        {/* <img src={list.offer.media.url} /> */}

                                      </div>
                                    </div >
                                    <div className="col-md-8 col-xl-8">
                                      <div >
                                        <div className="course-card_type">
                                          <i class="far fa-play-circle"></i>  {I18n.t(list.offer.category.name)}
                                          <h3 className='instructor-name pull-right' onClick={e => handleShow(list.offer.id)} style={{ cursor: 'pointer' }}>
                                            <i class="fa fa-users"></i>  {I18n.t("View Instructors")}
                                          </h3>
                                        </div>
                                        <h2 className="course-name">
                                          {list.offer.name}
                                        </h2>
                                        <div className="course-desc" style={{ fontSize: '12px', fontWeight: '100', height: '72px' }}>
                                          {parse(list.offer.description)}
                                        </div>
                                        <div className="lecture-count">
                                          {I18n.t("Total Duration")} : {list.offer_detail.duration}

                                          {/* <Link to={"/course/" + list.offer_id + "/consumption"}> */}
                                          {list.offer_detail.lecture_list === [] ?
                                            ""
                                            :
                                            <div className="btn btn-pink btn-class pull-right" >
                                              {I18n.t("Class Access")}
                                            </div>
                                          }
                                          {/* </Link> */}
                                          <div className="lecture-count1" >
                                            {(list.offer.free_content) === 0 ?
                                              <span className="text-success"> R$ {list.offer.price}</span>
                                              :
                                              <span className="text-success"> {I18n.t("Free Content")}</span>}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <hr />
                                  <div className='progress-line bg-transparent pt-0'>
                                    <div className='d-flex'>
                                      <p class="mb-2 progress__text">{I18n.t("Progress")}</p>
                                      <p class="mb-2 progress__text ml-auto">{list.percentage_complete}%</p>
                                    </div>
                                    <div className='progress'>
                                      <div class="progress-bar" role="progressbar" style={{ width: list.percentage_complete + '%' }}>

                                      </div>
                                    </div>
                                  </div>

                                  {list.boleto_url !== null ?
                                    <>
                                      <div className="boleto_btn">
                                        <Link to={{ pathname: list.boleto_url }} target="_blank">
                                          <Button style={{ backgroundColor: '#34cad2' }} active >
                                            Boleto URL
                                          </Button>
                                        </Link>
                                      </div>
                                    </>
                                    : ""}
                                </div>
                              </div>
                            }
                          </>
                        ))}
                      </>
                      : <div className="default_img">
                        <img src={myCourseD} className="img-fluid" />
                        <h5 className="">{I18n.t('Course list are Empty')} !</h5>
                      </div>}


                  </div>
                </div>

              </div>
            </div>
            {(course_list.length > 0) ?
              <div className="d-block col-md-9 offset-md-3" >
                <ReactPaginate
                  previousLabel={I18n.t("prev")}
                  nextLabel={I18n.t("next")}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={pageCount}
                  marginPagesDisplayed={0}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageClick}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"} />
              </div> : ''}
            {/* </div>
            </div> */}
          </>
          <>
            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <div className="modal-title-c h5"> {I18n.t("View all instructors")} </div>
              </Modal.Header>
              <Modal.Body>
                {
                  course_list.map((list) => (
                    temp_id === list.offer.id ?
                      // <div className="row">
                      <>
                        {(list.offer.offer_instructor.length === 0) ?
                          <Tr>
                            <Td colspan="12" style={{ textAlign: 'center' }}>
                              <Alert key={'alert'} variant={'info'} style={{ width: '350px', marginLeft: '50px' }}>
                                {I18n.t('No Instructor')}
                              </Alert>
                            </Td>
                          </Tr> : ''}
                        {list.offer.offer_instructor.map((inst) => (
                          <div className='col-md-12 mb-3'>
                            <div className='row'>
                              <>
                                <div className="col-lg-3">
                                  <div className=''>
                                    <Link to={"/instructor-detail/" + inst.user.id}>
                                      {inst.user.media !== null ?
                                        <img src={inst.user.media.url} style={{ borderRadius: '5px' }} className='img-fluid' />
                                        : <img src={defaultImg} style={{ borderRadius: '5px' }} className='img-fluid' />}
                                    </Link>
                                  </div>
                                </div>
                                <div className="col-lg-9 align-self-center">
                                  <div className="instructors_name">
                                    <Link to={"/instructor-detail/" + inst.user.id}>
                                      <h6>{inst.user.first_name} {inst.user.last_name}</h6>
                                    </Link>
                                  </div>
                                  <br />
                                </div>
                              </>
                            </div>
                          </div>
                        ))}
                      </>
                      //   {/* <hr className='hr-sep' /> */}
                      // {/* </div> */}
                      : ""
                  ))}
                {/* <div className="row" style={{ paddingBottom: '15px' }}>
                  <div className="col-lg-3">
                    <div className='user-image-c'>
                      <Link to="/instructor-detail">
                        <img src={course1} style={{ maxWidth: '100%', borderRadius: '30px' }} />
                      </Link>
                    </div>
                  </div>

                  <hr className='hr-sep' />
                </div> */}
              </Modal.Body>
              <Modal.Footer>
                <Button variant="danger" onClick={handleClose}>
                  {I18n.t("Close")}
                </Button>
                {/* <Button variant="primary" onClick={handleClose}>
                  Submit
                </Button> */}
              </Modal.Footer>
            </Modal>
          </>
        </div>
        <Footer />
      </body>
    </>
  )
}
const mapDispatchToProps = {
  toggleLoader
}
const mapStateToProps = (state) => {
  return {
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(MyCourse);
